import { useEffect, useState } from "react";
import "./clock.scss";
import clock_1 from "src/assets/svg/clock-1.svg";
import clock_2 from "src/assets/svg/clock-2.svg";
import useVotoStore from "src/store";

const ClockTreasures = () => {
    const [passTime, setPassTime] = useState<number>(0);
    const {
        vacation: { stages },
        setTimingoutTreasure,
        vacationStageFinishedById,
    } = useVotoStore();

    const { id, started, finished, time, timeout, completed } = stages[3];

    useEffect(() => {
        let timeSubscriber: any;
        let counter = 1;
        if (started && !finished && !completed) {
            timeSubscriber = setInterval(() => {
                counter += passTime;
                setPassTime(counter);
            }, 1000);

            if (time && passTime >= time && !timeout) {
                setTimingoutTreasure(true);
                clearInterval(timeSubscriber);
                counter = 0;
                setPassTime(0);
            }
        }

        if ((time && passTime >= time) || finished || completed) {
            // console.log("remove set time out");
            clearInterval(timeSubscriber);
            counter = 0;
            setPassTime(0);
        }
        return () => {
            timeSubscriber && clearInterval(timeSubscriber);
        };
    }, [
        finished,
        time,
        started,
        setTimingoutTreasure,
        timeout,
        completed,
        passTime,
    ]);

    useEffect(() => {
        if (timeout && !finished && !completed) {
            vacationStageFinishedById(id);
        }
        return () => {};
    }, [timeout, completed, finished, id, vacationStageFinishedById]);

    return (
        <></>
        // <div
        //     className={`voto-clock__container ${
        //         timeout || (finished && !completed)
        //             ? "loose"
        //             : completed
        //             ? "win"
        //             : passTime !== 0 && time && passTime >= (time * 1.5) / 3
        //             ? "alert"
        //             : ""
        //     }`}
        // >
        //     <img className="clock" src={clock_1} alt="" />
        //     <img className="clock" src={clock_2} alt="" />
        //     <div className="countdown">
        //         {timeout && <p>Time out!</p>}
        //         {completed && <p>Good job!</p>}
        //         {finished && !timeout && !completed && <p>You are loosed!</p>}
        //         {!finished && (
        //             <>
        //                 <p>
        //                     {time && time - passTime >= 0 ? time - passTime : 0}{" "}
        //                     . . .&nbsp;
        //                 </p>
        //                 <p>
        //                     {time && time - passTime - 1 >= 0
        //                         ? time - passTime - 1
        //                         : 0}{" "}
        //                     . . .&nbsp;
        //                 </p>
        //                 <p>
        //                     {" "}
        //                     {time && time - passTime - 2 >= 0
        //                         ? time - passTime - 2
        //                         : 0}
        //                 </p>
        //             </>
        //         )}
        //     </div>
        // </div>
    );
};
export default ClockTreasures;
