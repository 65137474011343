import { Suspense } from "react";
import cloud from "src/assets/images/adventure/guardian/clouds.png";
import land_right from "src/assets/images/adventure/land-right.png";
import land_left from "src/assets/images/adventure/land-left.png";
import element_back from "src/assets/images/adventure/element_back.png";
import element_front from "src/assets/images/adventure/element_front.png";
import weed_front from "src/assets/images/adventure/weed_front.png";
import Bubbles from "src/compnents/bubbles/Bubbles";
import Fish from "src/compnents/fish/Fish";
import Navbar from "src/compnents/navbar/Navbar";
import AdventureStages from "./Adventure.stages";
import BaseBackground from "src/compnents/backgrounds/base/base-background";

const AdventureHome = () => {
    return (
        <Suspense fallback={<>Loading...</>}>
            <div className="c-adventure-page c-voto-page">
                <Navbar showHome={false} />
                <BaseBackground />
                <AdventureStages />
            </div>
        </Suspense>
    );
};
export default AdventureHome;
