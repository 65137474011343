import useVotoStore from "src/store";
import cargo_image from "src/assets/images/adventure/guardian/cargo.png";
import ship_smoke from "src/assets/animations/Combustions_Fade.json";
import oil from "src/assets/animations/Spill_Fade.json";
import smoke_loop from "src/assets/animations/Combustions_Loop.json";
import oil_loop from "src/assets/animations/Spill_Loop.json";
import { useEffect, useRef, useState } from "react";
import checked from "src/assets/svg/check-green.svg";
import cross_red from "src/assets/svg/red-cross.svg";
import { Substage_cargo } from "src/store/guardian.slice/guardian.slice";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import { translationService } from "src/services/translation.service";

const Cargo = () => {
    const smokeRef = useRef<LottieRefCurrentProps>(null);
    const oilRef = useRef<LottieRefCurrentProps>(null);

    const [animationDone, setAnimationDone] = useState({
        [Substage_cargo.smoke]: false,
        [Substage_cargo.oil]: false,
    });

    const {
        locale,
        guardian: { stages, utslappIntro },
        guardianStageCompletedById,
        guardianStageFinishedById,
        guardianStageStartById,
        guardianSubstageCompletedByName,
    } = useVotoStore();
    const { completed, finished, started, id, subStages, tryCount, timeout } =
        stages[1];

    const tipsText = translationService.translate(
        "footer.guardian.utslappTips",
        locale
    );

    useEffect(() => {
        if (
            (utslappIntro && tryCount !== 2) ||
            (utslappIntro && tryCount === 2)
        ) {
            guardianStageStartById(id);
            if (!completed || !finished) {
                setAnimationDone({
                    [Substage_cargo.smoke]: false,
                    [Substage_cargo.oil]: false,
                });
            }
        }
    }, [
        utslappIntro,
        guardianStageStartById,
        id,
        tryCount,
        finished,
        completed,
    ]);

    useEffect(() => {
        if (started && subStages?.every((sub) => sub.completed === true)) {
            guardianStageCompletedById(id);
            guardianStageFinishedById(id);
        }
    }, [
        subStages,
        started,
        guardianStageCompletedById,
        guardianStageFinishedById,
        id,
    ]);

    const blockSmoke = () => {
        guardianSubstageCompletedByName(id, Substage_cargo.smoke);
    };

    const blockOil = () => {
        guardianSubstageCompletedByName(id, Substage_cargo.oil);
        setTimeout(() => {
            setAnimationDone({
                ...animationDone,
                [Substage_cargo.oil]: true,
            });
        }, 1300);
    };

    const onTouchStart = (name: Substage_cargo) => {
        if (name === Substage_cargo.smoke) {
            smokeRef.current?.animationItem?.goToAndPlay(0);
            blockSmoke();
            setTimeout(() => {
                setAnimationDone({
                    ...animationDone,
                    [Substage_cargo.smoke]: true,
                });
            }, 1300);
        }
        if (name === Substage_cargo.oil) {
            blockOil();
        }
    };

    return (
        <div className={`stage-cargo`}>
            <div className="stage-cargo__container">
                {!subStages?.find((sub) => sub.name === Substage_cargo.smoke)!
                    .completed &&
                    !completed &&
                    started &&
                    !finished && (
                        <>
                            {!subStages?.find(
                                (sub) => sub.name === Substage_cargo.smoke
                            )!.completed ? (
                                <Lottie
                                    animationData={smoke_loop}
                                    autoplay={true}
                                    loop={true}
                                    className="stage-cargo__container-smoke"
                                    onTouchStart={() =>
                                        onTouchStart(Substage_cargo.smoke)
                                    }
                                />
                            ) : (
                                ""
                            )}
                        </>
                    )}
                {subStages?.find((sub) => sub.name === Substage_cargo.smoke)!
                    .completed &&
                    !animationDone[Substage_cargo.smoke] && (
                        <>
                            {!animationDone[Substage_cargo.smoke] && (
                                <Lottie
                                    lottieRef={smokeRef}
                                    animationData={ship_smoke}
                                    autoplay={true}
                                    loop={false}
                                    id={
                                        subStages?.find(
                                            (sub) =>
                                                sub.name ===
                                                Substage_cargo.smoke
                                        )?.name
                                    }
                                    className="stage-cargo__container-smoke"
                                />
                            )}
                        </>
                    )}
                {!subStages?.find((sub) => sub.name === Substage_cargo.oil)!
                    .completed &&
                    !completed &&
                    started &&
                    !finished && (
                        <>
                            {!subStages?.find(
                                (sub) => sub.name === Substage_cargo.oil
                            )!.completed ? (
                                <Lottie
                                    animationData={oil_loop}
                                    autoplay={true}
                                    loop={true}
                                    className="stage-cargo__container-oil"
                                    onTouchStart={() =>
                                        onTouchStart(Substage_cargo.oil)
                                    }
                                />
                            ) : (
                                ""
                            )}
                        </>
                    )}
                {subStages?.find((sub) => sub.name === Substage_cargo.oil)!
                    .completed &&
                    !animationDone[Substage_cargo.oil] && (
                        <Lottie
                            lottieRef={oilRef}
                            animationData={oil}
                            autoplay={true}
                            loop={false}
                            id={
                                subStages?.find(
                                    (sub) => sub.name === Substage_cargo.oil
                                )?.name
                            }
                            className="stage-cargo__container-oil"
                        />
                    )}
                {started && !finished && (
                    <div className="stage-cargo__container-tips">
                        <p>{tipsText}</p>
                    </div>
                )}
                {completed && finished && (
                    <img
                        className="stage-cargo__checked"
                        src={checked}
                        alt=""
                    />
                )}
                {finished && !completed && (
                    <img
                        className="stage-cargo__checked"
                        src={cross_red}
                        alt=""
                    />
                )}
                <img
                    src={cargo_image}
                    className="stage-cargo__container-image"
                    alt=""
                />
            </div>
        </div>
    );
};
export default Cargo;
