import intromarin_sv from "src/assets/sounds/001_intromarin.mp3";
import syftemarin_sv from "src/assets/sounds/002_syftemarin.mp3";
import raddahavet_sv from "src/assets/sounds/003_raddahavet.mp3";
import motorbat_sv from "src/assets/sounds/004_motorbat.mp3";
import motorbat_bra_sv from "src/assets/sounds/005_motorbat bra.mp3";
import motorbat_neg_sv from "src/assets/sounds/006_motorbat neg.mp3";
import marinslutsats_sv from "src/assets/sounds/007_marinslutsats.mp3";
import utslapp_sv from "src/assets/sounds/008_utslapp.mp3";
import utslapp_pos_sv from "src/assets/sounds/009_utslapp pos.mp3";
import utslapp_neg_sv from "src/assets/sounds/010_utslapp neg.mp3";
import utslapp_forsok_igen_sv from "src/assets/sounds/011_utslapp forsok igen.mp3";
import utslapp_slutsats_sv from "src/assets/sounds/012_utslapp slutsats.mp3";
import skrap_sv from "src/assets/sounds/013_skrap.mp3";
import skrap_pos_sv from "src/assets/sounds/014_skrap_pos.mp3";
import skrap_neg_sv from "src/assets/sounds/015_skrap neg.mp3";
import skrap_slutsats_sv from "src/assets/sounds/016_skrap slutsats.mp3";
import skrap_fakta_sv from "src/assets/sounds/017_skrap fakta.mp3";
import fiske_sv from "src/assets/sounds/018_fiske.mp3";
import spoknat_sv from "src/assets/sounds/019_spoknat.mp3";
import spoknat_pos_sv from "src/assets/sounds/020_spoknat pos.mp3";
import spoknat_neg_sv from "src/assets/sounds/021_spoknat neg.mp3";
import spoknat_slutsats_sv from "src/assets/sounds/022_spoknat slutsats.mp3";
import fakta_flundra_sv from "src/assets/sounds/023_fakta flundra.mp3";
import fakt_sjostjarna_sv from "src/assets/sounds/024_fakt sjostjarna.mp3";
import fakta_mussla_sv from "src/assets/sounds/025 fakta mussla.mp3";
import fakta_gadda_sv from "src/assets/sounds/026_fakta gadda.mp3";
import fakta_manet_sv from "src/assets/sounds/027_fakta manet.mp3";
import fakta_krabba_sv from "src/assets/sounds/028_fakta krabba.mp3";
import fakat_sandslott_sv from "src/assets/sounds/029_fakat sandslott.mp3";
import fakta_barnsten_sv from "src/assets/sounds/030_fakta barnsten.mp3";
import intro_havet_sv from "src/assets/sounds/intro_havet_short.mp3";
import intro_ubat_sv from "src/assets/sounds/intro_ubat.mp3";
import intro_ubat_kor_sv from "src/assets/sounds/intro_ubat_kor.mp3";
import kor_sv from "src/assets/sounds/kor.mp3";

import intromarin_en from "src/assets/sounds/en/001_intro_marine_E.mp3";
import syftemarin_en from "src/assets/sounds/en/002_purpose_marine_E.mp3";
import raddahavet_en from "src/assets/sounds/en/003_save_the_sea_E.mp3";
import motorbat_en from "src/assets/sounds/en/004_motorboat_E.mp3";
import motorbat_bra_en from "src/assets/sounds/en/005_motorboat_pos_E.mp3";
import motorbat_neg_en from "src/assets/sounds/en/006_motorboat_neg_E.mp3";
import marinslutsats_en from "src/assets/sounds/en/007_motorboat_conclution_E.mp3";
import utslapp_en from "src/assets/sounds/en/008_discharge_E.mp3";
import utslapp_pos_en from "src/assets/sounds/en/009_outslapp_pos_E.mp3";
import utslapp_neg_en from "src/assets/sounds/en/010_release_neg_E.mp3";
import utslapp_forsok_igen_en from "src/assets/sounds/en/011_release_try_again_E.mp3";
import utslapp_slutsats_en from "src/assets/sounds/en/012_outslapp_conclusion_E.mp3";
import skrap_en from "src/assets/sounds/en/013_skrap_E.mp3";
import skrap_pos_en from "src/assets/sounds/en/014_skrap_pos_E.mp3";
import skrap_neg_en from "src/assets/sounds/en/015_skrap_neg_E.mp3";
import skrap_slutsats_en from "src/assets/sounds/en/016_skrap_conclusion_E.mp3";
import skrap_fakta_en from "src/assets/sounds/en/017_skrap_fact_E.mp3";
import fiske_en from "src/assets/sounds/en/018_fishing_E.mp3";
import spoknat_en from "src/assets/sounds/en/019_spoknat_E.mp3";
import spoknat_pos_en from "src/assets/sounds/en/020_spoknat_pos_E.mp3";
import spoknat_neg_en from "src/assets/sounds/en/021_spoknat_neg_E.mp3";
import spoknat_slutsats_en from "src/assets/sounds/en/022_spoknat_conclusion_E.mp3";
import fakta_flundra_en from "src/assets/sounds/en/023_fact_flounder_E.mp3";
import fakt_sjostjarna_en from "src/assets/sounds/en/024_fact_starfish_E.mp3";
import fakta_mussla_en from "src/assets/sounds/en/025_fact_mussel_E.mp3";
import fakta_gadda_en from "src/assets/sounds/en/026_fact_gadda_E.mp3";
import fakta_manet_en from "src/assets/sounds/en/027_jellyfish_fact_E.mp3";
import fakta_krabba_en from "src/assets/sounds/en/028_fact_crabb_E.mp3";
import fakat_sandslott_en from "src/assets/sounds/en/029_fact_sandcastle_E.mp3";
import fakta_barnsten_en from "src/assets/sounds/en/030_amber_fact_E.mp3";
import intro_havet_en from "src/assets/sounds/en/intro the sea_E.mp3";
import intro_ubat_en from "src/assets/sounds/en/intro_ubat_E.mp3";
import intro_ubat_kor_en from "src/assets/sounds/en/intro_ubat_E.mp3";
import kor_en from "src/assets/sounds/en/start_E.mp3";
import welcome_quiz_en from "src/assets/sounds/quiz/welcome_en.mp3";
import welcome_quiz_sv from "src/assets/sounds/quiz/welcome_sv.mp3";

export const soundsSrcData = {
    intromarin: {
        sv: intromarin_sv,
        en: intromarin_en,
    },
    syftemarin: {
        sv: syftemarin_sv,
        en: syftemarin_en,
    },
    raddahavet: {
        sv: raddahavet_sv,
        en: raddahavet_en,
    },
    motorbat: {
        sv: motorbat_sv,
        en: motorbat_en,
    },
    motorbat_bra: {
        sv: motorbat_bra_sv,
        en: motorbat_bra_en,
    },
    motorbat_neg: {
        sv: motorbat_neg_sv,
        en: motorbat_neg_en,
    },
    marinslutsats: {
        sv: marinslutsats_sv,
        en: marinslutsats_en,
    },
    utslapp: {
        sv: utslapp_sv,
        en: utslapp_en,
    },
    utslapp_pos: {
        sv: utslapp_pos_sv,
        en: utslapp_pos_en,
    },
    utslapp_neg: {
        sv: utslapp_neg_sv,
        en: utslapp_neg_en,
    },
    utslapp_forsok_igen: {
        sv: utslapp_forsok_igen_sv,
        en: utslapp_forsok_igen_en,
    },
    utslapp_slutsats: {
        sv: utslapp_slutsats_sv,
        en: utslapp_slutsats_en,
    },
    skrap: {
        sv: skrap_sv,
        en: skrap_en,
    },
    skrap_pos: {
        sv: skrap_pos_sv,
        en: skrap_pos_en,
    },
    skrap_neg: {
        sv: skrap_neg_sv,
        en: skrap_neg_en,
    },
    skrap_slutsats: {
        sv: skrap_slutsats_sv,
        en: skrap_slutsats_en,
    },
    skrap_fakta: {
        sv: skrap_fakta_sv,
        en: skrap_fakta_en,
    },
    fiske: {
        sv: fiske_sv,
        en: fiske_en,
    },
    spoknat: {
        sv: spoknat_sv,
        en: spoknat_en,
    },
    spoknat_pos: {
        sv: spoknat_pos_sv,
        en: spoknat_pos_en,
    },
    spoknat_neg: {
        sv: spoknat_neg_sv,
        en: spoknat_neg_en,
    },
    spoknat_slutsats: {
        sv: spoknat_slutsats_sv,
        en: spoknat_slutsats_en,
    },
    fakta_flundra: {
        sv: fakta_flundra_sv,
        en: fakta_flundra_en,
    },
    fakt_sjostjarna: {
        sv: fakt_sjostjarna_sv,
        en: fakt_sjostjarna_en,
    },
    fakta_mussla: {
        sv: fakta_mussla_sv,
        en: fakta_mussla_en,
    },
    fakta_gadda: {
        sv: fakta_gadda_sv,
        en: fakta_gadda_en,
    },
    fakta_manet: {
        sv: fakta_manet_sv,
        en: fakta_manet_en,
    },
    fakta_krabba: {
        sv: fakta_krabba_sv,
        en: fakta_krabba_en,
    },
    fakat_sandslott: {
        sv: fakat_sandslott_sv,
        en: fakat_sandslott_en,
    },
    fakta_barnsten: {
        sv: fakta_barnsten_sv,
        en: fakta_barnsten_en,
    },
    intro_havet: {
        sv: intro_havet_sv,
        en: intro_havet_en,
    },
    intro_ubat: {
        sv: intro_ubat_sv,
        en: intro_ubat_en,
    },
    intro_ubat_kor: {
        sv: intro_ubat_kor_sv,
        en: intro_ubat_kor_en,
    },
    kor: {
        sv: kor_sv,
        en: kor_en,
    },
    quiz:{
        welcome: {
            en: welcome_quiz_en,
            sv: welcome_quiz_sv,
        }
    }
};
