import { VotoProps } from "src/types/index.d";
import { guardianIntialState } from "./guardian.slice/guardian.slice";
import { submarineInitialState } from "./submarine.slice/submarine.slice";
import { baseInitialState } from "./base.slice/base.slice";
import { vacationInitialState } from "./vacation/vacation.slice";
import { quizInitialState } from "./quiz.slice/quiz.slice";

export const initialState: VotoProps = {
    ...baseInitialState,
    ...submarineInitialState,
    ...guardianIntialState,
    ...vacationInitialState,
    ...quizInitialState,
};
