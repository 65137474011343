import "./stars.scss";
import { ReactComponent as Star_empty } from "src/assets/svg/stars/star-empty.svg";
import { ReactComponent as Star_full } from "src/assets/svg/stars/star-full.svg";
import { ReactComponent as Star_half } from "src/assets/svg/stars/star-half.svg";

const StarsRank = ({
    totalItem,
    doneItem,
    startsNumber,
    starColor = "dark",
}: {
    totalItem: number;
    doneItem: number;
    startsNumber: number;
    starColor?: "light" | "dark";
}) => {
    const totalAv = totalItem / startsNumber;
    const doneAv = doneItem / totalAv;
    const isDecimal = doneAv % 1 !== 0;
    const doneRounded = Math.round(doneAv);
    const emptyStar = isDecimal
        ? startsNumber - doneRounded - 1
        : startsNumber - doneRounded;

    const fullstarArr = Array.from(Array(doneRounded).keys());
    const emptyStarArr = Array.from(Array(emptyStar).keys());

    return (
        <div
            className={`voto-stars-ranking ${
                starColor === "light" ? "light-star" : ""
            }`}
        >
            {fullstarArr.length
                ? fullstarArr.map((index) => (
                      <Star_full key={index} className="star" />
                  ))
                : ""}
            {isDecimal ? <Star_half className="star" /> : ""}
            {emptyStarArr.length
                ? emptyStarArr.map((index) => (
                      <Star_empty key={index} className="star" />
                  ))
                : ""}
        </div>
    );
};
export default StarsRank;
