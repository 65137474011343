import { useEffect, useRef } from "react";
import useVotoStore from "src/store";
import { audioService } from "src/services/audio.service";
import { translationService } from "src/services/translation.service";
import { ReactComponent as Cross_icon } from "src/assets/svg/circle-cross.svg";

const WelcomeQuiz = () => {
    const welRef = useRef<HTMLAudioElement>(null);

    const {
        locale,
        setQuizAudioIsPlaying,
        setWellcomeQuizIsDone,
        quiz: { quizAudioIsPlaying, wellcomeQuizIsDone },
    } = useVotoStore();

    const wellcome_havet = audioService.getAudioSrc("quiz.welcome", locale);
    const wellcome_havet_text1 = translationService.translate(
        "quizPage.wellcome",
        locale
    );

    useEffect(() => {
        if (!quizAudioIsPlaying && !wellcomeQuizIsDone) {
            welRef.current?.play();
            if (!quizAudioIsPlaying) {
                setQuizAudioIsPlaying(true);
            }
        }

        return () => {};
    }, [quizAudioIsPlaying, wellcomeQuizIsDone, setQuizAudioIsPlaying]);

    const endWellcomeAudio = () => {
        welRef.current?.pause();
        setQuizAudioIsPlaying(false);
        setWellcomeQuizIsDone(true);
    };

    const onWellcomeMetaData = (e: any) => {};

    return (
        <div className="voto-welcome-vo">
            <audio
                // autoPlay={false}
                onLoadedMetadata={onWellcomeMetaData}
                onEnded={endWellcomeAudio}
                ref={welRef}
                src={wellcome_havet}
            />
            <Cross_icon className="cross-icon" onClick={endWellcomeAudio} />
            <p>
                {wellcome_havet_text1}
                <br />
            </p>
        </div>
    );
};
export default WelcomeQuiz;
