import React, { Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Keyboard from "src/compnents/keyboard/Keyboard";
import { translationService } from "src/services/translation.service";
import useVotoStore from "src/store";
import { Supports } from "src/types/index.d";

const SelectGuidePage = () => {
    const { locale, supports, changeSupports } = useVotoStore();

    const titleText = translationService.translate("guidePage.title", locale);
    const subtitleText = translationService.translate(
        "guidePage.subtitle",
        locale
    );
    const textGuideTitle = translationService.translate(
        "guidePage.textGuideTitle",
        locale
    );
    const textGuideInfo = translationService.translate(
        "guidePage.textGuideInfo",
        locale
    );
    const signLanguageTitle = translationService.translate(
        "guidePage.signLanguageTitle",
        locale
    );
    const signLanguageInfo = translationService.translate(
        "guidePage.signLanguageInfo",
        locale
    );
    const imageSupportTitle = translationService.translate(
        "guidePage.imageSupportTitle",
        locale
    );
    const imageSupportInfo = translationService.translate(
        "guidePage.imageSupportInfo",
        locale
    );
    const backText = translationService.translate("shared.back", locale);
    const startText = translationService.translate("shared.start", locale);

    return (
        <Suspense fallback={<>Loading...</>}>
            <div className="c-guide-page">
                <div className="guide__content">
                    <h2>{titleText}:</h2>
                    <h4>{subtitleText}?</h4>
                    <div className="guide-boxes">
                        <div className="guide__box">
                            <div className="guide__box-text">
                                <h3>{textGuideTitle}</h3>
                                <p>{textGuideInfo}</p>
                            </div>
                            <input
                                type="checkbox"
                                checked={supports.textSupport}
                                onChange={() =>
                                    changeSupports(Supports.textSupport)
                                }
                            />
                        </div>
                        <div className="guide__box">
                            <div className="guide__box-text">
                                <h3>{signLanguageTitle}</h3>
                                <p>{signLanguageInfo}</p>
                            </div>
                            <input
                                type="checkbox"
                                checked={supports.signLanguage}
                                onChange={() =>
                                    changeSupports(Supports.signLanguage)
                                }
                            />
                        </div>
                        <div className="guide__box">
                            <div className="guide__box-text">
                                <h3>{imageSupportTitle}</h3>
                                <p>{imageSupportInfo}</p>
                            </div>
                            <input
                                type="checkbox"
                                checked={supports.imageSupport}
                                onChange={() =>
                                    changeSupports(Supports.imageSupport)
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className="bottom-buttons">
                    <Link
                        to={"/name"}
                        className="c-button button--primary button--medium btn-back"
                    >
                        {backText}
                    </Link>
                    <Link
                        to={"/adventure"}
                        className="c-button button--primary button--medium btn-next"
                    >
                        {startText}!
                    </Link>
                </div>
            </div>
        </Suspense>
    );
};
export default SelectGuidePage;
