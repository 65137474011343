import produce from "immer";
import { Basename, Locale, Supports, VotoState } from "src/types/index.d";
import { StateCreator } from "zustand";

export interface BaseProps {
    username: string;
    basename: string;
    locale: Locale;
    supports: {
        textSupport: boolean;
        signLanguage: boolean;
        imageSupport: boolean;
    };
    overlay: boolean;
}

export const baseInitialState: BaseProps = {
    username: "",
    locale: Locale.sv,
    basename: "",
    supports: {
        textSupport: false,
        signLanguage: false,
        imageSupport: false,
    },
    overlay: false,
};

export interface BaseState extends BaseProps {
    registerName: (name: string) => void;
    changeLocale: () => void;
    changeSupports: (checkbox: Supports) => void;
    setOverlay: (o: boolean) => void;
    setBasename: (bn: string) => void;
    resetBaseState: () => void;
}

export const baseSlice: StateCreator<VotoState, [], [], BaseState> = (
    set: (
        partial: (state: BaseState) => Partial<BaseState>,
        replace?: boolean,
        name?: string
    ) => void
) => ({
    ...baseInitialState,
    changeLocale: () =>
        set(
            produce((state: BaseState) => {
                state.locale =
                    state.locale === Locale.sv ? Locale.en : Locale.sv;
            }),
            false,
            "Switch locale"
        ),
    registerName: (name: string) =>
        set(
            produce((state: BaseState) => {
                state.username = name;
            }),
            false,
            "Set name"
        ),
    changeSupports: (checkbox: Supports) =>
        set(
            produce((state: BaseState) => {
                state.supports[checkbox] = !state.supports[checkbox];
            }),
            false,
            "Select support"
        ),
    setOverlay: (overlay: boolean) =>
        set(
            produce((state: BaseState) => {
                state.overlay = overlay;
            }),
            false,
            `Set overlay ${overlay}`
        ),
    setBasename: (bn: string) =>
        set(
            produce((state: BaseState) => {
                state.basename = bn;
            }),
            false,
            `Set basename as ${bn}`
        ),
    resetBaseState: () =>
        set(
            produce((state: BaseProps) => (state = baseInitialState)),
            false,
            "Reset Base Game"
        ),
});
