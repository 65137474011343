import { FC } from "react";
import useVotoStore from "src/store";
import "./footer.castle.scss";
import { translationService } from "src/services/translation.service";
import { Stage_3 } from "src/types/index.d";
import castle_thumbnail from "src/assets/svg/sand-castle-thumbnail.svg";
import { BackButton, NextButton } from "src/elemets/Buttons";

const FooterCastle: FC = () => {
    const {
        locale,
        vacation: { stages },
    } = useVotoStore();

    const castleStage = stages.find(
        (stage) => stage.name === Stage_3.puzzle_castle
    );

    const tillbakaText = translationService.translate("shared.back", locale);
    const nextText = translationService.translate("shared.next", locale);
    const ofText = translationService.translate("shared.of", locale);
    const mainTitleText = translationService.translate(
        "footer.vacation.mainTitle",
        locale
    );
    const castleTaskText = translationService.translate(
        "footer.vacation.castleTask",
        locale
    );

    return (
        <nav className="voto-footer-castle">
            <div className="stage-text">
                <div>
                    <h5 className="title">{mainTitleText}</h5>
                    <h5 className="task">{castleTaskText}</h5>
                </div>
                <img width={120} src={castle_thumbnail} alt="" />
            </div>
            <BackButton
                link="/beach"
                text={tillbakaText}
                customClass="back-button"
            />
            {castleStage?.completed && (
                <NextButton
                    link="/beach"
                    customClass="next-button"
                    text={nextText}
                />
            )}
            <div className="points">
                <h4>
                    {
                        castleStage?.subStages.filter(
                            (sub) => sub.completed === true
                        ).length
                    }{" "}
                    {ofText} 13
                </h4>
            </div>
        </nav>
    );
};
export default FooterCastle;
