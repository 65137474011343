import useVotoStore from "src/store";
import can_img from "src/assets/svg/trash-stage/can.svg";
import plastic_img from "src/assets/svg/trash-stage/plastic.svg";
import tier_img from "src/assets/svg/trash-stage/tier.svg";
import flask_img from "src/assets/svg/trash-stage/flask.svg";
import bin_animation from "src/assets/animations/GarbageCan.json";
import { useEffect, useRef, useState } from "react";
import checked from "src/assets/svg/check-green.svg";
import cross_red from "src/assets/svg/red-cross.svg";
import alert_icon from "src/assets/svg/alert-icon.svg";
import Draggable, { DraggableData } from "react-draggable";
import { Substage_trash } from "src/store/guardian.slice/guardian.slice";
import Lottie, { LottieRefCurrentProps } from "lottie-react";

const Trash = () => {
    const canRef = useRef<HTMLImageElement>(null);
    const flask_1_Ref = useRef<HTMLImageElement>(null);
    const flask_2_Ref = useRef<HTMLImageElement>(null);
    const tierRef = useRef<HTMLImageElement>(null);
    const plasticRef = useRef<HTMLImageElement>(null);
    const trashBinRef = useRef<HTMLDivElement>(null);
    const trashBinAnimationRef = useRef<LottieRefCurrentProps>(null);

    const [binPosition, setBinPosition] = useState({
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    });

    const [itemClicked, setItemClicked] = useState({
        [Substage_trash.can]: false,
        [Substage_trash.flask_1]: false,
        [Substage_trash.flask_2]: false,
        [Substage_trash.plastic]: false,
        [Substage_trash.tier]: false,
    });

    const [startDrag, setStartDrag] = useState(false);

    const {
        guardian: { stages, trashIntro, alert },
        guardianStageCompletedById,
        guardianStageFinishedById,
        guardianStageStartById,
        guardianSubstageCompletedByName,
    } = useVotoStore();
    const trashStage = stages[2];

    const getSubstageByName = (subName: Substage_trash) => {
        return (
            trashStage.subStages &&
            trashStage.subStages.find((sub) => sub.name === subName)
        );
    };

    useEffect(() => {
        if (
            (trashIntro && trashStage.tryCount !== 2) ||
            (trashIntro && trashStage.tryCount === 2)
        ) {
            guardianStageStartById(trashStage.id);
        }
    }, [trashStage, guardianStageStartById, trashIntro]);

    useEffect(() => {
        // if (trashStage.started) {
        trashBinRef.current &&
            setBinPosition({
                left: trashBinRef.current.offsetLeft,
                top: trashBinRef.current.offsetTop,
                bottom:
                    trashBinRef.current.offsetTop +
                    trashBinRef.current.offsetHeight,
                right:
                    trashBinRef.current.offsetLeft +
                    trashBinRef.current.offsetWidth,
            });
        // }

        return () => {};
    }, [trashBinRef, trashStage.id, trashStage.started]);

    useEffect(() => {
        if (
            trashStage.subStages &&
            trashStage.subStages.every((subS) => subS.completed === true)
        ) {
            if (!trashStage.completed) {
                guardianStageCompletedById(trashStage.id);
                guardianStageFinishedById(trashStage.id);
            }
        }

        return () => {};
    }, [
        trashStage.id,
        trashStage.subStages,
        guardianStageCompletedById,
        guardianStageFinishedById,
        trashStage.completed,
    ]);

    const trashBinHover = () => {
        if (trashBinRef.current) {
            trashBinAnimationRef.current?.play();
        }
    };

    const trashBinUnHover = () => {
        if (trashBinRef.current) {
            trashBinAnimationRef.current?.pause();
        }
    };

    const removeItem = (item: HTMLImageElement) => {
        item.id && guardianSubstageCompletedByName(trashStage.id, item.id);
    };

    const onStop = (e: any, ui: DraggableData) => {
        if (startDrag) {
            trashBinUnHover();
        }
        setStartDrag(false);

        const centerLeftItem =
            e.target.offsetLeft + ui.lastX + e.target.offsetWidth / 2;
        const centerTopItem =
            e.target.offsetTop + ui.lastY + e.target.offsetHeight / 2;
        if (binPosition.left !== 0) {
            if (
                centerLeftItem >= binPosition.left &&
                centerLeftItem <= binPosition.right &&
                centerTopItem >= binPosition.top &&
                centerTopItem <= binPosition.bottom
            ) {
                removeItem(e.target);
            }
        }
    };

    const onDrag = (e: any, ui: DraggableData) => {
        if (Object.keys(itemClicked).includes(e.target.id)) {
            e.target.style.opacity = 0.7;
        }
        setStartDrag(true);

        const centerLeftItem =
            e.target.offsetLeft + ui.lastX + e.target.offsetWidth / 2;
        const centerTopItem =
            e.target.offsetTop + ui.lastY + e.target.offsetHeight / 2;
        if (binPosition.left !== 0) {
            if (
                centerLeftItem >= binPosition.left &&
                centerLeftItem <= binPosition.right &&
                centerTopItem >= binPosition.top &&
                centerTopItem <= binPosition.bottom
            ) {
                trashBinHover();
            } else {
                trashBinUnHover();
            }
        }
    };

    const handleOnClickItem = (e: any) => {
        if (!trashStage.finished) {
            const newObj: any = { ...itemClicked };
            delete newObj[e.target.id];
            Object.keys(newObj).map((item) => (newObj[item] = false));
            trashBinHover();

            setItemClicked({
                ...newObj,
                [e.target.id]: true,
            });
        }
    };

    const handleBinClick = () => {
        const itemIndex = Object.values(itemClicked).findIndex(
            (item) => item === true
        );
        const selectedItem = Object.keys(itemClicked)[itemIndex];
        if (selectedItem) {
            guardianSubstageCompletedByName(trashStage.id, selectedItem);
            if (trashBinRef.current) {
                trashBinAnimationRef.current?.pause();
            }
        }
    };

    return (
        <div className={`stage-trash`}>
            <div
                ref={trashBinRef}
                className="self-item stage-trash__bin"
                onClick={handleBinClick}
            >
                <Lottie
                    animationData={bin_animation}
                    lottieRef={trashBinAnimationRef}
                    autoplay={false}
                    loop={true}
                    className="self-item stage-trash__bin-lottie"
                />
            </div>
            {/* <img
                onClick={handleBinClick}
                ref={trashBinRef}
                className="self-item stage-trash__bin"
                src={bin_img}
                alt=""
            /> */}
            {/* <img className="stage-trash__pickup" src={pickup} alt="" /> */}
            {!getSubstageByName(Substage_trash.can)?.completed && (
                <Draggable
                    nodeRef={canRef}
                    position={{ x: 0, y: 0 }}
                    onStop={onStop}
                    onDrag={onDrag}
                    disabled={trashStage.finished}
                    onMouseDown={handleOnClickItem}
                >
                    <img
                        ref={canRef}
                        className="self-item stage-trash__can"
                        id={Substage_trash.can}
                        src={can_img}
                        alt=""
                        style={
                            itemClicked[Substage_trash.can] === true &&
                            !startDrag
                                ? { opacity: 0.7 }
                                : {}
                        }
                    />
                </Draggable>
            )}
            {!getSubstageByName(Substage_trash.flask_1)?.completed && (
                <Draggable
                    nodeRef={flask_1_Ref}
                    position={{ x: 0, y: 0 }}
                    onStop={onStop}
                    onDrag={onDrag}
                    disabled={trashStage.finished}
                    onMouseDown={handleOnClickItem}
                >
                    <img
                        ref={flask_1_Ref}
                        className="self-item stage-trash__flask-1"
                        id={Substage_trash.flask_1}
                        src={flask_img}
                        alt=""
                        style={
                            itemClicked[Substage_trash.flask_1] === true &&
                            !startDrag
                                ? { opacity: 0.7 }
                                : {}
                        }
                    />
                </Draggable>
            )}
            {!getSubstageByName(Substage_trash.flask_2)?.completed && (
                <Draggable
                    nodeRef={flask_2_Ref}
                    position={{ x: 0, y: 0 }}
                    onStop={onStop}
                    onDrag={onDrag}
                    disabled={trashStage.finished}
                    onMouseDown={handleOnClickItem}
                >
                    <img
                        ref={flask_2_Ref}
                        className="self-item stage-trash__flask-2"
                        id={Substage_trash.flask_2}
                        src={flask_img}
                        alt=""
                        style={
                            itemClicked[Substage_trash.flask_2] === true &&
                            !startDrag
                                ? { opacity: 0.7 }
                                : {}
                        }
                    />
                </Draggable>
            )}
            {!getSubstageByName(Substage_trash.plastic)?.completed && (
                <Draggable
                    nodeRef={plasticRef}
                    position={{ x: 0, y: 0 }}
                    onStop={onStop}
                    onDrag={onDrag}
                    disabled={trashStage.finished}
                    onMouseDown={handleOnClickItem}
                >
                    <img
                        ref={plasticRef}
                        className="self-item stage-trash__plastic"
                        id={Substage_trash.plastic}
                        src={plastic_img}
                        alt=""
                        style={
                            itemClicked[Substage_trash.plastic] === true &&
                            !startDrag
                                ? { opacity: 0.7 }
                                : {}
                        }
                    />
                </Draggable>
            )}
            {!getSubstageByName(Substage_trash.tier)?.completed && (
                <Draggable
                    nodeRef={tierRef}
                    position={{ x: 0, y: 0 }}
                    onStop={onStop}
                    onDrag={onDrag}
                    disabled={trashStage.finished}
                    onMouseDown={handleOnClickItem}
                >
                    <img
                        ref={tierRef}
                        className="self-item stage-trash__tier"
                        id={Substage_trash.tier}
                        src={tier_img}
                        alt=""
                        style={
                            itemClicked[Substage_trash.tier] === true &&
                            !startDrag
                                ? { opacity: 0.7 }
                                : {}
                        }
                    />
                </Draggable>
            )}

            {trashStage.completed && trashStage.finished && (
                <img className="stage-trash__checked" src={checked} alt="" />
            )}
            {trashStage.finished && !trashStage.completed && (
                <img className="stage-trash__checked" src={cross_red} alt="" />
            )}
            {!trashStage.finished && !trashStage.completed && alert && (
                <img className="stage-trash__checked" src={alert_icon} alt="" />
            )}
        </div>
    );
};
export default Trash;
