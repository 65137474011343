import { Link } from "react-router-dom";
import useVotoStore from "src/store";
import { Basename } from "src/types/index.d";

const SelectBasename = () => {
    const { basename, setBasename } = useVotoStore();

    if (!basename) {
        return (
            <div
                style={{
                    position: "absolute",
                    top: "1vh",
                    width: "100%",
                }}
            >
                <ul
                    style={{
                        display: "flex",
                        listStyle: "none",
                        justifyContent: "center",
                        backgroundColor: "gray",
                        padding: "10px 0",
                    }}
                >
                    {Object.values(Basename).map((bn) => (
                        <li
                            key={bn}
                            style={{
                                margin: "0 5px",
                                padding: "2px",
                                backgroundColor: "black",
                                borderRadius: "6px",
                            }}
                        >
                            <Link
                                to={bn}
                                className="c-button button--primary button--small"
                                onClick={() => {
                                    setBasename(bn);
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 0);
                                }}
                            >
                                {bn}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
    return <></>;
};

export default SelectBasename;
