import { ReactComponent as PepeWings } from "src/assets/svg/quiz/avatars/1.svg";
import { ReactComponent as Poseidon } from "src/assets/svg/quiz/avatars/2.svg";
import { ReactComponent as Explorer } from "src/assets/svg/quiz/avatars/3.svg";
import { ReactComponent as Furioush } from "src/assets/svg/quiz/avatars/4.svg";
import SelectPlayerFooter from "src/compnents/footer/quiz/Select.player.footer";
import { translationService } from "src/services/translation.service";
import useVotoStore from "src/store";
import { AvatarName } from "src/types/index.d";

const Step3 = () => {
    const {
        quiz: { players },
        setAvatar,
        setPreviousSelectPlayerStep,
        setNextSelectPlayerStep,
        locale,
    } = useVotoStore();
    const firstPlayerAvatar = players.find((p) => p.id === 1)?.avatar;
    const secondPlayerAvatar = players.find((p) => p.id === 2)?.avatar;

    const selectCharText = translationService.translate(
        "quizPage.selectChar",
        locale
    );
    const pick_2Text = translationService.translate("quizPage.pick_2", locale);
    const poseidonText = translationService.translate(
        "quizPage.poseidon",
        locale
    );
    const explorerText = translationService.translate(
        "quizPage.explorer",
        locale
    );
    const pepewingsText = translationService.translate(
        "quizPage.pepewings",
        locale
    );
    const furioushText = translationService.translate(
        "quizPage.furioush",
        locale
    );

    const handleSelectAvatar = (a: string, n: AvatarName) => {
        if (firstPlayerAvatar && a === firstPlayerAvatar) return;
        setAvatar(2, a, n);
    };

    return (
        <>
            <div className="voto-select-player voto-select-player__step3">
                <h2 className="white-text voto-select-player__title">
                    {pick_2Text}
                </h2>
                <div className="voto-select-player__step3__list">
                    <div
                        className={`${
                            firstPlayerAvatar === "1"
                                ? "avatar-disabled"
                                : secondPlayerAvatar === "1"
                                ? "avatar-selected"
                                : ""
                        }`}
                    >
                        <PepeWings
                            onClick={() =>
                                handleSelectAvatar("1", AvatarName.peppe)
                            }
                            className={`avatar`}
                        />
                        <h3 className="white-text">{pepewingsText}</h3>
                    </div>
                    <div
                        className={`${
                            firstPlayerAvatar === "2"
                                ? "avatar-disabled"
                                : secondPlayerAvatar === "2"
                                ? "avatar-selected"
                                : ""
                        }`}
                    >
                        <Poseidon
                            onClick={() =>
                                handleSelectAvatar("2", AvatarName.poseidon)
                            }
                            className={`avatar`}
                        />
                        <h3 className="white-text">{poseidonText}</h3>
                    </div>
                    <div
                        className={`${
                            firstPlayerAvatar === "3"
                                ? "avatar-disabled"
                                : secondPlayerAvatar === "3"
                                ? "avatar-selected"
                                : ""
                        }`}
                    >
                        <Explorer
                            onClick={() =>
                                handleSelectAvatar("3", AvatarName.explorer)
                            }
                            className={`avatar`}
                        />
                        <h3 className="white-text">{explorerText}</h3>
                    </div>
                    <div
                        className={`${
                            firstPlayerAvatar === "4"
                                ? "avatar-disabled"
                                : secondPlayerAvatar === "4"
                                ? "avatar-selected"
                                : ""
                        }`}
                    >
                        <Furioush
                            onClick={() =>
                                handleSelectAvatar("4", AvatarName.furioush)
                            }
                            className={`avatar`}
                        />
                        <h3 className="white-text">{furioushText}</h3>
                    </div>
                </div>
            </div>
            <SelectPlayerFooter
                guide={""}
                clickNext={setNextSelectPlayerStep}
                showBackButton={true}
                clickBack={setPreviousSelectPlayerStep}
                showNextButton={secondPlayerAvatar !== "empty"}
            />
        </>
    );
};

export default Step3;
