import { Suspense, useEffect, useRef } from "react";
import cloud from "src/assets/images/adventure/guardian/clouds.png";
import land from "src/assets/images/adventure/guardian/land.png";
import bottom from "src/assets/images/adventure/guardian/bottom.png";
import vegi from "src/assets/images/adventure/guardian/vegi.png";
import Fish from "src/compnents/fish/Fish";
import { ReactComponent as VotoSignEn } from "src/assets/svg/Marine-area-ENG.svg";
import { ReactComponent as VotoSignSv } from "src/assets/svg/Marine-area-SVE.svg";
import "./background-guardian.scss";
import useVotoStore from "src/store";
import { Locale } from "src/types/index.d";

const BackgroundGuardian = () => {
    const protectedAreaLineRef = useRef<HTMLSpanElement>(null);
    const {
        setProtectedAreaOffset,
        locale,
        guardian: { stages, activeStage, alert, loose },
    } = useVotoStore();

    const { finished, started, completed, id } = stages[0];
    const protecteAreaOffset = protectedAreaLineRef?.current
        ?.offsetLeft as number;

    useEffect(() => {
        if (protecteAreaOffset) {
            setProtectedAreaOffset(protecteAreaOffset);
        }
        return () => {};
    }, [protecteAreaOffset, setProtectedAreaOffset]);

    return (
        <Suspense fallback={<>Loading...</>}>
            <div
                className={`c-guardian__background ${
                    loose ? "loose-bg" : alert ? "alert-bg" : ""
                }`}
            >
                <img className="topp-cloud" src={cloud} alt="" />
                <img className="topp-land" src={land} alt="" />
                <span
                    ref={protectedAreaLineRef}
                    className="protected-area-line"
                ></span>
                <Fish />
                {locale === Locale.en ? (
                    <VotoSignEn className="voto-sign" />
                ) : (
                    <VotoSignSv className="voto-sign" />
                )}
                {/* <img className="element-front" src={element_front} alt="" /> */}
                <img className="vegi vegi-1" src={vegi} alt="" />
                <img className="vegi vegi-2 vegi-blur" src={vegi} alt="" />
                <img className="vegi vegi-3" src={vegi} alt="" />
                <img className="vegi vegi-4 vegi-blur" src={vegi} alt="" />
                <img className="vegi vegi-5" src={vegi} alt="" />
                <img className="vegi vegi-6 vegi-blur" src={vegi} alt="" />
                <img className="vegi vegi-7" src={vegi} alt="" />
                <img className="bottom" src={bottom} alt="" />
            </div>
        </Suspense>
    );
};
export default BackgroundGuardian;
