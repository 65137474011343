import { Suspense, useEffect, useRef, useState } from "react";

import Navbar from "src/compnents/navbar/Navbar";
import BackgroundGuardian from "../../../compnents/backgrounds/guardian/Background-guardian";
import Scientist from "src/compnents/scientist/Scientist";
import FooterGuraidan from "src/compnents/footer/guardian/Footer.guradian";
import Ferry from "./stages/ferry/Ferry";
import useVotoStore from "src/store";
import Cargo from "./stages/cargo/Cargo";
import Trash from "./stages/trash/Trash";
import Trawling from "./stages/Trawling/Trawling";
import BravoScene from "./Bravo.scene";
import Welcome from "./audio/Welcome";
import { AudioNames } from "src/store/guardian.slice/guardian.slice";
import AudioText from "./audio/Audio-text";
import { audioService } from "src/services/audio.service";
import { translationService } from "src/services/translation.service";

const GuardianPage = () => {
    const {
        locale,
        guardian: {
            stages,
            activeStage,
            finished,
            alert,
            loose,
            wellcomeSpeechIsDone,
            saveSeaIntroDone,
            introStart,
            trashIntro,
            trawlIntro,
            trawlSlut,
            audioIsPlaying,
        },
        setLoose,
        setAlert,
        guardianStageFinishedById,
        guardianFinished,
        updateIntroStart,
    } = useVotoStore();
    const currentStage = stages[Number(activeStage) - 1];

    const saveSeaIntroAudio = audioService.getAudioSrc("raddahavet", locale);
    const saveSeaIntroText = translationService.translate(
        "scientist.guardian.saveSeaIntro",
        locale
    );

    useEffect(() => {
        if (
            currentStage.finished &&
            !currentStage.completed &&
            // currentStage.tryCount !== 2 &&
            introStart
        ) {
            updateIntroStart(false);
        }
    }, [
        currentStage.completed,
        currentStage.finished,
        currentStage.tryCount,
        introStart,
        updateIntroStart,
    ]);

    useEffect(() => {
        if (currentStage.completed && alert) {
            setAlert(false);
        }
        if (currentStage.completed && loose) {
            setLoose(false);
        }
    }, [currentStage.completed, alert, loose, setAlert, setLoose]);

    useEffect(() => {
        if (currentStage.timeout) {
            if (!currentStage.finished) {
                guardianStageFinishedById(currentStage.id);
            }
            if (
                activeStage === "4" &&
                currentStage.tryCount === 2 &&
                !finished &&
                trawlSlut
            ) {
                guardianFinished();
            }
        }
    }, [
        currentStage.timeout,
        guardianStageFinishedById,
        currentStage.id,
        trawlSlut,
        guardianFinished,
        currentStage.tryCount,
        activeStage,
        finished,
    ]);

    return (
        <Suspense fallback={<>Loading...</>}>
            <div className="c-guardian-page c-voto-page">
                <Navbar showHome={!audioIsPlaying ? true : false} />
                <BackgroundGuardian />

                {!currentStage.started && !wellcomeSpeechIsDone && <Welcome />}
                {wellcomeSpeechIsDone && !saveSeaIntroDone && (
                    <AudioText
                        text={saveSeaIntroText}
                        vo={saveSeaIntroAudio}
                        audioName={AudioNames.saveSeaIntroDone}
                    />
                )}
                <FerryAudioText />
                <UtslappAudioText />
                <TrashAudioText />
                <TrawlingAudioText />
                {wellcomeSpeechIsDone && !finished && (
                    <Scientist audioIsPlaying={audioIsPlaying} />
                )}
                {activeStage !== "4" && <Ferry />}
                {activeStage !== "4" && <Cargo />}
                {activeStage === "3" && trashIntro && <Trash />}

                {activeStage === "4" && trawlIntro && !finished && <Trawling />}

                {finished && <BravoScene />}

                <FooterGuraidan />
            </div>
        </Suspense>
    );
};

const FerryAudioText = () => {
    const {
        locale,
        guardian: {
            stages,
            activeStage,
            saveSeaIntroDone,
            startMotorBoat,
            motorboatBra,
            motorboatNeg,
            motorboatSlut,
            introStart,
        },
    } = useVotoStore();

    const currentStage = stages[Number(activeStage) - 1];

    const startMotorBoatAudio = audioService.getAudioSrc("motorbat", locale);
    const motorboatBraAudio = audioService.getAudioSrc("motorbat_bra", locale);
    const motorboatNegAudio = audioService.getAudioSrc("motorbat_neg", locale);
    const motorboatSlutAudio = audioService.getAudioSrc(
        "marinslutsats",
        locale
    );
    const startMotorBoatText = translationService.translate(
        "scientist.guardian.startMotorBoat",
        locale
    );
    const motorboatBraText = translationService.translate(
        "scientist.guardian.motorboatBra",
        locale
    );
    const motorboatNegText = translationService.translate(
        "scientist.guardian.motorboatNeg",
        locale
    );
    const motorboatSlutText = translationService.translate(
        "scientist.guardian.motorboatSlut",
        locale
    );

    return (
        <>
            {saveSeaIntroDone &&
                activeStage === "1" &&
                introStart &&
                !currentStage.finished &&
                // currentStage.tryCount !== 2 &&
                !startMotorBoat && (
                    <AudioText
                        text={startMotorBoatText}
                        vo={startMotorBoatAudio}
                        audioName={AudioNames.startMotorBoat}
                    />
                )}
            {saveSeaIntroDone &&
                activeStage === "1" &&
                currentStage.started &&
                currentStage.completed &&
                !motorboatBra && (
                    <AudioText
                        text={motorboatBraText}
                        vo={motorboatBraAudio}
                        audioName={AudioNames.motorboatBra}
                    />
                )}
            {saveSeaIntroDone &&
                activeStage === "1" &&
                currentStage.started &&
                !currentStage.completed &&
                currentStage.finished &&
                !motorboatNeg && (
                    <AudioText
                        text={motorboatNegText}
                        vo={motorboatNegAudio}
                        audioName={AudioNames.motorboatNeg}
                    />
                )}
            {saveSeaIntroDone &&
                activeStage === "1" &&
                currentStage.started &&
                (currentStage.completed ||
                    (currentStage.finished && currentStage.tryCount === 2)) &&
                (motorboatBra ||
                    (motorboatNeg && currentStage.tryCount === 2)) &&
                !motorboatSlut && (
                    <AudioText
                        text={motorboatSlutText}
                        vo={motorboatSlutAudio}
                        audioName={AudioNames.motorboatSlut}
                    />
                )}
        </>
    );
};

const UtslappAudioText = () => {
    const {
        locale,
        guardian: {
            stages,
            activeStage,
            motorboatSlut,
            utslappIntro,
            utslappBra,
            utslappNeg,
            utslappSlut,
            introStart,
        },
    } = useVotoStore();

    const currentStage = stages[Number(activeStage) - 1];

    const utslappIntroAudio = audioService.getAudioSrc("utslapp", locale);
    const utslappBraAudio = audioService.getAudioSrc("utslapp_pos", locale);
    const utslappNegAudio = audioService.getAudioSrc("utslapp_neg", locale);
    const utslappSlutAudio = audioService.getAudioSrc(
        "utslapp_slutsats",
        locale
    );

    const utslappIntroText = translationService.translate(
        "scientist.guardian.utslappIntro",
        locale
    );
    const utslappBraText = translationService.translate(
        "scientist.guardian.utslappBra",
        locale
    );
    const utslappNegText = translationService.translate(
        "scientist.guardian.utslappNeg",
        locale
    );
    const utslappSlutText = translationService.translate(
        "scientist.guardian.utslappSlut",
        locale
    );

    return (
        <>
            {motorboatSlut &&
                !utslappIntro &&
                !currentStage.finished &&
                activeStage === "2" &&
                // currentStage.tryCount !== 2 &&
                introStart && (
                    <AudioText
                        text={utslappIntroText}
                        vo={utslappIntroAudio}
                        audioName={AudioNames.utslappIntro}
                    />
                )}
            {motorboatSlut &&
                activeStage === "2" &&
                currentStage.started &&
                currentStage.completed &&
                !utslappBra && (
                    <AudioText
                        text={utslappBraText}
                        vo={utslappBraAudio}
                        audioName={AudioNames.utslappBra}
                    />
                )}
            {motorboatSlut &&
                activeStage === "2" &&
                currentStage.started &&
                !currentStage.completed &&
                currentStage.finished &&
                !utslappNeg && (
                    <AudioText
                        text={utslappNegText}
                        vo={utslappNegAudio}
                        audioName={AudioNames.utslappNeg}
                    />
                )}
            {motorboatSlut &&
                activeStage === "2" &&
                currentStage.started &&
                (currentStage.completed ||
                    (currentStage.finished && currentStage.tryCount === 2)) &&
                (utslappBra || (utslappNeg && currentStage.tryCount === 2)) &&
                !utslappSlut && (
                    <AudioText
                        text={utslappSlutText}
                        vo={utslappSlutAudio}
                        audioName={AudioNames.utslappSlut}
                    />
                )}
        </>
    );
};

const TrashAudioText = () => {
    const {
        locale,
        guardian: {
            stages,
            activeStage,
            utslappSlut,
            trashIntro,
            trashBra,
            trashNeg,
            trashSlut,
            introStart,
        },
    } = useVotoStore();

    const currentStage = stages[Number(activeStage) - 1];

    const trashIntroAudio = audioService.getAudioSrc("skrap", locale);
    const trashBraAudio = audioService.getAudioSrc("skrap_pos", locale);
    const trashNegAudio = audioService.getAudioSrc("skrap_neg", locale);
    const trashSlutAudio = audioService.getAudioSrc("skrap_slutsats", locale);

    const trashIntroText = translationService.translate(
        "scientist.guardian.trashIntro",
        locale
    );
    const trashBraText = translationService.translate(
        "scientist.guardian.trashBra",
        locale
    );
    const trashNegText = translationService.translate(
        "scientist.guardian.trashNeg",
        locale
    );
    const trashSlutText = translationService.translate(
        "scientist.guardian.trashSlut",
        locale
    );

    return (
        <>
            {utslappSlut &&
                !trashIntro &&
                activeStage === "3" &&
                !currentStage.finished &&
                // currentStage.tryCount !== 2 &&
                introStart && (
                    <AudioText
                        text={trashIntroText}
                        vo={trashIntroAudio}
                        audioName={AudioNames.trashIntro}
                    />
                )}
            {trashIntro &&
                activeStage === "3" &&
                currentStage.started &&
                currentStage.completed &&
                !trashBra && (
                    <AudioText
                        text={trashBraText}
                        vo={trashBraAudio}
                        audioName={AudioNames.trashBra}
                    />
                )}
            {trashIntro &&
                activeStage === "3" &&
                currentStage.started &&
                !currentStage.completed &&
                currentStage.finished &&
                !trashNeg && (
                    <AudioText
                        text={trashNegText}
                        vo={trashNegAudio}
                        audioName={AudioNames.trashNeg}
                    />
                )}
            {trashIntro &&
                activeStage === "3" &&
                currentStage.started &&
                (currentStage.completed ||
                    (currentStage.finished && currentStage.tryCount === 2)) &&
                (trashBra || (trashNeg && currentStage.tryCount === 2)) &&
                !trashSlut && (
                    <AudioText
                        text={trashSlutText}
                        vo={trashSlutAudio}
                        audioName={AudioNames.trashSlut}
                    />
                )}
        </>
    );
};
const TrawlingAudioText = () => {
    const {
        locale,
        guardian: {
            stages,
            activeStage,
            trashSlut,
            introStart,
            trawlPreIntro,
            trawlIntro,
            trawlBra,
            trawlNeg,
            trawlSlut,
        },
    } = useVotoStore();

    const currentStage = stages[Number(activeStage) - 1];

    const trawlPreIntroAudio = audioService.getAudioSrc("fiske", locale);
    const trawlIntroAudio = audioService.getAudioSrc("spoknat", locale);
    const trawlBraAudio = audioService.getAudioSrc("spoknat_pos", locale);
    const trawlNegAudio = audioService.getAudioSrc("spoknat_neg", locale);
    const trawlSlutAudio = audioService.getAudioSrc("spoknat_slutsats", locale);

    const trawlPreIntroText1 = translationService.translate(
        "scientist.guardian.trawlPreIntro.1",
        locale
    );
    const trawlPreIntroText2 = translationService.translate(
        "scientist.guardian.trawlPreIntro.2",
        locale
    );
    const trawlIntroText = translationService.translate(
        "scientist.guardian.trawlIntro",
        locale
    );
    const trawlBraText = translationService.translate(
        "scientist.guardian.trawlBra",
        locale
    );
    const trawlNegText = translationService.translate(
        "scientist.guardian.trawlNeg",
        locale
    );
    const trawlSlutText = translationService.translate(
        "scientist.guardian.trawlSlut",
        locale
    );

    return (
        <>
            {trashSlut &&
                !trawlPreIntro &&
                activeStage === "4" &&
                !currentStage.finished &&
                // currentStage.tryCount !== 2 &&
                introStart && (
                    <AudioText
                        text={trawlPreIntroText1}
                        text2={trawlPreIntroText2}
                        vo={trawlPreIntroAudio}
                        audioName={AudioNames.trawlPreIntro}
                    />
                )}
            {trashSlut &&
                trawlPreIntro &&
                activeStage === "4" &&
                !currentStage.finished &&
                introStart && (
                    <AudioText
                        text={trawlIntroText}
                        vo={trawlIntroAudio}
                        audioName={AudioNames.trawlIntro}
                    />
                )}
            {trawlIntro &&
                activeStage === "4" &&
                currentStage.started &&
                currentStage.completed &&
                !trawlBra && (
                    <AudioText
                        text={trawlBraText}
                        vo={trawlBraAudio}
                        audioName={AudioNames.trawlBra}
                    />
                )}
            {trawlIntro &&
                activeStage === "4" &&
                currentStage.started &&
                !currentStage.completed &&
                currentStage.finished &&
                !trawlNeg && (
                    <AudioText
                        text={trawlNegText}
                        vo={trawlNegAudio}
                        audioName={AudioNames.trawlNeg}
                    />
                )}
            {trawlIntro &&
                activeStage === "4" &&
                currentStage.started &&
                (currentStage.completed ||
                    (currentStage.finished && currentStage.tryCount === 2)) &&
                (trawlBra || (trawlNeg && currentStage.tryCount === 2)) &&
                !trawlSlut && (
                    <AudioText
                        text={trawlSlutText}
                        vo={trawlSlutAudio}
                        audioName={AudioNames.trawlSlut}
                    />
                )}
        </>
    );
};

export default GuardianPage;
