import VideoPlayer from "src/hooks/video-player/Video-player";
import bag_bg from "src/assets/images/adventure/vacation/bag-video-bg.png";
import { FC, SyntheticEvent, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useVotoStore from "src/store";

const VidoBag: FC = (): JSX.Element => {
    const bagVideoRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const { state } = useLocation();

    const { setBagVideoSeen } = useVotoStore();

    const handleOnEndVideo = (e: SyntheticEvent<HTMLVideoElement>) => {
        if (e.type === "ended" && bagVideoRef.current) {
            bagVideoRef.current.style.animation = "fade-out 1s";
            setBagVideoSeen(true);
            setTimeout(() => {
                if (state.lastPage) {
                    navigate("/end-page");
                } else {
                    navigate("/beach");
                }
            }, 1000);
        }
    };

    const endVideo = () => {
        if (bagVideoRef.current) {
            bagVideoRef.current.style.animation = "fade-out 1s";
            setBagVideoSeen(true);
            setTimeout(() => {
                if (state.lastPage) {
                    navigate("/end-page");
                } else {
                    navigate("/beach");
                }
            }, 1000);
        }
    };

    return (
        <div ref={bagVideoRef} className="c-packup__bag-video fade-in">
            <img className="c-packup__bag-video__bg" src={bag_bg} alt="" />

            <VideoPlayer
                size="90%"
                url={state.videoUrl}
                onEndedVideo={handleOnEndVideo}
                autoplay={true}
            />
            <button
                style={{ position: "absolute", right: "0", top: "50%" }}
                className="hidden-close"
                onClick={endVideo}
            ></button>
        </div>
    );
};

export default VidoBag;
