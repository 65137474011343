import axios from "axios";
import useVotoStore from "src/store";
import { SingleLocation } from "src/types/index.d";

export const getLocations = async () => {
    const { setLocations } = useVotoStore.getState();
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/wp-json/voto/v1/locations`
        );

        if (response.status === 200) {
            const result = Object.values(response.data);
            setLocations(result);
        }
    } catch (error) {
        console.log("Server error", error);
    }
};

export const getLocationByName = async (basename: string) => {
    const { setFilms } = useVotoStore.getState();
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/wp-json/voto/v1/location/?name=${basename}`
        );

        if (response.status === 200) {
            const result = Object.values(response.data)[0] as SingleLocation;
            setFilms(result);
        }
    } catch (error) {
        console.log("Server error", error);
    }
};
