import produce from "immer";
import {
    Adventures,
    Stage_3,
    SubStage_find_hidden,
    SubStage_find_treasures,
    SubStage_go_home,
    SubStage_go_vacation,
    SubStage_puzzle_castle,
    VotoState,
} from "src/types/index.d";
import { StateCreator } from "zustand";

export interface VacationState extends VacationProps {
    vacationStageStartById: (id: string) => void;
    vacationStageFinishedById: (id: string) => void;
    vacationStageCompletedById: (id: string) => void;
    vacationSubstageCompletedByName: (
        stageId: string,
        subStageId: string
    ) => void;
    vacationSubstageDoneWith: (
        stageId: string,
        subStageId: string,
        doneWidth: string
    ) => void;
    setBagVideoSeen: (s: boolean) => void;
    // replayFerryStage: () => void;
    // setActiveStage: (id: string) => void;
    // vacationStart: () => void;
    // vacationFinished: () => void;
    setTimingoutTreasure: (b: boolean) => void;
    setTotalFrame: (n: number) => void;
    treasureTrayAgain: () => void;
    snorkleAudioIntroDone: (name: SubStage_find_hidden) => void;
    updateSnorkleAudioIsPlaying: (b: boolean) => void;
}

export interface VacationProps {
    [Adventures.vacation]: {
        id: string;
        name: Adventures.vacation;
        started: boolean;
        completed: boolean;
        finished: boolean;
        bagVideoSeen: boolean;
        stages: {
            id: string;
            name: Stage_3;
            started: boolean;
            completed: boolean;
            finished: boolean;
            time?: number;
            timeout: boolean;
            totalFrame?: number;
            tryCount?: number;
            audioIsPlaying?: boolean;
            subStages: {
                id: string;
                name:
                    | SubStage_go_vacation
                    | SubStage_go_home
                    | SubStage_find_hidden
                    | SubStage_puzzle_castle
                    | SubStage_find_treasures;
                completed: boolean;
                doneWith?: string;
                intro?: boolean;
                point?: number;
            }[];
        }[];
    };
}
export const vacationInitialState: VacationProps = {
    [Adventures.vacation]: {
        id: "3",
        name: Adventures.vacation,
        started: false,
        completed: false,
        finished: false,
        bagVideoSeen: false,
        stages: [
            {
                id: "3-1",
                name: Stage_3.got_to_beach,
                started: false,
                completed: false,
                finished: false,
                timeout: false,
                subStages: [
                    {
                        id: "1",
                        name: SubStage_go_vacation.sun_glasses,
                        completed: false,
                    },
                    {
                        id: "2",
                        name: SubStage_go_vacation.raquets,
                        completed: false,
                    },
                    {
                        id: "3",
                        name: SubStage_go_vacation.scuba,
                        completed: false,
                    },
                    {
                        id: "4",
                        name: SubStage_go_vacation.dino,
                        completed: false,
                    },
                    {
                        id: "5",
                        name: SubStage_go_vacation.ball,
                        completed: false,
                    },
                    {
                        id: "6",
                        name: SubStage_go_vacation.camera,
                        completed: false,
                    },
                    {
                        id: "7",
                        name: SubStage_go_vacation.bucket,
                        completed: false,
                    },
                ],
            },
            {
                id: "3-2",
                name: Stage_3.snorkle_find_hidden,
                started: false,
                completed: false,
                finished: false,
                timeout: false,
                audioIsPlaying: false,
                subStages: [
                    {
                        id: "1",
                        name: SubStage_find_hidden.gadde,
                        completed: false,
                        intro: false,
                    },
                    {
                        id: "2",
                        name: SubStage_find_hidden.jellyfish,
                        completed: false,
                        intro: false,
                    },
                    {
                        id: "3",
                        name: SubStage_find_hidden.star,
                        completed: false,
                        intro: false,
                    },
                    {
                        id: "4",
                        name: SubStage_find_hidden.crab,
                        completed: false,
                        intro: false,
                    },
                    {
                        id: "5",
                        name: SubStage_find_hidden.mussels,
                        completed: false,
                        intro: false,
                    },
                    {
                        id: "6",
                        name: SubStage_find_hidden.piggvar,
                        completed: false,
                        intro: false,
                    },
                ],
            },
            {
                id: "3-3",
                name: Stage_3.puzzle_castle,
                started: false,
                completed: false,
                finished: false,
                timeout: false,
                subStages: [
                    {
                        id: "1",
                        name: SubStage_puzzle_castle.door,
                        completed: false,
                    },
                    {
                        id: "2",
                        name: SubStage_puzzle_castle.main_tower,
                        completed: false,
                    },
                    {
                        id: "3",
                        name: SubStage_puzzle_castle.main_top_tower,
                        completed: false,
                    },
                    {
                        id: "4",
                        name: SubStage_puzzle_castle.tower_1,
                        completed: false,
                        doneWith: "",
                    },
                    {
                        id: "5",
                        name: SubStage_puzzle_castle.top_tower_1,
                        completed: false,
                        doneWith: "",
                    },
                    {
                        id: "6",
                        name: SubStage_puzzle_castle.tower_2,
                        completed: false,
                        doneWith: "",
                    },
                    {
                        id: "7",
                        name: SubStage_puzzle_castle.top_tower_2,
                        completed: false,
                        doneWith: "",
                    },
                    {
                        id: "8",
                        name: SubStage_puzzle_castle.left_door,
                        completed: false,
                    },
                    {
                        id: "9",
                        name: SubStage_puzzle_castle.right_door,
                        completed: false,
                    },
                    {
                        id: "10",
                        name: SubStage_puzzle_castle.triangle_ladder,
                        completed: false,
                    },
                    {
                        id: "11",
                        name: SubStage_puzzle_castle.ladder,
                        completed: false,
                    },
                    {
                        id: "12",
                        name: SubStage_puzzle_castle.tree_1,
                        completed: false,
                        doneWith: "",
                    },
                    {
                        id: "13",
                        name: SubStage_puzzle_castle.tree_2,
                        completed: false,
                        doneWith: "",
                    },
                ],
            },
            {
                id: "3-4",
                name: Stage_3.find_treasures,
                started: false,
                completed: false,
                finished: false,
                time: 30,
                timeout: false,
                tryCount: 0,
                totalFrame: 0,
                subStages: [
                    {
                        id: "1",
                        name: SubStage_find_treasures.amber_stone,
                        completed: false,
                        point: 2,
                    },
                    {
                        id: "2",
                        name: SubStage_find_treasures.shellsnack,
                        completed: false,
                        point: 2,
                    },
                    {
                        id: "3",
                        name: SubStage_find_treasures.conch,
                        completed: false,
                        point: 3,
                    },
                    {
                        id: "4",
                        name: SubStage_find_treasures.fossil,
                        completed: false,
                        point: 4,
                    },
                    {
                        id: "5",
                        name: SubStage_find_treasures.vassen,
                        completed: false,
                        point: 5,
                    },
                ],
            },
            {
                id: "3-5",
                name: Stage_3.got_to_home,
                started: false,
                completed: false,
                finished: false,
                timeout: false,
                subStages: [
                    {
                        id: "1",
                        name: SubStage_go_home.sun_glasses,
                        completed: false,
                    },
                    {
                        id: "2",
                        name: SubStage_go_home.raquets,
                        completed: false,
                    },
                    {
                        id: "3",
                        name: SubStage_go_home.scuba,
                        completed: false,
                    },
                    {
                        id: "4",
                        name: SubStage_go_home.dino,
                        completed: false,
                    },
                    {
                        id: "5",
                        name: SubStage_go_home.ball,
                        completed: false,
                    },
                    {
                        id: "6",
                        name: SubStage_go_home.camera,
                        completed: false,
                    },
                    {
                        id: "7",
                        name: SubStage_go_home.bucket,
                        completed: false,
                    },
                    {
                        id: "8",
                        name: SubStage_go_home.food_box,
                        completed: false,
                    },
                    {
                        id: "9",
                        name: SubStage_go_home.sun_cream,
                        completed: false,
                    },
                    {
                        id: "10",
                        name: SubStage_go_home.water_flask,
                        completed: false,
                    },
                ],
            },
        ],
    },
};

export const vacationSlice: StateCreator<VotoState, [], [], VacationState> = (
    set: (
        partial: (state: VacationState) => Partial<VacationState>,
        replace?: boolean,
        name?: string
    ) => void
) => ({
    ...vacationInitialState,
    vacationStageStartById: (id: string) => {
        set(
            produce((state: VacationState) => {
                state[Adventures.vacation].stages.find(
                    (stage) => stage.id === id
                )!.started = true;
            }),
            false,
            `Stage ${id} started`
        );
    },
    vacationStageFinishedById: (id: string) => {
        set(
            produce((state: VacationState) => {
                state[Adventures.vacation].stages.find(
                    (stage) => stage.id === id
                )!.finished = true;
            }),
            false,
            `Stage ${id} finished`
        );
    },
    vacationStageCompletedById: (id: string) => {
        set(
            produce((state: VacationState) => {
                state[Adventures.vacation].stages.find(
                    (stage) => stage.id === id
                )!.completed = true;
            }),
            false,
            `Stage ${id} completed`
        );
    },
    vacationSubstageCompletedByName: (
        stageId: string,
        subStageName: string
    ) => {
        set(
            produce((state: VacationState) => {
                state[Adventures.vacation].stages
                    .find((stage) => stage.id === stageId)!
                    .subStages!.find(
                        (sub) => sub.name === subStageName
                    )!.completed = true;
            }),
            false,
            `Stage ${stageId}, subStage ${subStageName} completed`
        );
    },
    vacationSubstageDoneWith: (
        stageId: string,
        subStageName: string,
        doneWidth: string
    ) => {
        set(
            produce((state: VacationState) => {
                state[Adventures.vacation].stages
                    .find((stage) => stage.id === stageId)!
                    .subStages!.find(
                        (sub) => sub.name === subStageName
                    )!.doneWith = doneWidth;
            }),
            false,
            `Stage ${stageId}, subStage ${subStageName} done width ${doneWidth}`
        );
    },

    setBagVideoSeen: (seen: boolean) => {
        set(
            produce((state: VacationState) => {
                state[Adventures.vacation].bagVideoSeen = seen;
            }),
            false,
            `Bag video seen set ${seen}`
        );
    },

    setTotalFrame: (n: number) => {
        set(
            produce((state: VacationState) => {
                state[Adventures.vacation].stages.find(
                    (st) => st.name === Stage_3.find_treasures
                )!.totalFrame = n;
            }),
            false,
            `Total frame set to ${n}`
        );
    },
    treasureTrayAgain: () => {
        set(
            produce((state: VacationState) => {
                let stage = state[Adventures.vacation].stages.find(
                    (st) => st.name === Stage_3.find_treasures
                );
                if (stage) {
                    stage.finished = false;
                    stage.timeout = false;
                    stage.tryCount = 2;
                    stage.started = false;
                    stage.completed = false;
                    stage?.subStages?.map((sub) => (sub.completed = false));
                    state[Adventures.vacation].stages[3] = stage;
                }
            }),
            false,
            `Treasure stage try again`
        );
    },

    // vacationStart: () => {
    //     set(
    //         produce((state: vacationState) => {
    //             state[Adventures.vacation].started = true;
    //         }),
    //         false,
    //         `vacation started`
    //     );
    // },
    // vacationFinished: () => {
    //     set(
    //         produce((state: vacationState) => {
    //             state[Adventures.vacation].finished = true;
    //         }),
    //         false,
    //         `vacation finished`
    //     );
    // },
    setTimingoutTreasure: (b: boolean) =>
        set(
            produce((state: VacationState) => {
                state[Adventures.vacation].stages.find(
                    (stage) => stage.name === Stage_3.find_treasures
                )!.timeout = b;
            }),
            false,
            `Stage treasures timeout`
        ),
    updateSnorkleAudioIsPlaying: (b: boolean) =>
        set(
            produce((state: VacationState) => {
                state[Adventures.vacation].stages.find(
                    (stage) => stage.name === Stage_3.snorkle_find_hidden
                )!.audioIsPlaying = b;
            }),
            false,
            `Stage Snorkle audio is playing set ${b}`
        ),
    snorkleAudioIntroDone: (name: SubStage_find_hidden) =>
        set(
            produce((state: VacationState) => {
                state[Adventures.vacation].stages
                    .find(
                        (stage) => stage.name === Stage_3.snorkle_find_hidden
                    )!
                    .subStages.find((sub) => sub.name === name)!.intro = true;
            }),
            false,
            `Stage Snorkle ${name} intro done`
        ),
});
