import useVotoStore from "src/store";
import balance_meter_pin from "src/assets/svg/balance-meter/balance-meter-pin.svg";
import balance_meter_frame from "src/assets/svg/balance-meter/balance-meter-frame.svg";
import "./pin.scss";
import { Stage_3 } from "src/types/index.d";

const PinTreasures = () => {
    const {
        vacation: { stages },
    } = useVotoStore();

    const treasuresStage = stages.find(
        (stage) => stage.name === Stage_3.find_treasures
    );

    const moving = {
        animation: `pinMove ${treasuresStage!.time}s linear forwards`,
    };
    const loose = { transform: "translateX(-50%) rotateZ(-90deg)" };
    const win = { transform: "translateX(-50%) rotateZ(90deg)" };

    return (
        <div className="voto-balance-meter">
            <div className="voto-balance-meter__container">
                <img
                    className="balance-meter__frame"
                    src={balance_meter_frame}
                    alt=""
                />
                <img
                    style={
                        treasuresStage!.started &&
                        !treasuresStage!.completed &&
                        !treasuresStage!.finished
                            ? moving
                            : treasuresStage!.finished &&
                              !treasuresStage!.completed
                            ? loose
                            : treasuresStage!.finished &&
                              treasuresStage!.completed
                            ? win
                            : {}
                    }
                    className="balance-meter__pin"
                    src={balance_meter_pin}
                    alt=""
                />
            </div>
        </div>
    );
};
export default PinTreasures;
