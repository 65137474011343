import { Suspense } from "react";
import { Link } from "react-router-dom";
import BaseBackground from "src/compnents/backgrounds/base/base-background";
import { translationService } from "src/services/translation.service";
import useVotoStore from "src/store";
import useAnalyticsEventTracker from "src/hooks/google-analytics/useAnalyticsEventTracker";
import { ReactComponent as StartBg } from "src/assets/svg/buttons-blue/start-bg.svg";

const EndPage = () => {
    const { locale, resetState } = useVotoStore();
    const gaEventTracker = useAnalyticsEventTracker("Click event");

    const titleText = translationService.translate("endPage.title", locale);
    const replayText = translationService.translate("endPage.replay", locale);

    const handleReset = () => {
        gaEventTracker("Click on End");
        resetState();
    };

    return (
        <Suspense fallback={<>Loading...</>}>
            <div className="c-first-page">
                <BaseBackground hasOverlay={true} />
                <div className="title">
                    <h2>{titleText}</h2>
                </div>

                <Link
                    to="/"
                    onClick={handleReset}
                    className="c-button button-background button-blue-start button--large btn-start"
                >
                    <StartBg className="button-bg" />
                    {replayText}
                </Link>
            </div>
        </Suspense>
    );
};
export default EndPage;
