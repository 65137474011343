import BaseBackground from "src/compnents/backgrounds/base/base-background";
import Navbar from "src/compnents/navbar/Navbar";
import useVotoStore from "src/store";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Profiles from "src/compnents/profiles/Profiles";
import WelcomeQuiz from "src/compnents/quiz/Welcome.quiz";

const SelectPlayer = () => {
    const {
        quiz: { step, wellcomeQuizIsDone },
        locale,
    } = useVotoStore();

    const showPage = () => {
        switch (step) {
            case "step1":
                return <Step1 />;
            case "step2":
                return <Step2 />;
            case "step3":
                return <Step3 />;
            case "step4":
                return <Step4 />;
            default:
                return <></>;
        }
    };

    return (
        <div className="voto-select-player-page c-voto-page">
            <Navbar showHome={wellcomeQuizIsDone} />
            <BaseBackground />
            {!wellcomeQuizIsDone && <WelcomeQuiz />}
            {step !== "step1" && step !== "step4" ? <Profiles /> : ""}
            {showPage()}
        </div>
    );
};

export default SelectPlayer;
