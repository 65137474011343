import { ReactComponent as QuizFrame } from "src/assets/svg/quiz/quiz-frame-one.svg";
import QuestionQuiz from "src/compnents/quiz/question/Question.quiz";
import Spinner from "src/compnents/spinner/Spinner";
import useVotoStore from "src/store";
import { showAreaBg } from "src/utils/helpers";
import { useEffect } from "react";
import PlayerSection from "src/compnents/quiz/player/Player";

const TwoPlayerQuiz = () => {
    const {
        quiz: {
            players,
            winner,
            countdown,
            activePlayer,
            selectedQuestion,
            showFeedback,
        },
        handleAfterQuestionAnswerd,
        updateRound,
    } = useVotoStore();

    const player1 = players[0];

    useEffect(() => {
        if (selectedQuestion.answered && !countdown.state) {
            handleAfterQuestionAnswerd(selectedQuestion);
        }
    }, [countdown, selectedQuestion.answered]);

    useEffect(() => {
        if (!winner.length) {
            setTimeout(() => {
                if (showFeedback) {
                    updateRound(false, true);
                }
            }, 0);
        }
    }, [winner, showFeedback]);

    return (
        <>
            <div className="one-player-quiz">
                <QuizFrame className="quiz-frame" />
                <Spinner />
                <div className="one-player-quiz__players">
                    <div
                        className={`one-player-quiz__players-1 ${
                            player1.id !== activePlayer
                                ? "bg-disactive"
                                : "bg-dark-blue"
                            // : "bg-light-blue"
                        }`}
                    >
                        <PlayerSection
                            player={player1}
                            active={player1.id === activePlayer}
                        />
                    </div>
                </div>
                <div className="one-player-quiz__questions">
                    <div
                        className={`one-player-quiz__questions-1 ${
                            player1.id !== activePlayer
                                ? "bg-disactive"
                                : showAreaBg(selectedQuestion.area)
                        }`}
                    >
                        {player1.id === activePlayer && (
                            <>
                                <QuestionQuiz player={player1} />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default TwoPlayerQuiz;
