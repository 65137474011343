import { FC } from "react";
import useVotoStore from "src/store";
import "../packup.scss";
import { translationService } from "src/services/translation.service";
import { Stage_3 } from "src/types/index.d";

const FooterPackupBeach: FC = () => {
    const {
        locale,
        vacation: { stages },
    } = useVotoStore();

    const packupBeachStage = stages.find(
        (stage) => stage.name === Stage_3.got_to_home
    );

    const ofText = translationService.translate("shared.of", locale);
    const mainTitleText = translationService.translate(
        "footer.vacation.mainTitle",
        locale
    );
    const packupBeachTaskText = translationService.translate(
        "footer.vacation.packupBeachTask",
        locale
    );
    const packupBeachTipsText = translationService.translate(
        "footer.vacation.packupBeachTips",
        locale
    );

    return (
        <nav className="voto-footer-packup-home">
            <div className="title">
                <h5 className="top-text">{mainTitleText}</h5>
                <h5 className="bottom-text">{packupBeachTaskText}</h5>
            </div>
            <div className="description">
                <h5>{packupBeachTipsText}</h5>
            </div>
            <div className="points">
                <h4>
                    {
                        packupBeachStage?.subStages.filter(
                            (sub) => sub.completed === true
                        ).length
                    }{" "}
                    {ofText} 7
                </h4>
            </div>
        </nav>
    );
};
export default FooterPackupBeach;
