import { FC } from "react";
import { Link } from "react-router-dom";
import useVotoStore from "src/store";
import "./navbar.scss";
import { ReactComponent as Home_icon } from "src/assets/svg/buttons-blue/home-white.svg";
import finish_icon from "src/assets/svg/buttons-blue/finish-icon-red.svg";
import { translationService } from "src/services/translation.service";
import { ReactComponent as HomeBackground } from "src/assets/svg/buttons-blue/home-bg.svg";
import { ReactComponent as FinishBackground } from "src/assets/svg/buttons-blue/finish-bg.svg";

const Navbar: FC<{ showName?: boolean; showHome: boolean }> = ({
    showName = true,
    showHome,
}) => {
    const { username, locale, setOverlay } = useVotoStore();

    const homeText = translationService.translate("navbar.home", locale);
    const endGameText = translationService.translate("shared.end", locale);
    const playAsText = translationService.translate("navbar.playAs", locale);

    return (
        <nav className="voto-navbar">
            {showHome && (
                <div className="home">
                    <div className="home-content">
                        <Link
                            className="c-button button-background button--medium button-blue-home btn-home"
                            to="/adventure"
                        >
                            <HomeBackground className="button-bg" />
                            <Home_icon className="button-icon" />
                            {homeText}
                        </Link>
                    </div>
                </div>
            )}
            {showName && (
                <div className="name-finish">
                    {/* <div className="name">
                        <p>{playAsText}:</p>
                        <h3>{username}</h3>
                    </div> */}
                    <div className="finish-game">
                        <button
                            className="c-button button-background button--medium button-red-finish btn-reset-game"
                            onClick={() => setOverlay(true)}
                        >
                            <FinishBackground className="button-bg" />

                            <img src={finish_icon} alt="" />

                            {endGameText}
                        </button>
                    </div>
                </div>
            )}
        </nav>
    );
};
export default Navbar;
