import { FC } from "react";
import useVotoStore from "src/store";
import "./footer.treasures.scss";
import { translationService } from "src/services/translation.service";
import { Stage_3 } from "src/types/index.d";
import ClockTreasures from "../clock/Clock-treasures";
import PinTreasures from "../pin/Pin-treasures";
import { Link } from "react-router-dom";
import treasures_thumbnail from "src/assets/svg/treasures-thumbnail.svg";
import { BackButton, NextButton, TryAgainButton } from "src/elemets/Buttons";

const FooterTreasures: FC = () => {
    const {
        locale,
        vacation: { stages },
        treasureTrayAgain,
    } = useVotoStore();

    const treasureStage = stages.find(
        (stage) => stage.name === Stage_3.find_treasures
    );

    const tillbakaText = translationService.translate("shared.back", locale);
    const tryAgainText = translationService.translate(
        "shared.tryAgain",
        locale
    );
    const nextText = translationService.translate("shared.next", locale);
    const ofText = translationService.translate("shared.of", locale);
    const mainTitleText = translationService.translate(
        "footer.vacation.mainTitle",
        locale
    );
    const treasureTaskText = translationService.translate(
        "footer.vacation.treasureTask",
        locale
    );

    return (
        <nav className="voto-footer-treasures">
            <div className="stage-text">
                <div>
                    <h5 className="title">{mainTitleText}</h5>
                    <h5 className="task">{treasureTaskText}</h5>
                </div>
                <img width={120} src={treasures_thumbnail} alt="" />
            </div>
            {treasureStage?.finished && (
                <BackButton
                    link="/beach"
                    text={tillbakaText}
                    customClass="back-button"
                />
            )}
            <PinTreasures />
            {(treasureStage?.completed ||
                (treasureStage?.finished && treasureStage.tryCount === 2)) && (
                <NextButton
                    link="/beach"
                    customClass="next-button"
                    text={nextText}
                />
            )}
            {!treasureStage?.completed &&
                treasureStage?.finished &&
                treasureStage?.tryCount !== 2 && (
                    <TryAgainButton
                        text={tryAgainText}
                        customClass="try-again-button"
                        onclick={treasureTrayAgain}
                    />
                )}
            <div className="points">
                <h4>
                    {treasureStage?.subStages.reduce((accum, curr) => {
                        if (curr.point && curr.completed) {
                            return accum + curr.point;
                        }
                        return accum;
                    }, 0)}{" "}
                    {ofText} 16
                </h4>
            </div>
            {treasureStage?.started && <ClockTreasures />}
        </nav>
    );
};
export default FooterTreasures;
