import cloud from "src/assets/images/adventure/vacation/castle/cloud.png";
import bag_1 from "src/assets/images/adventure/vacation/packup/bag-1.png";
import bag_2 from "src/assets/images/adventure/vacation/packup/bag-2.png";
import bag_3 from "src/assets/images/adventure/vacation/packup/bag-3.png";
import bucket from "src/assets/images/adventure/vacation/packup/bucket.png";
import ball from "src/assets/images/adventure/vacation/packup/ball.png";
import scuba from "src/assets/images/adventure/vacation/packup/scuba.png";
import sun_glasses from "src/assets/images/adventure/vacation/packup/sun-glasses.png";
import raquets from "src/assets/images/adventure/vacation/packup/raquets.png";
import camera from "src/assets/images/adventure/vacation/packup/camera.png";
import dino from "src/assets/images/adventure/vacation/packup/dino.png";
import bucket_border from "src/assets/images/adventure/vacation/packup/bucket-border.png";
import ball_border from "src/assets/images/adventure/vacation/packup/ball-border.png";
import scuba_border from "src/assets/images/adventure/vacation/packup/scuba-border.png";
import sun_glasses_border from "src/assets/images/adventure/vacation/packup/sun-glasses-border.png";
import raquets_border from "src/assets/images/adventure/vacation/packup/raquets-border.png";
import camera_border from "src/assets/images/adventure/vacation/packup/camer-border.png";
import dino_border from "src/assets/images/adventure/vacation/packup/dino-border.png";
import water_flask_border from "src/assets/images/adventure/vacation/packup/water-flask-border.png";
import food_box_border from "src/assets/images/adventure/vacation/packup/food-box-border.png";
import sun_cream_border from "src/assets/images/adventure/vacation/packup/sun-cream-border.png";
import food_box from "src/assets/images/adventure/vacation/packup/food-box.png";
import water_flask from "src/assets/images/adventure/vacation/packup/water-flask.png";
import sun_cream from "src/assets/images/adventure/vacation/packup/sun-cream.png";
import Draggable, { DraggableData } from "react-draggable";
import { useEffect, useRef, useState } from "react";
import useVotoStore from "src/store";
import { Locale, SubStage_go_home } from "src/types/index.d";
import Navbar from "src/compnents/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import FooterPackupBeach from "src/compnents/footer/packup/beach/Footer-packup-beach";
import beachVideo_swe from "src/assets/videos/Voto_Strandfilmen_PART3_REV6_SWE.mp4";
import beachVideo_en from "src/assets/videos/Voto_Strandfilmen_PART3_REV6_En.mp4";

const PackupBeach = () => {
    const bagRef = useRef<HTMLImageElement>(null);
    const bucketRef = useRef<HTMLImageElement>(null);
    const ballRef = useRef<HTMLImageElement>(null);
    const scubaRef = useRef<HTMLImageElement>(null);
    const sun_glassesRef = useRef<HTMLImageElement>(null);
    const raquetsRef = useRef<HTMLImageElement>(null);
    const cameraRef = useRef<HTMLImageElement>(null);
    const dinoRef = useRef<HTMLImageElement>(null);
    const food_box_ref = useRef<HTMLImageElement>(null);
    const sun_cream_ref = useRef<HTMLImageElement>(null);
    const water_flask_ref = useRef<HTMLImageElement>(null);

    const navigate = useNavigate();

    const [bagPosition, setBagPosition] = useState({
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    });

    const [itemClicked, setItemClicked] = useState({
        [SubStage_go_home.sun_glasses]: false,
        [SubStage_go_home.ball]: false,
        [SubStage_go_home.bucket]: false,
        [SubStage_go_home.dino]: false,
        [SubStage_go_home.camera]: false,
        [SubStage_go_home.scuba]: false,
        [SubStage_go_home.raquets]: false,
        [SubStage_go_home.food_box]: false,
        [SubStage_go_home.sun_cream]: false,
        [SubStage_go_home.water_flask]: false,
    });

    const [startDrag, setStartDrag] = useState(false);
    const zoomInBagTime = 5.5;

    const {
        locale,
        vacation: { stages },
        vacationSubstageCompletedByName,
        vacationStageStartById,
        vacationStageCompletedById,
    } = useVotoStore();
    const packupStage = stages[4];
    const packupHomeStage = stages[0];

    const beachVideo = locale === Locale.en ? beachVideo_en : beachVideo_swe;

    const getSubstageByName = (subName: SubStage_go_home) => {
        return packupStage.subStages.find((sub) => sub.name === subName);
    };

    useEffect(() => {
        if (!packupStage.started) {
            vacationStageStartById(packupStage.id);
        }
        bagRef.current &&
            setBagPosition({
                left: bagRef.current.offsetLeft,
                top: bagRef.current.offsetTop,
                bottom: bagRef.current.offsetTop + bagRef.current.offsetHeight,
                right: bagRef.current.offsetLeft + bagRef.current.offsetWidth,
            });

        return () => {};
    }, [bagRef, packupStage.id, vacationStageStartById, packupStage.started]);

    useEffect(() => {
        if (
            packupStage.subStages.filter((subS) => subS.completed === true)
                .length === 7
        ) {
            if (!packupStage.completed) {
                vacationStageCompletedById(packupStage.id);
            }

            setTimeout(() => {
                navigate("/bag-video", {
                    state: { videoUrl: beachVideo, lastPage: true },
                });
            }, (zoomInBagTime - 1) * 1000);
        }

        return () => {};
    }, [
        packupStage.id,
        packupStage.subStages,
        vacationStageCompletedById,
        packupStage.completed,
        navigate,
    ]);

    const bagHover = () => {
        if (bagRef.current) {
            bagRef.current.style.transform = "scale(1.1)";
        }
    };

    const bagUnHover = () => {
        if (bagRef.current) {
            bagRef.current.style.transform = "scale(1)";
        }
    };

    const removeItem = (item: HTMLImageElement) => {
        item.id && vacationSubstageCompletedByName(packupStage.id, item.id);
    };

    const onStop = (e: any, ui: DraggableData) => {
        if (startDrag) {
            e.target.style.opacity = 1;
        }
        bagUnHover();
        setStartDrag(false);

        const centerLeftItem =
            e.target.offsetLeft + ui.lastX + e.target.offsetWidth / 2;
        const centerTopItem =
            e.target.offsetTop + ui.lastY + e.target.offsetHeight / 2;
        if (bagPosition.left !== 0) {
            if (
                centerLeftItem >= bagPosition.left &&
                centerLeftItem <= bagPosition.right &&
                centerTopItem >= bagPosition.top &&
                centerTopItem <= bagPosition.bottom
            ) {
                removeItem(e.target);
            }
        }
    };
    const onDrag = (e: any, ui: DraggableData) => {
        if (Object.keys(itemClicked).includes(e.target.id)) {
            e.target.style.opacity = 0.7;
        }
        setStartDrag(true);

        const centerLeftItem =
            e.target.offsetLeft + ui.lastX + e.target.offsetWidth / 2;
        const centerTopItem =
            e.target.offsetTop + ui.lastY + e.target.offsetHeight / 2;
        if (bagPosition.left !== 0) {
            if (
                centerLeftItem >= bagPosition.left &&
                centerLeftItem <= bagPosition.right &&
                centerTopItem >= bagPosition.top &&
                centerTopItem <= bagPosition.bottom
            ) {
                bagHover();
            } else {
                bagUnHover();
            }
        }
    };

    const handleOnClickItem = (e: any) => {
        const newObj: any = { ...itemClicked };
        delete newObj[e.target.id];
        Object.keys(newObj).map((item) => (newObj[item] = false));

        setItemClicked({
            ...newObj,
            [e.target.id]: true,
        });
    };

    const handleBagClick = () => {
        const itemIndex = Object.values(itemClicked).findIndex(
            (item) => item === true
        );
        const selectedItem = Object.keys(itemClicked)[itemIndex];
        if (selectedItem) {
            vacationSubstageCompletedByName(packupStage.id, selectedItem);
            if (bagRef.current) {
                bagRef.current.style.transform = "scale(1.1)";
            }
        }
    };

    const bagClass = () =>
        packupStage.subStages.filter((sub) => sub.completed === true).length >=
        6
            ? "bag--3"
            : packupStage.subStages.filter((sub) => sub.completed).length >= 3
            ? "bag--2"
            : "bag--1";
    const bagSrc = () =>
        packupStage.subStages.filter((sub) => sub.completed === true).length >=
        6
            ? bag_3
            : packupStage.subStages.filter((sub) => sub.completed).length >= 3
            ? bag_2
            : bag_1;

    return (
        <div
            className="c-packup-beach__wrapper c-voto-page"
            style={
                packupStage.completed
                    ? {
                          animation: `zoom-in-bag ${zoomInBagTime}s forwards`,
                      }
                    : {}
            }
        >
            <div className={`c-packup-beach`}>
                <Navbar showHome={true} />
                <img src={cloud} className="c-packup-beach__cloud" alt="" />

                <img
                    onClick={handleBagClick}
                    ref={bagRef}
                    className={`self-item c-packup-beach__bag ${bagClass()}`}
                    src={bagSrc()}
                    alt=""
                />
                {/* <img
                    onClick={handleBagClick}
                    ref={bagRef}
                    className=" self-item c-packup-beach__bag"
                    src={bag}
                    alt=""
                /> */}

                {!getSubstageByName(SubStage_go_home.food_box)?.completed && (
                    <>
                        <Draggable
                            nodeRef={food_box_ref}
                            position={{ x: 0, y: 0 }}
                            onStop={onStop}
                            onDrag={onDrag}
                            onMouseDown={handleOnClickItem}
                        >
                            <img
                                ref={food_box_ref}
                                id={SubStage_go_home.food_box}
                                className="self-item c-packup-beach__food-box"
                                src={food_box}
                                alt=""
                                style={
                                    itemClicked[SubStage_go_home.food_box] ===
                                        true && !startDrag
                                        ? { opacity: 0.7 }
                                        : {}
                                }
                            />
                        </Draggable>
                        <img
                            src={food_box_border}
                            className="self-item border-items c-packup-beach__food-box"
                            alt=""
                            style={
                                itemClicked[SubStage_go_home.food_box] ===
                                    true && !startDrag
                                    ? { display: "block" }
                                    : {}
                            }
                        />
                    </>
                )}
                {!getSubstageByName(SubStage_go_home.sun_cream)?.completed && (
                    <>
                        <Draggable
                            nodeRef={sun_cream_ref}
                            position={{ x: 0, y: 0 }}
                            onStop={onStop}
                            onDrag={onDrag}
                            onMouseDown={handleOnClickItem}
                        >
                            <img
                                ref={sun_cream_ref}
                                id={SubStage_go_home.sun_cream}
                                className="self-item c-packup-beach__sun-cream"
                                src={sun_cream}
                                alt=""
                                style={
                                    itemClicked[SubStage_go_home.sun_cream] ===
                                        true && !startDrag
                                        ? { opacity: 0.7 }
                                        : {}
                                }
                            />
                        </Draggable>
                        <img
                            src={sun_cream_border}
                            className="self-item border-items c-packup-beach__sun-cream"
                            alt=""
                            style={
                                itemClicked[SubStage_go_home.sun_cream] ===
                                    true && !startDrag
                                    ? { display: "block" }
                                    : {}
                            }
                        />
                    </>
                )}
                {!getSubstageByName(SubStage_go_home.water_flask)
                    ?.completed && (
                    <>
                        <Draggable
                            nodeRef={water_flask_ref}
                            position={{ x: 0, y: 0 }}
                            onStop={onStop}
                            onDrag={onDrag}
                            onMouseDown={handleOnClickItem}
                        >
                            <img
                                ref={water_flask_ref}
                                id={SubStage_go_home.water_flask}
                                className="self-item c-packup-beach__water-flask"
                                src={water_flask}
                                alt=""
                                style={
                                    itemClicked[
                                        SubStage_go_home.water_flask
                                    ] === true && !startDrag
                                        ? { opacity: 0.7 }
                                        : {}
                                }
                            />
                        </Draggable>
                        <img
                            src={water_flask_border}
                            className="self-item border-items c-packup-beach__water-flask"
                            alt=""
                            style={
                                itemClicked[SubStage_go_home.water_flask] ===
                                    true && !startDrag
                                    ? { display: "block" }
                                    : {}
                            }
                        />
                    </>
                )}

                {!getSubstageByName(SubStage_go_home.sun_glasses)?.completed &&
                    packupHomeStage.subStages.find(
                        (sub) => sub.name === SubStage_go_home.sun_glasses
                    )?.completed && (
                        <>
                            <Draggable
                                nodeRef={sun_glassesRef}
                                position={{ x: 0, y: 0 }}
                                onStop={onStop}
                                onDrag={onDrag}
                                onMouseDown={handleOnClickItem}
                            >
                                <img
                                    ref={sun_glassesRef}
                                    id={SubStage_go_home.sun_glasses}
                                    className="self-item c-packup-beach__sun-glasses"
                                    src={sun_glasses}
                                    alt=""
                                    style={
                                        itemClicked[
                                            SubStage_go_home.sun_glasses
                                        ] === true && !startDrag
                                            ? { opacity: 0.7 }
                                            : {}
                                    }
                                />
                            </Draggable>
                            <img
                                src={sun_glasses_border}
                                className="self-item border-items c-packup-beach__sun-glasses"
                                alt=""
                                style={
                                    itemClicked[
                                        SubStage_go_home.sun_glasses
                                    ] === true && !startDrag
                                        ? { display: "block" }
                                        : {}
                                }
                            />
                        </>
                    )}
                {!getSubstageByName(SubStage_go_home.raquets)?.completed &&
                    packupHomeStage.subStages.find(
                        (sub) => sub.name === SubStage_go_home.raquets
                    )?.completed && (
                        <>
                            <Draggable
                                nodeRef={raquetsRef}
                                position={{ x: 0, y: 0 }}
                                onStop={onStop}
                                onDrag={onDrag}
                                onMouseDown={handleOnClickItem}
                            >
                                <img
                                    ref={raquetsRef}
                                    id={SubStage_go_home.raquets}
                                    className="self-item c-packup-beach__raquets"
                                    src={raquets}
                                    alt=""
                                    style={
                                        itemClicked[
                                            SubStage_go_home.raquets
                                        ] === true && !startDrag
                                            ? { opacity: 0.7 }
                                            : {}
                                    }
                                />
                            </Draggable>
                            <img
                                src={raquets_border}
                                className="self-item border-items c-packup-beach__raquets"
                                alt=""
                                style={
                                    itemClicked[SubStage_go_home.raquets] ===
                                        true && !startDrag
                                        ? { display: "block" }
                                        : {}
                                }
                            />
                        </>
                    )}
                {!getSubstageByName(SubStage_go_home.scuba)?.completed &&
                    packupHomeStage.subStages.find(
                        (sub) => sub.name === SubStage_go_home.scuba
                    )?.completed && (
                        <>
                            <Draggable
                                nodeRef={scubaRef}
                                position={{ x: 0, y: 0 }}
                                onStop={onStop}
                                onDrag={onDrag}
                                onMouseDown={handleOnClickItem}
                            >
                                <img
                                    ref={scubaRef}
                                    id={SubStage_go_home.scuba}
                                    className="self-item c-packup-beach__scuba"
                                    src={scuba}
                                    alt=""
                                    style={
                                        itemClicked[SubStage_go_home.scuba] ===
                                            true && !startDrag
                                            ? { opacity: 0.7 }
                                            : {}
                                    }
                                />
                            </Draggable>
                            <img
                                src={scuba_border}
                                className="self-item border-items c-packup-beach__scuba"
                                alt=""
                                style={
                                    itemClicked[SubStage_go_home.scuba] ===
                                        true && !startDrag
                                        ? { display: "block" }
                                        : {}
                                }
                            />
                        </>
                    )}

                {!getSubstageByName(SubStage_go_home.dino)?.completed &&
                    packupHomeStage.subStages.find(
                        (sub) => sub.name === SubStage_go_home.dino
                    )?.completed && (
                        <>
                            <Draggable
                                nodeRef={dinoRef}
                                position={{ x: 0, y: 0 }}
                                onStop={onStop}
                                onDrag={onDrag}
                                onMouseDown={handleOnClickItem}
                            >
                                <img
                                    ref={dinoRef}
                                    id={SubStage_go_home.dino}
                                    className="self-item c-packup-beach__dino"
                                    src={dino}
                                    alt=""
                                    style={
                                        itemClicked[SubStage_go_home.dino] ===
                                            true && !startDrag
                                            ? { opacity: 0.7 }
                                            : {}
                                    }
                                />
                            </Draggable>
                            <img
                                src={dino_border}
                                className="self-item border-items c-packup-beach__dino"
                                alt=""
                                style={
                                    itemClicked[SubStage_go_home.dino] ===
                                        true && !startDrag
                                        ? { display: "block" }
                                        : {}
                                }
                            />
                        </>
                    )}
                {!getSubstageByName(SubStage_go_home.ball)?.completed &&
                    packupHomeStage.subStages.find(
                        (sub) => sub.name === SubStage_go_home.ball
                    )?.completed && (
                        <>
                            <Draggable
                                nodeRef={ballRef}
                                position={{ x: 0, y: 0 }}
                                onStop={onStop}
                                onDrag={onDrag}
                                onMouseDown={handleOnClickItem}
                            >
                                <img
                                    ref={ballRef}
                                    id={SubStage_go_home.ball}
                                    className="self-item c-packup-beach__ball"
                                    src={ball}
                                    alt=""
                                    style={
                                        itemClicked[SubStage_go_home.ball] ===
                                            true && !startDrag
                                            ? { opacity: 0.7 }
                                            : {}
                                    }
                                />
                            </Draggable>
                            <img
                                src={ball_border}
                                className="self-item border-items c-packup-beach__ball"
                                alt=""
                                style={
                                    itemClicked[SubStage_go_home.ball] ===
                                        true && !startDrag
                                        ? { display: "block" }
                                        : {}
                                }
                            />
                        </>
                    )}
                {!getSubstageByName(SubStage_go_home.camera)?.completed &&
                    packupHomeStage.subStages.find(
                        (sub) => sub.name === SubStage_go_home.camera
                    )?.completed && (
                        <>
                            <Draggable
                                nodeRef={cameraRef}
                                position={{ x: 0, y: 0 }}
                                onStop={onStop}
                                onDrag={onDrag}
                                onMouseDown={handleOnClickItem}
                            >
                                <img
                                    ref={cameraRef}
                                    id={SubStage_go_home.camera}
                                    className="self-item c-packup-beach__camera"
                                    src={camera}
                                    alt=""
                                    style={
                                        itemClicked[SubStage_go_home.camera] ===
                                            true && !startDrag
                                            ? { opacity: 0.7 }
                                            : {}
                                    }
                                />
                            </Draggable>
                            <img
                                src={camera_border}
                                className="self-item border-items c-packup-beach__camera"
                                alt=""
                                style={
                                    itemClicked[SubStage_go_home.camera] ===
                                        true && !startDrag
                                        ? { display: "block" }
                                        : {}
                                }
                            />
                        </>
                    )}
                {!getSubstageByName(SubStage_go_home.bucket)?.completed &&
                    packupHomeStage.subStages.find(
                        (sub) => sub.name === SubStage_go_home.bucket
                    )?.completed && (
                        <>
                            <Draggable
                                nodeRef={bucketRef}
                                position={{ x: 0, y: 0 }}
                                onStop={onStop}
                                onDrag={onDrag}
                                onMouseDown={handleOnClickItem}
                            >
                                <img
                                    ref={bucketRef}
                                    id={SubStage_go_home.bucket}
                                    className="self-item c-packup-beach__bucket"
                                    src={bucket}
                                    alt=""
                                    style={
                                        itemClicked[SubStage_go_home.bucket] ===
                                            true && !startDrag
                                            ? { opacity: 0.7 }
                                            : {}
                                    }
                                />
                            </Draggable>
                            <img
                                src={bucket_border}
                                className="self-item border-items c-packup-beach__bucket"
                                alt=""
                                style={
                                    itemClicked[SubStage_go_home.bucket] ===
                                        true && !startDrag
                                        ? { display: "block" }
                                        : {}
                                }
                            />
                        </>
                    )}
            </div>
            <FooterPackupBeach />
        </div>
    );
};
export default PackupBeach;
