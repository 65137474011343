import { FC } from "react";
import useVotoStore from "src/store";
import "./footer.snorkle.scss";
import { translationService } from "src/services/translation.service";
import { Stage_3 } from "src/types/index.d";
import snorkle_thumbnail from "src/assets/svg/snorkle-thumbnail.svg";
import { BackButton, NextButton } from "src/elemets/Buttons";

const FooterSnorkle: FC = () => {
    const {
        locale,
        vacation: { stages },
    } = useVotoStore();

    const snorkleStage = stages.find(
        (stage) => stage.name === Stage_3.snorkle_find_hidden
    );

    const tillbakaText = translationService.translate("shared.back", locale);
    const nextText = translationService.translate("shared.next", locale);
    const ofText = translationService.translate("shared.of", locale);
    const mainTitleText = translationService.translate(
        "footer.vacation.mainTitle",
        locale
    );
    const snorkleTaskText = translationService.translate(
        "footer.vacation.snorkleTask",
        locale
    );

    return (
        <nav className="voto-footer-snorkle">
            <div className="stage-text">
                <div>
                    <h5 className="title">{mainTitleText}</h5>
                    <h5 className="task">{snorkleTaskText}</h5>
                </div>
                <img width={120} src={snorkle_thumbnail} alt="" />
            </div>
            {!snorkleStage?.audioIsPlaying && (
                <BackButton
                    link="/beach"
                    text={tillbakaText}
                    customClass="back-button"
                />
            )}
            {snorkleStage?.completed && !snorkleStage.audioIsPlaying && (
                <NextButton
                    link="/beach"
                    customClass="next-button"
                    text={nextText}
                />
            )}
            <div className="points">
                <h4>
                    {
                        snorkleStage?.subStages.filter(
                            (sub) => sub.completed === true
                        ).length
                    }{" "}
                    {ofText} 6
                </h4>
            </div>
        </nav>
    );
};
export default FooterSnorkle;
