import groud_layer_1 from "src/assets/svg/snorkle/ground-layer-1.svg";
import groud_layer_2 from "src/assets/svg/snorkle/ground-layer-2.svg";
import groud_layer_3 from "src/assets/svg/snorkle/ground-layer-3.svg";
import groud_layer_4 from "src/assets/svg/snorkle/ground-layer-4.svg";
import groud_layer_5 from "src/assets/svg/snorkle/ground-layer-5.svg";
import sand_hill from "src/assets/svg/snorkle/sand-hill.svg";
import mussels from "src/assets/svg/snorkle/hidden-mussels.svg";
import star_see from "src/assets/svg/snorkle/star-sea.svg";
import hidden_piggvar from "src/assets/svg/snorkle/hidden-piggvar.svg";
import piggvar from "src/assets/svg/snorkle/piggvar.svg";
import hidden_gedde from "src/assets/svg/snorkle/hidden-gedde.svg";
import gedde from "src/assets/svg/snorkle/gedde.svg";
import crab_img from "src/assets/svg/snorkle/crab.svg";
import hidden_jellyfish from "src/assets/svg/snorkle/hidden-jelly-fish.svg";
import jellyfish from "src/assets/svg/snorkle/jelly-fish.svg";
import plants from "src/assets/svg/snorkle/plants.svg";
import check_svg from "src/assets/svg/green-check-1.svg";
import useVotoStore from "src/store";
import { useEffect, useRef, useState } from "react";
import { SubStage_find_hidden } from "src/types/index.d";
import Navbar from "src/compnents/navbar/Navbar";
import FooterSnorkle from "src/compnents/footer/snorkle/Footer.snorkle";
import SnorkleAudio from "./Snorkle-audio-text";
import Scientist from "src/compnents/scientist/Scientist";
import { translationService } from "src/services/translation.service";
import { audioService } from "src/services/audio.service";
import Draggable, { DraggableData } from "react-draggable";

const SnorklePage = () => {
    const mussels_ph_ref = useRef<HTMLImageElement>(null);
    const star_ph_ref = useRef<HTMLImageElement>(null);
    const piggvar_ph_ref = useRef<HTMLImageElement>(null);
    const gadde_ph_ref = useRef<HTMLImageElement>(null);
    const crab_ph_ref = useRef<HTMLImageElement>(null);
    const jellyfish_ph_ref = useRef<HTMLImageElement>(null);

    const main_container_ref = useRef<HTMLDivElement>(null);
    const container_ref = useRef<HTMLDivElement>(null);
    const mussels_ref = useRef<HTMLImageElement>(null);
    const star_ref = useRef<HTMLImageElement>(null);
    const piggvar_ref = useRef<HTMLImageElement>(null);
    const gadde_ref = useRef<HTMLImageElement>(null);
    const crab_ref = useRef<HTMLImageElement>(null);
    const jellyfish_ref = useRef<HTMLImageElement>(null);

    const [startDrag, setStartDrag] = useState(false);
    const [placeholder, setPlaceholder] = useState<any>({
        [SubStage_find_hidden.crab]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        [SubStage_find_hidden.gadde]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        [SubStage_find_hidden.jellyfish]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        [SubStage_find_hidden.mussels]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        [SubStage_find_hidden.piggvar]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        [SubStage_find_hidden.star]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
    });

    const {
        locale,
        vacation: { stages },
        vacationSubstageCompletedByName,
        vacationStageStartById,
        vacationStageCompletedById,
    } = useVotoStore();
    const snorkleStage = stages[1];

    useEffect(() => {
        if (placeholder[SubStage_find_hidden.crab].left === 0) {
            setPlaceholder({
                [SubStage_find_hidden.crab]: {
                    left: crab_ph_ref.current
                        ? crab_ph_ref.current?.offsetLeft
                        : 0,
                    right: crab_ph_ref.current
                        ? crab_ph_ref.current?.offsetLeft +
                          crab_ph_ref.current?.offsetWidth
                        : 0,
                    top: crab_ph_ref.current
                        ? crab_ph_ref.current?.offsetTop
                        : 0,
                    bottom: crab_ph_ref.current
                        ? crab_ph_ref.current?.offsetTop +
                          crab_ph_ref.current?.offsetHeight
                        : 0,
                },
                [SubStage_find_hidden.gadde]: {
                    left: gadde_ph_ref.current
                        ? gadde_ph_ref.current?.offsetLeft
                        : 0,
                    right: gadde_ph_ref.current
                        ? gadde_ph_ref.current?.offsetLeft +
                          gadde_ph_ref.current?.offsetWidth
                        : 0,
                    top: gadde_ph_ref.current
                        ? gadde_ph_ref.current?.offsetTop
                        : 0,
                    bottom: gadde_ph_ref.current
                        ? gadde_ph_ref.current?.offsetTop +
                          gadde_ph_ref.current?.offsetHeight
                        : 0,
                },
                [SubStage_find_hidden.jellyfish]: {
                    left: jellyfish_ph_ref.current
                        ? jellyfish_ph_ref.current?.offsetLeft
                        : 0,
                    right: jellyfish_ph_ref.current
                        ? jellyfish_ph_ref.current?.offsetLeft +
                          jellyfish_ph_ref.current?.offsetWidth
                        : 0,
                    top: jellyfish_ph_ref.current
                        ? jellyfish_ph_ref.current?.offsetTop
                        : 0,
                    bottom: jellyfish_ph_ref.current
                        ? jellyfish_ph_ref.current?.offsetTop +
                          jellyfish_ph_ref.current?.offsetHeight
                        : 0,
                },
                [SubStage_find_hidden.mussels]: {
                    left: mussels_ph_ref.current
                        ? mussels_ph_ref.current?.offsetLeft
                        : 0,
                    right: mussels_ph_ref.current
                        ? mussels_ph_ref.current?.offsetLeft +
                          mussels_ph_ref.current?.offsetWidth
                        : 0,
                    top: mussels_ph_ref.current
                        ? mussels_ph_ref.current?.offsetTop
                        : 0,
                    bottom: mussels_ph_ref.current
                        ? mussels_ph_ref.current?.offsetTop +
                          mussels_ph_ref.current?.offsetHeight
                        : 0,
                },
                [SubStage_find_hidden.piggvar]: {
                    left: piggvar_ph_ref.current
                        ? piggvar_ph_ref.current?.offsetLeft
                        : 0,
                    right: piggvar_ph_ref.current
                        ? piggvar_ph_ref.current?.offsetLeft +
                          piggvar_ph_ref.current?.offsetWidth
                        : 0,
                    top: piggvar_ph_ref.current
                        ? piggvar_ph_ref.current?.offsetTop
                        : 0,
                    bottom: piggvar_ph_ref.current
                        ? piggvar_ph_ref.current?.offsetTop +
                          piggvar_ph_ref.current?.offsetHeight
                        : 0,
                },
                [SubStage_find_hidden.star]: {
                    left: star_ph_ref.current
                        ? star_ph_ref.current?.offsetLeft
                        : 0,
                    right: star_ph_ref.current
                        ? star_ph_ref.current?.offsetLeft +
                          star_ph_ref.current?.offsetWidth
                        : 0,
                    top: star_ph_ref.current
                        ? star_ph_ref.current?.offsetTop
                        : 0,
                    bottom: star_ph_ref.current
                        ? star_ph_ref.current?.offsetTop +
                          star_ph_ref.current?.offsetHeight
                        : 0,
                },
            });
        }
    }, [placeholder]);

    const gadda_audio = audioService.getAudioSrc("fakta_gadda", locale);
    const flundra_audio = audioService.getAudioSrc("fakta_flundra", locale);
    const sjostjarna_audio = audioService.getAudioSrc(
        "fakt_sjostjarna",
        locale
    );
    const manet_audio = audioService.getAudioSrc("fakta_manet", locale);
    const mussla_audio = audioService.getAudioSrc("fakta_mussla", locale);
    const krabba_audio = audioService.getAudioSrc("fakta_krabba", locale);

    const titleText = translationService.translate(
        "vacation.snorkle.title",
        locale
    );
    const flundraText = translationService.translate(
        "scientist.snorkle.flundra",
        locale
    );
    const sjostjarnaText = translationService.translate(
        "scientist.snorkle.sjostjarna",
        locale
    );
    const musslaText = translationService.translate(
        "scientist.snorkle.mussla",
        locale
    );
    const gaddaText = translationService.translate(
        "scientist.snorkle.gadda",
        locale
    );
    const manetText = translationService.translate(
        "scientist.snorkle.manet",
        locale
    );
    const krabbaText = translationService.translate(
        "scientist.snorkle.krabba",
        locale
    );

    const getTranslation = (fish: string) => {
        switch (fish) {
            case SubStage_find_hidden.piggvar:
                return flundraText;
            case SubStage_find_hidden.star:
                return sjostjarnaText;
            case SubStage_find_hidden.mussels:
                return musslaText;
            case SubStage_find_hidden.gadde:
                return gaddaText;
            case SubStage_find_hidden.jellyfish:
                return manetText;
            case SubStage_find_hidden.crab:
                return krabbaText;
            default:
                return "";
        }
    };

    const getImageSrc = (fish: string) => {
        switch (fish) {
            case SubStage_find_hidden.piggvar:
                return piggvar;
            case SubStage_find_hidden.star:
                return star_see;
            case SubStage_find_hidden.mussels:
                return mussels;
            case SubStage_find_hidden.gadde:
                return gedde;
            case SubStage_find_hidden.jellyfish:
                return jellyfish;
            case SubStage_find_hidden.crab:
                return crab_img;
            default:
                return "";
        }
    };

    useEffect(() => {
        if (!snorkleStage.started) {
            vacationStageStartById(snorkleStage.id);
        }

        return () => {};
    }, [vacationStageStartById, snorkleStage.id, snorkleStage.started]);

    const handleSelectHidden = (hiddenObject: SubStage_find_hidden) => {
        if (!snorkleStage.audioIsPlaying) {
            if (
                !snorkleStage.subStages.find((sub) => sub.name === hiddenObject)
                    ?.completed
            ) {
                vacationSubstageCompletedByName(snorkleStage.id, hiddenObject);
            }
        }
    };

    useEffect(() => {
        if (snorkleStage.subStages.every((sub) => sub.completed === true)) {
            vacationStageCompletedById(snorkleStage.id);
        }
        return () => {};
    }, [snorkleStage.id, snorkleStage.subStages, vacationStageCompletedById]);

    const onDrag = (e: any, ui: DraggableData) => {
        setStartDrag(true);
    };

    const onStop = (e: any, ui: DraggableData) => {
        setStartDrag(false);
        if (Object.values(SubStage_find_hidden).includes(e.target.id)) {
            const dragItem = e.target.id as SubStage_find_hidden;
            const container = e.target.parentElement;

            const centerLeftItem =
                container.offsetLeft +
                e.target.offsetLeft +
                ui.lastX +
                e.target.offsetWidth / 2;
            const centerTopItem =
                container?.offsetTop +
                e.target.offsetTop +
                ui.lastY +
                e.target.offsetHeight / 2;

            if (
                centerLeftItem >=
                    placeholder[SubStage_find_hidden[dragItem]].left &&
                centerLeftItem <=
                    placeholder[SubStage_find_hidden[dragItem]].right &&
                centerTopItem >=
                    placeholder[SubStage_find_hidden[dragItem]].top &&
                centerTopItem <=
                    placeholder[SubStage_find_hidden[dragItem]].bottom
            ) {
                handleSelectHidden(dragItem);
            }
        }
    };

    return (
        <div className="c-snorkle__wrapper">
            <div className="c-snorkle">
                <Navbar showHome={!snorkleStage.audioIsPlaying && true} />
                <div className="background">
                    <div
                        className="background__top-box"
                        ref={main_container_ref}
                    >
                        <div className="background__top-box__text">
                            <h5>{titleText}</h5>
                        </div>
                        <div
                            className="background__top-box__list"
                            ref={container_ref}
                        >
                            <div className="background__top-box__list-item">
                                <Draggable
                                    nodeRef={gadde_ref}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                    position={{ x: 0, y: 0 }}
                                    disabled={
                                        snorkleStage.subStages.find(
                                            (sub) =>
                                                sub.name ===
                                                SubStage_find_hidden.gadde
                                        )?.completed ||
                                        snorkleStage.audioIsPlaying
                                    }
                                >
                                    <img
                                        src={gedde}
                                        ref={gadde_ref}
                                        id={SubStage_find_hidden.gadde}
                                        className="background__top-box__list-item__image"
                                        alt=""
                                    />
                                </Draggable>
                                {snorkleStage.subStages.find(
                                    (sub) =>
                                        sub.name === SubStage_find_hidden.gadde
                                )?.completed && (
                                    <>
                                        {!snorkleStage.subStages.find(
                                            (sub) =>
                                                sub.name ===
                                                SubStage_find_hidden.gadde
                                        )?.intro && (
                                            <SnorkleAudio
                                                audioSrc={gadda_audio}
                                                audioName={
                                                    SubStage_find_hidden.gadde
                                                }
                                            />
                                        )}
                                        <img
                                            src={check_svg}
                                            className="background__top-box__list-item__check-svg"
                                            alt=""
                                        />
                                    </>
                                )}
                            </div>
                            <div className="background__top-box__list-item">
                                <Draggable
                                    nodeRef={jellyfish_ref}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                    position={{ x: 0, y: 0 }}
                                    disabled={
                                        snorkleStage.subStages.find(
                                            (sub) =>
                                                sub.name ===
                                                SubStage_find_hidden.jellyfish
                                        )?.completed ||
                                        snorkleStage.audioIsPlaying
                                    }
                                >
                                    <img
                                        src={jellyfish}
                                        id={SubStage_find_hidden.jellyfish}
                                        ref={jellyfish_ref}
                                        className="background__top-box__list-item__image"
                                        alt=""
                                    />
                                </Draggable>
                                {snorkleStage.subStages.find(
                                    (sub) =>
                                        sub.name ===
                                        SubStage_find_hidden.jellyfish
                                )?.completed && (
                                    <>
                                        {!snorkleStage.subStages.find(
                                            (sub) =>
                                                sub.name ===
                                                SubStage_find_hidden.jellyfish
                                        )?.intro && (
                                            <SnorkleAudio
                                                audioSrc={manet_audio}
                                                audioName={
                                                    SubStage_find_hidden.jellyfish
                                                }
                                            />
                                        )}
                                        <img
                                            src={check_svg}
                                            className="background__top-box__list-item__check-svg"
                                            alt=""
                                        />
                                    </>
                                )}
                            </div>
                            <div className="background__top-box__list-item">
                                <Draggable
                                    nodeRef={star_ref}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                    position={{ x: 0, y: 0 }}
                                    disabled={
                                        snorkleStage.subStages.find(
                                            (sub) =>
                                                sub.name ===
                                                SubStage_find_hidden.star
                                        )?.completed ||
                                        snorkleStage.audioIsPlaying
                                    }
                                >
                                    <img
                                        src={star_see}
                                        id={SubStage_find_hidden.star}
                                        ref={star_ref}
                                        className="background__top-box__list-item__image"
                                        alt=""
                                    />
                                </Draggable>
                                {snorkleStage.subStages.find(
                                    (sub) =>
                                        sub.name === SubStage_find_hidden.star
                                )?.completed && (
                                    <>
                                        {!snorkleStage.subStages.find(
                                            (sub) =>
                                                sub.name ===
                                                SubStage_find_hidden.star
                                        )?.intro && (
                                            <SnorkleAudio
                                                audioSrc={sjostjarna_audio}
                                                audioName={
                                                    SubStage_find_hidden.star
                                                }
                                            />
                                        )}
                                        <img
                                            src={check_svg}
                                            className="background__top-box__list-item__check-svg"
                                            alt=""
                                        />
                                    </>
                                )}
                            </div>
                            <div className="background__top-box__list-item">
                                <Draggable
                                    nodeRef={crab_ref}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                    position={{ x: 0, y: 0 }}
                                    disabled={
                                        snorkleStage.subStages.find(
                                            (sub) =>
                                                sub.name ===
                                                SubStage_find_hidden.crab
                                        )?.completed ||
                                        snorkleStage.audioIsPlaying
                                    }
                                >
                                    <img
                                        src={crab_img}
                                        id={SubStage_find_hidden.crab}
                                        ref={crab_ref}
                                        className="background__top-box__list-item__image"
                                        alt=""
                                    />
                                </Draggable>
                                {snorkleStage.subStages.find(
                                    (sub) =>
                                        sub.name === SubStage_find_hidden.crab
                                )?.completed && (
                                    <>
                                        {!snorkleStage.subStages.find(
                                            (sub) =>
                                                sub.name ===
                                                SubStage_find_hidden.crab
                                        )?.intro && (
                                            <SnorkleAudio
                                                audioSrc={krabba_audio}
                                                audioName={
                                                    SubStage_find_hidden.crab
                                                }
                                            />
                                        )}
                                        <img
                                            src={check_svg}
                                            className="background__top-box__list-item__check-svg"
                                            alt=""
                                        />
                                    </>
                                )}
                            </div>
                            <div className="background__top-box__list-item">
                                <Draggable
                                    nodeRef={mussels_ref}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                    position={{ x: 0, y: 0 }}
                                    disabled={
                                        snorkleStage.subStages.find(
                                            (sub) =>
                                                sub.name ===
                                                SubStage_find_hidden.mussels
                                        )?.completed ||
                                        snorkleStage.audioIsPlaying
                                    }
                                >
                                    <img
                                        src={mussels}
                                        id={SubStage_find_hidden.mussels}
                                        ref={mussels_ref}
                                        className="background__top-box__list-item__image"
                                        alt=""
                                    />
                                </Draggable>
                                {snorkleStage.subStages.find(
                                    (sub) =>
                                        sub.name ===
                                        SubStage_find_hidden.mussels
                                )?.completed && (
                                    <>
                                        {!snorkleStage.subStages.find(
                                            (sub) =>
                                                sub.name ===
                                                SubStage_find_hidden.mussels
                                        )?.intro && (
                                            <SnorkleAudio
                                                audioSrc={mussla_audio}
                                                audioName={
                                                    SubStage_find_hidden.mussels
                                                }
                                            />
                                        )}
                                        <img
                                            src={check_svg}
                                            className="background__top-box__list-item__check-svg"
                                            alt=""
                                        />
                                    </>
                                )}
                            </div>
                            <div className="background__top-box__list-item">
                                <Draggable
                                    nodeRef={piggvar_ref}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                    position={{ x: 0, y: 0 }}
                                    disabled={
                                        snorkleStage.subStages.find(
                                            (sub) =>
                                                sub.name ===
                                                SubStage_find_hidden.piggvar
                                        )?.completed ||
                                        snorkleStage.audioIsPlaying
                                    }
                                >
                                    <img
                                        src={piggvar}
                                        id={SubStage_find_hidden.piggvar}
                                        ref={piggvar_ref}
                                        className="background__top-box__list-item__image"
                                        alt=""
                                    />
                                </Draggable>
                                {snorkleStage.subStages.find(
                                    (sub) =>
                                        sub.name ===
                                        SubStage_find_hidden.piggvar
                                )?.completed && (
                                    <>
                                        {!snorkleStage.subStages.find(
                                            (sub) =>
                                                sub.name ===
                                                SubStage_find_hidden.piggvar
                                        )?.intro && (
                                            <SnorkleAudio
                                                audioSrc={flundra_audio}
                                                audioName={
                                                    SubStage_find_hidden.piggvar
                                                }
                                            />
                                        )}
                                        <img
                                            src={check_svg}
                                            className="background__top-box__list-item__check-svg"
                                            alt=""
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <img
                        className="ground background__ground-layer-5"
                        src={groud_layer_5}
                        alt=""
                    />
                    <img
                        className="ground background__ground-layer-4"
                        src={groud_layer_4}
                        alt=""
                    />
                    <img
                        className="ground background__ground-layer-3"
                        src={groud_layer_3}
                        alt=""
                    />
                    <img
                        className="ground background__ground-layer-2"
                        src={groud_layer_2}
                        alt=""
                    />
                    <img
                        className="ground background__ground-layer-1"
                        src={groud_layer_1}
                        alt=""
                    />
                    <img
                        className="ground background__ground-layer-1"
                        src={groud_layer_1}
                        alt=""
                    />
                    <img
                        className="ground background__plants"
                        src={plants}
                        alt=""
                    />
                    <img
                        className="ground background__sand-hill"
                        src={sand_hill}
                        alt=""
                    />
                </div>
                <div className="hidden-creatures">
                    {!snorkleStage.subStages.find(
                        (sub) => sub.name === SubStage_find_hidden.mussels
                    )?.completed ? (
                        <img
                            className="hidden-creatures__mussels"
                            onClick={() =>
                                handleSelectHidden(SubStage_find_hidden.mussels)
                            }
                            ref={mussels_ph_ref}
                            src={mussels}
                            alt=""
                        />
                    ) : (
                        <img
                            className="hidden-creatures__mussels apear"
                            src={mussels}
                            alt=""
                        />
                    )}
                    {!snorkleStage.subStages.find(
                        (sub) => sub.name === SubStage_find_hidden.star
                    )?.completed ? (
                        <img
                            className="hidden-creatures__star"
                            onClick={() =>
                                handleSelectHidden(SubStage_find_hidden.star)
                            }
                            ref={star_ph_ref}
                            src={star_see}
                            alt=""
                        />
                    ) : (
                        <img
                            className="hidden-creatures__star apear"
                            src={star_see}
                            alt=""
                        />
                    )}
                    {!snorkleStage.subStages.find(
                        (sub) => sub.name === SubStage_find_hidden.piggvar
                    )?.completed ? (
                        <img
                            className="hidden-creatures__hidden-piggvar"
                            onClick={() =>
                                handleSelectHidden(SubStage_find_hidden.piggvar)
                            }
                            ref={piggvar_ph_ref}
                            src={hidden_piggvar}
                            alt=""
                        />
                    ) : (
                        <img
                            className="hidden-creatures__hidden-piggvar apear"
                            src={piggvar}
                            alt=""
                        />
                    )}
                    {!snorkleStage.subStages.find(
                        (sub) => sub.name === SubStage_find_hidden.gadde
                    )?.completed ? (
                        <img
                            className="hidden-creatures__hidden-gedde"
                            onClick={() =>
                                handleSelectHidden(SubStage_find_hidden.gadde)
                            }
                            ref={gadde_ph_ref}
                            src={hidden_gedde}
                            alt=""
                        />
                    ) : (
                        <img
                            className="hidden-creatures__hidden-gedde"
                            src={gedde}
                            alt=""
                        />
                    )}
                    {!snorkleStage.subStages.find(
                        (sub) => sub.name === SubStage_find_hidden.crab
                    )?.completed ? (
                        <img
                            className="hidden-creatures__crab"
                            onClick={() =>
                                handleSelectHidden(SubStage_find_hidden.crab)
                            }
                            ref={crab_ph_ref}
                            src={crab_img}
                            alt=""
                        />
                    ) : (
                        <img
                            className="hidden-creatures__crab apear"
                            src={crab_img}
                            alt=""
                        />
                    )}
                    {!snorkleStage.subStages.find(
                        (sub) => sub.name === SubStage_find_hidden.jellyfish
                    )?.completed ? (
                        <img
                            className="hidden-creatures__hidden-jellyfish"
                            onClick={() =>
                                handleSelectHidden(
                                    SubStage_find_hidden.jellyfish
                                )
                            }
                            ref={jellyfish_ph_ref}
                            src={hidden_jellyfish}
                            alt=""
                        />
                    ) : (
                        <img
                            className="hidden-creatures__hidden-jellyfish"
                            src={jellyfish}
                            alt=""
                        />
                    )}
                </div>
                {snorkleStage.audioIsPlaying ? (
                    <>
                        <div className="description">
                            <h5>
                                <img
                                    src={getImageSrc(
                                        snorkleStage.subStages.find(
                                            (sub) => sub.completed && !sub.intro
                                        )!.name
                                    )}
                                    alt=""
                                />
                                {getTranslation(
                                    snorkleStage.subStages.find(
                                        (sub) => sub.completed && !sub.intro
                                    )!.name
                                )}
                            </h5>
                        </div>
                        <div className="scientist">
                            <Scientist
                                audioIsPlaying={snorkleStage.audioIsPlaying}
                            />
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
            <FooterSnorkle />
        </div>
    );
};
export default SnorklePage;
