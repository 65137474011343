import { useEffect, useRef, useState } from "react";
import submarine_img from "src/assets/images/adventure/submarine/submarine-base.svg";
import submarine_bg_video from "src/assets/videos/outside-window.mp4";
import check_svg from "src/assets/svg/check-green.svg";
import reflections from "src/assets/svg/reflections.svg";
import get_button_sv from "src/assets/animations/Button_GO_sv.json";
import get_button_en from "src/assets/animations/Button_GO_eng.json";
import Navbar from "src/compnents/navbar/Navbar";
import { translationService } from "src/services/translation.service";
import useVotoStore from "src/store";
import VideoPlayer from "src/hooks/video-player/Video-player";
import { findFilmById } from "src/utils/filmById";

import { ReactComponent as VideoFrame1 } from "src/assets/svg/video-frame-1.svg";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import { Locale } from "src/types/index.d";
import SubmarineIntro from "./Intro";

interface FilmsIntro {
    [key: string]: { title: string; description: string };
}

const Submarine = () => {
    const goButtonRef = useRef<LottieRefCurrentProps>(null);
    const {
        locale,
        submarine,
        filmDone,
        submarineUpdateIntro,
        basename,
        setFilms,
    } = useVotoStore();
    const goText = translationService.translate("shared.go", locale);
    const defaultWindowText = translationService.translate(
        "submarinePage.windowText",
        locale
    );

    const [filmId, setFilmId] = useState<string>("");
    const [showFilm, setShowFilm] = useState<boolean>(false);

    // useEffect(() => {
    //     if (basename) {
    //         setFilms(basename);
    //     }
    // }, [basename]);

    const filmsIntro: FilmsIntro = {
        "1": {
            title: translationService.translate(
                "submarinePage.films.title.1",
                locale
            ),
            description: translationService.translate(
                "submarinePage.films.description.1",
                locale
            ),
        },
        "2": {
            title: translationService.translate(
                "submarinePage.films.title.2",
                locale
            ),
            description: translationService.translate(
                "submarinePage.films.description.2",
                locale
            ),
        },
        "3": {
            title: translationService.translate(
                "submarinePage.films.title.3",
                locale
            ),
            description: translationService.translate(
                "submarinePage.films.description.3",
                locale
            ),
        },
        "4": {
            title: translationService.translate(
                "submarinePage.films.title.4",
                locale
            ),
            description: translationService.translate(
                "submarinePage.films.description.4",
                locale
            ),
        },
        "5": {
            title: translationService.translate(
                "submarinePage.films.title.5",
                locale
            ),
            description: translationService.translate(
                "submarinePage.films.description.5",
                locale
            ),
        },
        "6": {
            title: translationService.translate(
                "submarinePage.films.title.6",
                locale
            ),
            description: translationService.translate(
                "submarinePage.films.description.6",
                locale
            ),
        },
    };

    const intro_first_text = translationService.translate(
        "submarinePage.intro.first",
        locale
    );
    const intro_second_text = translationService.translate(
        "submarinePage.intro.second",
        locale
    );

    const onCloseFilm = () => {
        setShowFilm(false);
        setFilmId("");
    };

    const onSelectFilm = () => {
        setShowFilm(true);
        goButtonRef.current?.animationItem?.goToAndStop(0);

        setTimeout(() => {
            filmDone(filmId);
        }, 2000);
    };

    return (
        <>
            {showFilm && filmId && (
                <VideoPlayer
                    url={
                        submarine.stages.find((stage) => stage.id === filmId)!
                            .url
                    }
                    onCloseClick={onCloseFilm}
                    autoplay={true}
                    onEndedVideo={onCloseFilm}
                    showProgressBar={true}
                />
            )}
            <div
                className={`voto-submarine c-voto-page ${
                    showFilm && filmId && "show-film"
                }`}
            >
                <Navbar showHome={true} />
                {filmId ? (
                    <img
                        className="background background--image"
                        src={
                            submarine.stages.find(
                                (stage) => stage.id === filmId
                            )!.image
                        }
                        alt="submarine"
                    />
                ) : (
                    <VideoPlayer
                        url={submarine_bg_video}
                        autoplay={true}
                        loop={true}
                        muted={!submarine.intro ? true : false}
                    />
                )}
                <img
                    className="background"
                    src={submarine_img}
                    alt="submarine"
                />
                {!filmId && (
                    <div className="submarine-window">
                        {submarine.intro ? (
                            <>
                                <h2 className="start-title">
                                    {defaultWindowText}
                                </h2>
                                <h2 className="start-text">{goText}</h2>
                            </>
                        ) : (
                            <>
                                <SubmarineIntro />
                                <h5 className="intro-text">
                                    {intro_first_text}
                                </h5>
                                <h5
                                    className="intro-text"
                                    style={{ paddingTop: ".5rem" }}
                                >
                                    {intro_second_text}
                                </h5>
                            </>
                        )}
                    </div>
                )}
                {!showFilm && filmId && (
                    <div className="submarine-window">
                        <h2 className="start-title">
                            {locale === Locale.en
                                ? submarine.stages.find(
                                      (stage) => stage.id === filmId
                                  )!.title.en
                                : submarine.stages.find(
                                      (stage) => stage.id === filmId
                                  )!.title.se}
                        </h2>
                    </div>
                )}
                <div className="film__container">
                    {submarine?.stages.map(
                        (stage) =>
                            stage.url && (
                                <div
                                    onClick={() => {
                                        if (!submarine.intro) {
                                            submarineUpdateIntro(true);
                                        }
                                        goButtonRef.current?.animationItem?.goToAndPlay(
                                            0
                                        );

                                        setFilmId(stage.id);
                                    }}
                                    className={`film__item film-${
                                        stage.id
                                    }-image clickable ${
                                        filmId === stage.id ? "is-active" : ""
                                    }`}
                                    key={stage.id}
                                >
                                    <VideoFrame1 />
                                    {/* <img
                                className={`film__item-reflections`}
                                src={reflections}
                                alt=""
                            /> */}
                                    <img
                                        className={`film__item-image film-${stage.id}-image clickable`}
                                        src={stage.image}
                                        title={stage.title.en}
                                        alt=""
                                    />
                                    {stage.completed && (
                                        <img
                                            className={`film__item-check film-${stage.id}-image check-svg`}
                                            src={check_svg}
                                            alt=""
                                        />
                                    )}
                                </div>
                            )
                    )}
                    {/* {submarine?.stages.map((stage) => (
                        <div
                            onClick={() => {
                                if (!submarine.intro) {
                                    submarineUpdateIntro(true);
                                }
                                goButtonRef.current?.animationItem?.goToAndPlay(
                                    0
                                );

                                setFilmId(stage.id);
                            }}
                            className={`film__item film-${
                                stage.id
                            }-image clickable ${
                                filmId === stage.id ? "is-active" : ""
                            }`}
                            key={stage.id}
                        >
                            <VideoFrame1 />
                            {/* <img
                                className={`film__item-reflections`}
                                src={reflections}
                                alt=""
                            /> 
                            <img
                                className={`film__item-image film-${stage.id}-image clickable`}
                                src={require(`src/assets/images/adventure/submarine/film-${stage.id}.jpg`)}
                                alt=""
                            />
                            {stage.completed && (
                                <img
                                    className={`film__item-check film-${stage.id}-image check-svg`}
                                    src={check_svg}
                                    alt=""
                                />
                            )}
                        </div>
                    ))} */}
                </div>
                <div
                    onClick={() => filmId && onSelectFilm()}
                    className="go-button clickable"
                >
                    <Lottie
                        lottieRef={goButtonRef}
                        className="go-button__lottie"
                        animationData={
                            locale === Locale.sv ? get_button_sv : get_button_en
                        }
                        loop={true}
                        autoplay={false}
                    />
                </div>
            </div>
        </>
    );
};
export default Submarine;
