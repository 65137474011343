import { ReactComponent as HistoryIcon } from "src/assets/svg/quiz/history-icon.svg";
import { ReactComponent as ArtIcon } from "src/assets/svg/quiz/art-icon.svg";
import { ReactComponent as ScientistIcon } from "src/assets/svg/quiz/scientist-icon.svg";
import { ReactComponent as SportIcon } from "src/assets/svg/quiz/sport-icon.svg";
import { ReactComponent as MarineIcon } from "src/assets/svg/quiz/marine-icon.svg";
import { QuizAreaName } from "src/types/index.d";

const AreaIcon = ({ area }: { area: QuizAreaName }) => {
    switch (area) {
        case QuizAreaName.history:
            return <HistoryIcon className="section-icon" />;
        case QuizAreaName.art:
            return <ArtIcon className="section-icon" />;
        case QuizAreaName.marine:
            return <MarineIcon className="section-icon" />;
        case QuizAreaName.scientist:
            return <ScientistIcon className="section-icon" />;
        case QuizAreaName.sport:
            return <SportIcon className="section-icon" />;
        default:
            return <></>;
    }
};
export default AreaIcon;
