import { QuizArea, QuizAreaName } from "src/types/index.d";

export const quizAreas: QuizArea[] = [
    {
        name: QuizAreaName.marine,
        questions: [
            {
                question: {
                    sv: "Ålen kan bli väldigt gammal. Den äldsta ålen levde i Brantevik i Skåne, hur gammal blev den?",
                    en: "Eels can become very old. The oldest eel lived in Brantevik in Skåne, how old was it?",
                },
                answers: [
                    { sv: "95 år", en: "95 years" },
                    { sv: "127 år", en: "127 years" },
                    { sv: "över 150 år", en: "over 150 years" },
                ],
                rightAnswer: { sv: "över 150 år", en: "over 150 years" },
                done: false,
            },
            {
                question: {
                    sv: "Finns det koraller i Sverige?",
                    en: "Are there any corals in Sweden?",
                },
                answers: [
                    { sv: "Ja, men bara fossila", en: "Yes, but only fossils" },
                    {
                        sv: "Nej, de trivs inte i bräckt vatten",
                        en: "No, they do not thrive in brackish water",
                    },
                    { sv: "Ja, på västkusten", en: "Yes, on the west coast" },
                ],
                rightAnswer: {
                    sv: "Ja, på västkusten",
                    en: "Yes, on the west coast",
                },
                done: false,
            },
            {
                question: {
                    sv: "Vad kallas sälens unge?",
                    en: "What is the seal pup called?",
                },
                answers: [
                    { sv: "Kid", en: "Seal kid" },
                    { sv: "Kut", en: "Seal pup" },
                    { sv: "Kalv", en: "Seal calf" },
                ],
                rightAnswer: { sv: "Kut", en: "Seal pup" },
                done: false,
            },
            {
                question: {
                    sv: "Hur mycket vatten kan en enda mussla rena?",
                    en: "How much water can a single mussel clean?",
                },
                answers: [
                    { sv: "9 ml per timme", en: "9 ml per hour" },
                    { sv: "9 dl per timme", en: "9 dl per hour" },
                    { sv: "9 liter per timme", en: "9 liters per hour" },
                ],
                rightAnswer: {
                    sv: "9 liter per timme",
                    en: "9 liters per hour",
                },
                done: false,
            },
            {
                question: {
                    sv: "Ungefär hur mycket plast uppskattar man att det hamnar i världens hav varje dag?",
                    en: "Approximately how much plastic is estimated to enter the world's oceans every day?",
                },
                answers: [
                    {
                        sv: "Ett lastbilslass varje minut (mellan 7-15 miljoner ton)",
                        en: "One truckload every minute (between 7-15 million tonnes)",
                    },
                    { sv: "100 ton", en: "100 tonnes" },
                    { sv: "3 miljoner ton", en: "3 million tonnes" },
                ],
                rightAnswer: {
                    sv: "Ett lastbilslass varje minut (mellan 7-15 miljoner ton)",
                    en: "One truckload every minute (between 7-15 million tonnes)",
                },
                done: false,
            },
            {
                question: {
                    sv: "Var ligger världens största korallrev?",
                    en: "Where is the world's largest coral reef?",
                },
                answers: [
                    {
                        sv: "På Västkusten, Sverige",
                        en: "On the Swedish West Coast",
                    },
                    { sv: "Utanför Australien", en: "Outside Australia" },
                    { sv: "Galapagosöarna", en: "Galapagos Islands" },
                ],
                rightAnswer: {
                    sv: "Utanför Australien",
                    en: "Outside Australia",
                },
                done: false,
            },
            {
                question: {
                    sv: "Vad är en tumlare för sorts djur?",
                    en: "What kind of animal is a porpoise?",
                },
                answers: [
                    { sv: "Fisk", en: "Fish" },
                    { sv: "Delfin", en: "Dolphin" },
                    { sv: "Val", en: "Whale" },
                ],
                rightAnswer: { sv: "Val", en: "Whale" },
                done: false,
            },
            {
                question: {
                    sv: "Vad är det största hotet mot världshaven?",
                    en: "What is the biggest threat to the world's oceans?",
                },
                answers: [
                    { sv: "Överfiske", en: "Over fishing" },
                    { sv: "Övergödning", en: "Eutrophication" },
                    {
                        sv: "Utsläpp från båttrafiken",
                        en: "Emissions from boat traffic",
                    },
                ],
                rightAnswer: { sv: "Överfiske", en: "Over fishing" },
                done: false,
            },
            {
                question: {
                    sv: "Var bor det pingviner?",
                    en: "Where do penguins live?",
                },
                answers: [
                    { sv: "På Antarktis", en: "In Antarctica" },
                    { sv: "På Arktis", en: "In the Arctic" },
                    {
                        sv: "På Island och Grönland",
                        en: "In Iceland and Greenland",
                    },
                ],
                rightAnswer: { sv: "På Antarktis", en: "In Antarctica" },
                done: false,
            },
            {
                question: {
                    sv: "Blåstången förökar sig när…",
                    en: "The bladderwrack reproduces when...",
                },
                answers: [
                    {
                        sv: "När det är exakt 14 grader i vattnet",
                        en: "When the water is exactly 14 degrees",
                    },
                    {
                        sv: "I maj och juni vid fullmåne",
                        en: "In May and June during the full moon",
                    },
                    { sv: "När det regnar", en: "When it rains" },
                ],
                rightAnswer: {
                    sv: "I maj och juni vid fullmåne",
                    en: "In May and June during the full moon",
                },
                done: false,
            },
        ]
    },
    {
        name: QuizAreaName.scientist,
        questions: [
            {
                question: {
                    sv: "Ungefär hur många procent av allt syre produceras av havet?",
                    en: "What percentage of all oxygen is produced by the ocean?",
                },
                answers: [
                    { sv: "25%", en: "25%" },
                    { sv: "50%", en: "50%" },
                    { sv: "65%", en: "65%" },
                ],
                rightAnswer: { sv: "50%", en: "50%" },
                done: false,
            },
            {
                question: {
                    sv: "Ungefär hur många procent av koldioxiden i luften fångas upp av havet?",
                    en: "Approximately what percentage of carbon dioxide in the air is captured by the ocean?",
                },
                answers: [
                    { sv: "10%", en: "10%" },
                    { sv: "20%", en: "20%" },
                    { sv: "30%", en: "30%" },
                ],
                rightAnswer: { sv: "30%", en: "30%" },
                done: false,
            },
            {
                question: {
                    sv: "Hur stor del av jordens yta täcks av hav?",
                    en: "How much of the Earth's surface is covered by oceans?",
                },
                answers: [
                    { sv: "70%", en: "70%" },
                    { sv: "50%", en: "50%" },
                    { sv: "82%", en: "82%" },
                ],
                rightAnswer: { sv: "70%", en: "70%" },
                done: false,
            },
            {
                question: {
                    sv: "Vad heter världens största hav?",
                    en: "What is the name of the world's largest ocean?",
                },
                answers: [
                    { sv: "Medelhavet", en: "Mediterranean Sea" },
                    { sv: "Kaspiska havet", en: "Caspian Sea" },
                    { sv: "Stilla havet", en: "Pacific Ocean" },
                ],
                rightAnswer: { sv: "Stilla havet", en: "Pacific Ocean" },
                done: false,
            },
            {
                question: {
                    sv: "Vad kallas en undervattensvulkan som sprutar varmt vatten och mineraler?",
                    en: "What is an underwater volcano that spews hot water and minerals called?",
                },
                answers: [
                    { sv: "Havskälla", en: "Ocean source" },
                    { sv: "Hydrotermal ventil", en: "Hydrothermal vent" },
                    { sv: "Exoterm vulkan", en: "Exothermic volcano" },
                ],
                rightAnswer: {
                    sv: "Hydrotermal ventil",
                    en: "Hydrothermal vent",
                },
                done: false,
            },
            {
                question: {
                    sv: "Vilken är den vanligaste källan till plastförorening i haven?",
                    en: "What is the most common source of plastic pollution in the oceans?",
                },
                answers: [
                    {
                        sv: "Engångsartiklar i plast",
                        en: "Disposable plastic items",
                    },
                    {
                        sv: "Övergiven fiskeutrustning",
                        en: "Abandoned fishing gear",
                    },
                    { sv: "Industriavfall", en: "Industrial waste" },
                ],
                rightAnswer: {
                    sv: "Engångsartiklar i plast",
                    en: "Disposable plastic items",
                },
                done: false,
            },
            {
                question: {
                    sv: "Hur uppstår tidvatten?",
                    en: "How does tides occur?",
                },
                answers: [
                    {
                        sv: "Genom solens och månens dragningskraft på jorden",
                        en: "By the gravitational pull of the sun and moon on the Earth",
                    },
                    {
                        sv: "Tidvatten styrs av undervattensströmmar",
                        en: "Tides are controlled by underwater currents",
                    },
                    {
                        sv: "Det är resultatet av vindens påverkan på vattnet",
                        en: "It is the result of the action of wind and water.",
                    },
                ],
                rightAnswer: {
                    sv: "Genom solens och månens dragningskraft på jorden",
                    en: "By the gravitational pull of the sun and moon on the Earth",
                },
                done: false,
            },
            {
                question: {
                    sv: "I världshaven finns det ca 35 gram salt per kilo vatten. Hur många gram per kilo vatten finns det i Östersjön?",
                    en: "The world's oceans have an average salinity of about 35 grams of salt per kilogram of water. Compared to this, the Baltic Sea has an average salinity of approximately:",
                },
                answers: [
                    { sv: "3 gram", en: "3 grams" },
                    { sv: "7 gram", en: "7 grams" },
                    {
                        sv: "35 grams per kilogram (same salinity)",
                        en: "35 grams per kilogram (same salinity)",
                    },
                ],
                rightAnswer: { sv: "7 gram", en: "7 grams" },
                done: false,
            },
            {
                question: {
                    sv: "Vilka två grundämnen förknippas främst med övergödning?",
                    en: "Which two chemical elements are primarily associated with eutrophication?",
                },
                answers: [
                    { sv: "Kväve och Fosfor", en: "Nitrogen and Phosphorus" },
                    {
                        sv: "Koldioxid och Fosfor",
                        en: "Carbon dioxide and Phosphorus",
                    },
                    { sv: "PCB och dioxiner", en: "PCBs and dioxins" },
                ],
                rightAnswer: {
                    sv: "Kväve och Fosfor",
                    en: "Nitrogen and Phosphorus",
                },
                done: false,
            },
            {
                question: {
                    sv: "Om all havsis på Antarktis smälter, hur högt skulle havsnivån kunna stiga?",
                    en: "If all the sea ice in Antarctica were to melt, how much would the sea level rise?",
                },
                answers: [
                    { sv: "5 meter", en: "5 meters" },
                    { sv: "50 meter", en: "50 meters" },
                    { sv: "15 meter", en: "15 meters" },
                ],
                rightAnswer: { sv: "50 meter", en: "50 meters" },
                done: false,
            },
        ]
    },
    {
        name: QuizAreaName.art,
        questions: [
            {
                question: {
                    sv: "Det finns en chans att du hittar bärnsten längs Sveriges kust. Vad kan du hitta i bärnsten?",
                    en: "There is a chance you find amber during the Swedish coast. What can you find in the amber?",
                },
                answers: [
                    { sv: "Utdöda insekter", en: "Extinct insects" },
                    { sv: "Guld", en: "Gold" },
                    { sv: "Ben av dinosaurier", en: "Bones of dinosaurs" },
                ],
                rightAnswer: { sv: "Utdöda insekter", en: "Extinct insects" },
                done: false,
            },
            {
                question: {
                    sv: "Vilken fisk är känd för sin rosa färg och används ofta i maträtten Sushi?",
                    en: "Which fish is known for its pink color and is often used in the Japanese dish Sushi?",
                },
                answers: [
                    { sv: "Torsk", en: "Cod" },
                    { sv: "Svärdfisk", en: "Swordfish" },
                    { sv: "Lax", en: "Salmon" },
                ],
                rightAnswer: { sv: "Lax", en: "Salmon" },
                done: false,
            },
            {
                question: {
                    sv: "Vilken film handlar om en clownfisk som tappar bort sin son?",
                    en: "What movie is about a clownfish who is losing his son?",
                },
                answers: [
                    { sv: "Hjälp jag är en fisk", en: "Help! I'm a fish" },
                    { sv: "Hitta Nemo", en: "Finding Nemo" },
                    { sv: "Den lilla krabban", en: "Little Crab" },
                ],
                rightAnswer: { sv: "Hitta Nemo", en: "Finding Nemo" },
                done: false,
            },
            {
                question: {
                    sv: "Vilken klassisk opera baserad på H.C. Andersens saga handlar om en person som offrar sin röst för att bli människa?",
                    en: "Which classic opera based on H.C. Andersen's fairy tale is about a person who sacrifices their voice to become human?",
                },
                answers: [
                    { sv: "Den lilla sjöjungfrun", en: "Little Mermaid" },
                    { sv: "La Traviata", en: "La Traviata" },
                    { sv: "Prinsessan i havet", en: "Princess in the Ocean" },
                ],
                rightAnswer: {
                    sv: "Den lilla sjöjungfrun",
                    en: "Little Mermaid",
                },
                done: false,
            },
            {
                question: {
                    sv: "Vem är havsguden i den grekiska mytologin?",
                    en: "Who is the sea god in Greek mythology?",
                },
                answers: [
                    { sv: "Heros", en: "Heros" },
                    { sv: "Poseidon", en: "Poseidon" },
                    { sv: "Moses", en: "Moses" },
                ],
                rightAnswer: { sv: "Poseidon", en: "Poseidon" },
                done: false,
            },
            {
                question: {
                    sv: "Vilken klassisk saga handlar om en pojke som blir vän med en talande val?",
                    en: "What classic fairy tale is about a boy who becomes friend with a speaking whale?",
                },
                answers: [
                    { sv: "Pinocchio", en: "Pinocchio" },
                    { sv: "Nils Karlsson Pyssling", en: "Christopher's Dwarf" },
                    { sv: "Moby Dick", en: "Moby Dick" },
                ],
                rightAnswer: { sv: "Moby Dick", en: "Moby Dick" },
                done: false,
            },
            {
                question: {
                    sv: "Vilken musikstil utvecklades på Jamaica, känd för rytmer som liknar ljudet av vågor och koppling till havet och ö-livet?",
                    en: "Which music style originated in Jamaica, known for rhythms resembling the sound of waves and having connections to the sea and island life?",
                },
                answers: [
                    { sv: "SKA", en: "SKA" },
                    { sv: "Punk", en: "Punk" },
                    { sv: "Reggae", en: "Reggae" },
                ],
                rightAnswer: { sv: "Reggae", en: "Reggae" },
                done: false,
            },
            {
                question: {
                    sv: "Vad heter Sveriges enda marina nationalpark?",
                    en: "There is only one marine nationalpark in Sweden, what's it's name?",
                },
                answers: [
                    { sv: "Stuor Muorkke", en: "Stuor Muorkke" },
                    { sv: "Kosterhavet", en: "Koster Sea" },
                    { sv: "Kattegatt", en: "The Kattegat" },
                ],
                rightAnswer: { sv: "Kosterhavet", en: "Koster Sea" },
                done: false,
            },
            {
                question: {
                    sv: "Bajau-folket i Malaysia har levt i direkt anslutning till havet i tusentals år. De har till och med utvecklat en unik förmåga. Vad?",
                    en: "The Bajau people in Malaysia have lived in close proximity to the sea for thousands of years. They have even developed a unique ability. What?",
                },
                answers: [
                    {
                        sv: "Dyka utan utrustning upp till 70 m",
                        en: "Dive without equipment up to 70 m",
                    },
                    {
                        sv: "Fånga fisk med bara händerna",
                        en: "Catch fish with their bare hands",
                    },
                    {
                        sv: "Kan simma upp till 24 km/h",
                        en: "Swim speed up to 24 km/h",
                    },
                ],
                rightAnswer: {
                    sv: "Dyka utan utrustning upp till 70 m",
                    en: "Dive without equipment up to 70 m",
                },
                done: false,
            },
            {
                question: {
                    sv: "Havet var en ständigt återkommande inspirationskälla inom kulturlivet. Evert Taube och hans visor ger en unik inblick i sjömännens liv och kultur. Vilken av dessa har han skrivit?",
                    en: "The sea was a constantly recurring source of inspiration in cultural life. Evert Taube, and his folk songs provide a unique insight into the lives and culture of sailors. Which of these has he written?",
                },
                answers: [
                    {
                        sv: "Så skimrande var aldrig havet",
                        en: "Så skimrande var aldrig havet",
                    },
                    { sv: "En sjömansvisa", en: "A sailor's song" },
                    { sv: "Västkustvisan", en: "The West Coast Song" },
                ],
                rightAnswer: {
                    sv: "Så skimrande var aldrig havet",
                    en: "Så skimrande var aldrig havet",
                },
                done: false,
            },
        ]
    },
    {
        name: QuizAreaName.history,
        questions: [
            {
                question: {
                    sv: "Vad händer om du gnuggar en bärnsten mot exempelvis håret eller handen?",
                    en: "What happens if you rub amber against, for example your hair or hand?",
                },
                answers: [
                    {
                        sv: "Du kan få syn på fossila insekter inuti bärnstenen",
                        en: "You may spot fossilized insects inside the amber",
                    },
                    {
                        sv: "Den blir varm och kan antända",
                        en: "It becomes warm and can ignite",
                    },
                    {
                        sv: "Det bildas statisk elektricitet",
                        en: "Static electricity is generated",
                    },
                ],
                rightAnswer: {
                    sv: "Det bildas statisk elektricitet",
                    en: "Static electricity is generated",
                },
                done: false,
            },
            {
                question: {
                    sv: "Vid Kaspiska havet finns hällristningar föreställande båtar. Hur gamla tror man att de är?",
                    en: "At the Caspian Sea, there are petroglyphs depicting boats. How old are they?",
                },
                answers: [
                    { sv: "500 år", en: "500 years" },
                    { sv: "2000 år", en: "2,000 years" },
                    { sv: "5000 år", en: "5,000 years" },
                ],
                rightAnswer: { sv: "5000 år", en: "5,000 years" },
                done: false,
            },
            {
                question: {
                    sv: "Var låg Gotland för 430 miljoner år sedan?",
                    en: "Where was Gotland located 430 million years ago?",
                },
                answers: [
                    { sv: "Vid Arktis", en: "Near the Arctic" },
                    {
                        sv: "På samma plats där det ligger idag",
                        en: "In the same location where it is today",
                    },
                    { sv: "Vid ekvatorn", en: "Near the equator" },
                ],
                rightAnswer: { sv: "Vid ekvatorn", en: "Near the equator" },
                done: false,
            },
            {
                question: {
                    sv: "Tyska Hansan skulle på medeltiden underlätta handeln runt Östersjön. Vilken stad i Sverige tillhörde Hansan?",
                    en: "In the Middle Ages, the German Hansa was supposed to facilitate trade around the Baltic Sea. Which city in Sweden belonged to the Hanseatic League?",
                },
                answers: [
                    { sv: "Visby", en: "Visby" },
                    { sv: "Umeå", en: "Umeå" },
                    { sv: "Göteborg", en: "Gothenburg" },
                ],
                rightAnswer: { sv: "Visby", en: "Visby" },
                done: false,
            },
            {
                question: {
                    sv: "När sjönk det kända fartyget Titanic?",
                    en: "When did the famous ship Titanic sink?",
                },
                answers: [
                    { sv: "1912", en: "1912" },
                    { sv: "1905", en: "1905" },
                    { sv: "1921", en: "1921" },
                ],
                rightAnswer: { sv: "1912", en: "1912" },
                done: false,
            },
            {
                question: {
                    sv: "Blåstången har många användningsområden. Vad använde de gamla grekerna den till?",
                    en: "Bladderwrack has many uses. What did the ancient Greeks use it for?",
                },
                answers: [
                    {
                        sv: "Som rep när de byggde båtar",
                        en: "As rope when they built boats",
                    },
                    { sv: "Medicin", en: "Medicine" },
                    { sv: "Hudkräm", en: "Skin lotion" },
                ],
                rightAnswer: { sv: "Medicin", en: "Medicine" },
                done: false,
            },
            {
                question: {
                    sv: "Trävrak bevaras exceptionellt bra i Östersjön eftersom en viss mask som äter trä inte trivs i det bräckta vattnet. Vilken mask är det?",
                    en: "Ship wrecks is exceptionally well-preserved in the Baltic Sea because a certain worm that eats wood doesn't thrive in the brackish water. Which worm is it?",
                },
                answers: [
                    { sv: "Skeppsmask", en: "Shipworm" },
                    { sv: "Bandmask", en: "Tapeworm" },
                    { sv: "Vattenmask", en: "Waterworm" },
                ],
                rightAnswer: { sv: "Skeppsmask", en: "Shipworm" },
                done: false,
            },
            {
                question: {
                    sv: "Vilket kungligt skepp förliste 1628 i Stockholm och går nu att se på museum?",
                    en: "Which royal ship sank in 1628 in Stockholm and can now be seen in a museum?",
                },
                answers: [
                    { sv: "Kronan", en: "Kronan" },
                    { sv: "Vasa", en: "Vasa" },
                    { sv: "Gustaf ll", en: "Gustaf II" },
                ],
                rightAnswer: { sv: "Vasa", en: "Vasa" },
                done: false,
            },
            {
                question: {
                    sv: "Christopher Columbus seglade 1492 från Spanien över Atlanten. Han skulle till Indien, men var hamnade han?",
                    en: "Christopher Columbus sailed from Spain across the Atlantic in 1492. He was aiming for India, but where did he end up?",
                },
                answers: [
                    { sv: "Stockholm", en: "Stockholm" },
                    { sv: "Sydamerika", en: "South America" },
                    { sv: "Västindiska öarna, Amerika", en: "North America" },
                ],
                rightAnswer: {
                    sv: "Västindiska öarna, Amerika",
                    en: "North America",
                },
                done: false,
            },
            {
                question: {
                    sv: "Hur många vrak från skepp och mindre båtar känner vi till i Östersjön?",
                    en: "How many wrecks from ships and smaller boats do we know of in the Baltic Sea?",
                },
                answers: [
                    { sv: "1000", en: "1000" },
                    { sv: "520", en: "520" },
                    { sv: "17000", en: "17000" },
                ],
                rightAnswer: { sv: "17000", en: "17000" },
                done: false,
            },
        ]
    },
    {
        name: QuizAreaName.sport,
        questions: [
            {
                question: {
                    sv: "Hur snabbt har någon seglat runt jorden?",
                    en: "How quickly has someone sailed around the world?",
                },
                answers: [
                    { sv: "240 dagar", en: "240 days" },
                    { sv: "365 dagar", en: "365 days" },
                    { sv: "40 dagar", en: "40 days" },
                ],
                rightAnswer: { sv: "40 dagar", en: "40 days" },
                done: false,
            },
            {
                question: {
                    sv: "Vad är det officiella hastighetsrekordet i vindsurfing?",
                    en: "What is the official speed record in wind surfing?",
                },
                answers: [
                    { sv: "46 km/h", en: "25 knots (ca. 46 km/h)" },
                    { sv: "103,67 km/h", en: "55,93 knots (ca. 103,67 km/h)" },
                    { sv: "120 km/h", en: "65 knots (ca 120 km/h)" },
                ],
                rightAnswer: {
                    sv: "103,67 km/h",
                    en: "55,93 knots (ca. 103,67 km/h)",
                },
                done: false,
            },
            {
                question: {
                    sv: "Vilken sport utförs med en bräda och en drake som används för att dra brädan över vattnet?",
                    en: "Which sport is performed with a board and a kite used to pull the board across the water?",
                },
                answers: [
                    { sv: "Vindsurfing", en: "Windsurfing" },
                    { sv: "Kitesurfing", en: "Kitesurfing" },
                    { sv: "Segling", en: "Sailing" },
                ],
                rightAnswer: { sv: "Kitesurfing", en: "Kitesurfing" },
                done: false,
            },
            {
                question: {
                    sv: "Vilken typ av dykning utförs utan några tekniska hjälpmedel?",
                    en: "What type of diving is done without any technical aids?",
                },
                answers: [
                    { sv: "Teknisk dykning", en: "Technical diving" },
                    { sv: "Fridykning", en: "Freediving" },
                    { sv: "Scubadykning", en: "Scuba diving" },
                ],
                rightAnswer: { sv: "Fridykning", en: "Freediving" },
                done: false,
            },
            {
                question: {
                    sv: "Vad kallas den typ av fiske där man tävlar i att fånga den längsta eller tyngsta fisken?",
                    en: "What is the name of the type of fishing where you compete to catch the longest or heaviest fish?",
                },
                answers: [
                    { sv: "Sportfiske", en: "Sport fishing" },
                    { sv: "Trollingfiske", en: "Trolling fishing" },
                    { sv: "Fiskefestival", en: "Fishing festival" },
                ],
                rightAnswer: { sv: "Sportfiske", en: "Sport fishing" },
                done: false,
            },
            {
                question: {
                    sv: "Vad gör man när man pimplar?",
                    en: "What do you do when you ice fish?",
                },
                answers: [
                    { sv: "Fiske på isen", en: "Fishing on the ice" },
                    { sv: "Dyker under is", en: "Diving under the ice" },
                    { sv: "Fiskar från kanot", en: "Fishing from a canoe" },
                ],
                rightAnswer: { sv: "Fiske på isen", en: "Fishing on the ice" },
                done: false,
            },
            {
                question: {
                    sv: "1995 i Nordsjön uppmättes den högsta vågen, den döptes till Draupner. Hur hög var den?",
                    en: "In 1995, the highest wave was measured in the North Sea and named Draupner. How high was it?",
                },
                answers: [
                    { sv: "5 m", en: "5 m" },
                    { sv: "75 m", en: "75 m" },
                    { sv: "25 m", en: "25 m" },
                ],
                rightAnswer: { sv: "25 m", en: "25 m" },
                done: false,
            },
            {
                question: {
                    sv: "När blev det förbjudet i Sverige för fritidsbåtar att hälla ut toalettavfall i vattnet?",
                    en: "When did Sweden ban recreational boats from dumping toilet waste in the water?",
                },
                answers: [
                    { sv: "1896", en: "1896" },
                    { sv: "2015", en: "2015" },
                    { sv: "1996", en: "1996" },
                ],
                rightAnswer: { sv: "2015", en: "2015" },
                done: false,
            },
            {
                question: {
                    sv: "Vilken är världens största hamn?",
                    en: "Which is the world's largest port?",
                },
                answers: [
                    { sv: "Hamburg, Tyskland", en: "Hamburg, Germany" },
                    { sv: "Jebel Ali, Dubai", en: "Jebel Ali, Dubai" },
                    { sv: "Shanghai, Kina", en: "Shanghai, China" },
                ],
                rightAnswer: { sv: "Shanghai, Kina", en: "Shanghai, China" },
                done: false,
            },
            {
                question: {
                    sv: "Vad är världsrekordet i att hålla andan under vatten (statisk apné)?",
                    en: "What is the world record for holding your breath underwater (static apnea)?",
                },
                answers: [
                    {
                        sv: "5 minuter och 34 sekunder",
                        en: "5 minutes and 34 seconds",
                    },
                    {
                        sv: "11 minuter och 54 sekunder",
                        en: "11 minutes and 54 seconds",
                    },
                    {
                        sv: "9 minuter och 44 sekunder",
                        en: "9 minutes and 44 seconds",
                    },
                ],
                rightAnswer: {
                    sv: "11 minuter och 54 sekunder",
                    en: "11 minutes and 54 seconds",
                },
                done: false,
            },
        ]
    },
];
