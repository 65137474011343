import useVotoStore from "src/store";
import "./profiles.scss";
import { translationService } from "src/services/translation.service";
import { AvatarName } from "src/types/index.d";
import { getTranslatedName } from "src/utils/helpers";

const Profiles = () => {
    const {
        quiz: { playerCount, players }, locale
    } = useVotoStore();

    return (
        <div className="voto-profiles c-voto-page">
            <div className="voto-profiles__1">
                <span className="voto-profiles__1-icon">
                    {!!players[0]!.avatar ? (
                        <img
                            src={require(`src/assets/svg/quiz/avatars/${
                                players[0]!.avatar
                            }.svg`)}
                            alt=""
                        />
                    ) : (
                        ""
                    )}
                </span>
                <h4 className="blue-text voto-profiles__1-name">
                    {getTranslatedName(players[0].name, locale)}
                </h4>
            </div>
            {playerCount === 2 && (
                <div className="voto-profiles__2">
                    <span className="voto-profiles__2-icon">
                        <img
                            src={require(`src/assets/svg/quiz/avatars/${
                                players[1]!.avatar
                            }.svg`)}
                            alt=""
                        />
                    </span>
                    <h4 className="blue-text voto-profiles__2-name">
                        {getTranslatedName(players[1].name, locale)}
                    </h4>
                </div>
            )}
        </div>
    );
};
export default Profiles;
