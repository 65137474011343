import { Link } from "react-router-dom";
import { ReactComponent as BackBackground } from "src/assets/svg/buttons-blue/back-bg.svg";
import { ReactComponent as BackArrow } from "src/assets/svg/buttons-blue/arrow-left-white.svg";
import { ReactComponent as NextBg } from "src/assets/svg/buttons-blue/next-bg.svg";
import { ReactComponent as TryAgainBg } from "src/assets/svg/buttons-blue/try-again-bg.svg";
import { ReactComponent as TryAgainIcon } from "src/assets/svg/buttons-blue/tray-again-white.svg";
import { ReactComponent as ArrowRight } from "src/assets/svg/buttons-blue/arrow-right-white.svg";

export const BackButton = ({
    onclick,
    text,
    link,
    customClass,
}: {
    onclick?: () => void;
    text: string;
    link?: string;
    customClass?: string;
}) => {
    return link ? (
        <Link
            to={link}
            className={`c-button button-background button-blue-small button--medium pulsing ${customClass}`}
        >
            <BackBackground className="button-bg" />
            <BackArrow className="button-icon" />
            {text}
        </Link>
    ) : (
        <button
            onClick={onclick}
            className={`c-button button-background button-blue-small button--medium pulsing ${customClass}`}
        >
            <BackBackground className="button-bg" />
            <BackArrow className="button-icon" />
            {text}
        </button>
    );
};

export const NextButton = ({
    onclick,
    text,
    customClass,
    link,
}: {
    onclick?: () => void;
    text: string;
    customClass?: string;
    link?: string;
}) => {
    return link ? (
        <Link
            to={link}
            className={`c-button button-background button--medium button-blue-small pulsing ${customClass}`}
        >
            <NextBg className="button-bg" />
            {text}
            <ArrowRight className="button-icon" />
        </Link>
    ) : (
        <button
            onClick={onclick}
            className={`c-button button-background button--medium button-blue-small pulsing ${customClass}`}
        >
            <NextBg className="button-bg" />
            {text}
            <ArrowRight className="button-icon" />
        </button>
    );
};

export const TryAgainButton = ({
    onclick,
    text,
    customClass,
}: {
    onclick: () => void;
    text: string;
    customClass?: string;
}) => {
    return (
        <button
            onClick={onclick}
            className={`c-button button-background button--medium button-blue-small pulsing ${customClass}`}
        >
            <TryAgainBg className="button-bg" />
            <TryAgainIcon className="button-icon" />
            {text}
        </button>
    );
};
