import BaseBackground from "src/compnents/backgrounds/base/base-background";
import Navbar from "src/compnents/navbar/Navbar";
import useVotoStore from "src/store";
import SinglePlayerQuiz from "./Single-player-quiz";
import TwoPlayerQuiz from "./Two-player-quiz";
import QuizFooter from "src/compnents/footer/quiz/Quiz.footer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "src/routes/routes";
import { quiz_question_number } from "src/constants";

const QuizPage = () => {
    const navigate = useNavigate();

    const {
        quiz: {
            playerCount,
            spinning,
            players,
            winner,
            countdown,
            secondPlayerLastTry,
        },
        handleWinner,
        setGameFinish,
    } = useVotoStore();

    useEffect(() => {
        if (players.some((player) => player.points === quiz_question_number)) {
            handleWinner();
        }
    }, [players]);

    useEffect(() => {
        if (playerCount === 1) {
            if (players[0].points === quiz_question_number) {
                setGameFinish();
            }
        }
        if (playerCount === 2) {
            if (secondPlayerLastTry) {
                setGameFinish();
            }
        }
    }, [playerCount, players, secondPlayerLastTry]);

    useEffect(() => {
        if (winner.length !== 0 && !countdown.state) {
            if (playerCount === 1) {
                navigate(routes.quizWinner);
            }
            if (playerCount === 2 && secondPlayerLastTry) {
                navigate(routes.quizWinner);
            }
        }
    }, [countdown, winner, secondPlayerLastTry]);

    return (
        <div className="voto-quiz dark-background-overlay c-voto-page">
            {!spinning && <Navbar showHome={true} />}
            <BaseBackground />
            <div className="voto-quiz__main">
                {playerCount !== 2 ? <SinglePlayerQuiz /> : <TwoPlayerQuiz />}
            </div>
            <QuizFooter />
        </div>
    );
};
export default QuizPage;
