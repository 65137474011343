import useVotoStore from "src/store";
import balance_meter_pin from "src/assets/svg/balance-meter/balance-meter-pin.svg";
import balance_meter_frame from "src/assets/svg/balance-meter/balance-meter-frame.svg";
import "./pin.scss";

const PinGuardian = () => {
    const {
        guardian: { activeStage, stages, finished },
    } = useVotoStore();

    const currentStage = stages[Number(activeStage) - 1];

    const moving = {
        animation: `pinMove ${currentStage.time}s linear forwards`,
    };
    const loose = { transform: "translateX(-50%) rotateZ(-90deg)" };
    const win = { transform: "translateX(-50%) rotateZ(90deg)" };

    return (
        <div className="voto-balance-meter">
            <div className="voto-balance-meter__container">
                <img
                    className="balance-meter__frame"
                    src={balance_meter_frame}
                    alt=""
                />
                <img
                    style={
                        finished
                            ? {}
                            : currentStage.started &&
                              !currentStage.completed &&
                              !currentStage.finished
                            ? moving
                            : currentStage.finished && !currentStage.completed
                            ? loose
                            : currentStage.finished && currentStage.completed
                            ? win
                            : {}
                    }
                    className="balance-meter__pin"
                    src={balance_meter_pin}
                    alt=""
                />
            </div>
        </div>
    );
};

export default PinGuardian;
