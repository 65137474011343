import useVotoStore from "src/store";
import "./end-game-modal.scss";
import { Link } from "react-router-dom";
import { translationService } from "src/services/translation.service";
import useAnalyticsEventTracker from "src/hooks/google-analytics/useAnalyticsEventTracker";
import { routes } from "src/routes/routes";
import { ReactComponent as FinishBackground } from "src/assets/svg/buttons-blue/finish-bg.svg";
import { ReactComponent as ContinuePlayBackground } from "src/assets/svg/buttons-blue/extra-large-bg.svg";

const EndGameOverlay = () => {
    const { setOverlay, resetState, locale } = useVotoStore();

    const gaEventTracker = useAnalyticsEventTracker("Click event");

    const titleText = translationService.translate("overlay.title", locale);
    const subtitleText = translationService.translate(
        "overlay.subtitle",
        locale
    );
    const cancelText = translationService.translate("overlay.cancel", locale);
    const endText = translationService.translate("shared.end", locale);

    const handleReset = () => {
        gaEventTracker("Click on End");
        resetState();
    };

    return (
        <div className="voto-overlay">
            <div className="modal-content">
                <h2 className="title">{titleText}?</h2>
                <h4 className="subtitle">{subtitleText}.</h4>
                <div className="modal-content__buttons">
                    <button
                        onClick={() => setOverlay(false)}
                        className="c-button button-background button--large btn-cancel"
                    >
                        <ContinuePlayBackground className="button-bg" />
                        {cancelText}
                    </button>

                    <Link
                        to={routes.home}
                        onClick={handleReset}
                        className="c-button button-background button--large btn-end"
                    >
                        <FinishBackground className="button-bg" />
                        {endText.toLocaleUpperCase()}
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default EndGameOverlay;
