import scientistAnimation from "src/assets/animations/Professor.json";
import "./scientist.scss";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import { useEffect, useRef } from "react";
import useVotoStore from "src/store";

const Scientist = ({ audioIsPlaying }: { audioIsPlaying: boolean }) => {
    const scientistRef = useRef<LottieRefCurrentProps>(null);

    useEffect(() => {
        if (audioIsPlaying) {
            scientistRef.current?.play();
        }
        if (!audioIsPlaying) {
            scientistRef.current?.pause();
        }

        return () => {};
    }, [audioIsPlaying]);

    return (
        <div className={`voto-scientist`}>
            <Lottie
                lottieRef={scientistRef}
                className="voto-scientist__scientist"
                animationData={scientistAnimation}
                loop={true}
                autoplay={false}
            />
        </div>
    );
};

export default Scientist;
