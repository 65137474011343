import { Fragment } from "react";
import "./bubbles.scss";

const Bubbles = () => {
    const bubbleSizeRange = [0.3, 3.9];
    const bubblePositionRange = [3, 90];
    const bubbleOpacityRange = [0.2, 1];
    const bubbleSpeedRange = [5, 10];
    const bubbleNumber = 20;

    const getRandomArbitrary = (min: number, max: number) => {
        return Math.random() * (max - min) + min;
    };

    return (
        <div className="voto-bubbles">
            {Array.from(Array(bubbleNumber), (e, i) => {
                const animationDelayRange = getRandomArbitrary(0, 5);
                const animationSpeedRange = getRandomArbitrary(
                    bubbleSpeedRange[0],
                    bubbleSpeedRange[1]
                );
                const randomSize = getRandomArbitrary(
                    bubbleSizeRange[0],
                    bubbleSizeRange[1]
                );
                const randomOpacity = getRandomArbitrary(
                    bubbleOpacityRange[0],
                    bubbleOpacityRange[1]
                );
                const randomPosition = getRandomArbitrary(
                    bubblePositionRange[0],
                    bubblePositionRange[1]
                );
                return (
                    <Fragment key={i}>
                        <span
                            className="bubble"
                            style={{
                                width: `${randomSize}vw`,
                                height: `${randomSize}vw`,
                                left: `${randomPosition}%`,
                                opacity: randomOpacity,
                                animation: `rise-bubble ${animationSpeedRange}s linear infinite`,
                                animationDelay: `${animationDelayRange}s`,
                            }}
                        ></span>
                    </Fragment>
                );
            })}
        </div>
    );
};
export default Bubbles;
