import blue_line from "src/assets/images/adventure/vacation/blue-line.png";
import stone_left from "src/assets/images/adventure/vacation/stone-left.png";
import stone_right from "src/assets/images/adventure/vacation/stone-right.png";
import snorkle_thumbnail from "src/assets/images/adventure/vacation/snorkle.png";
import treasures_thumbnail from "src/assets/images/adventure/vacation/treasures.png";
import castle_thumbnail from "src/assets/images/adventure/vacation/castle.png";
import { Link } from "react-router-dom";
import StarsRank from "src/compnents/stars/Stars.rank";
import useVotoStore from "src/store";
import { Stage_3 } from "src/types/index.d";
import Navbar from "src/compnents/navbar/Navbar";
import { translationService } from "src/services/translation.service";
import { ReactComponent as BackHomeBackground } from "src/assets/svg/buttons-blue/go-back-home-bg.svg";

const BeachHome = () => {
    const starscount = 5;
    const {
        locale,
        vacation: { stages },
    } = useVotoStore();
    const snorkleStage = stages.find(
        (stage) => stage.name === Stage_3.snorkle_find_hidden
    );
    const castleStage = stages.find(
        (stage) => stage.name === Stage_3.puzzle_castle
    );
    const treasuresStage = stages.find(
        (stage) => stage.name === Stage_3.find_treasures
    );

    const titleText = translationService.translate("beachPage.title", locale);
    const subtitleText = translationService.translate(
        "beachPage.subtitle",
        locale
    );
    const backHomeText = translationService.translate(
        "beachPage.backHome",
        locale
    );
    const castleTaskText = translationService.translate(
        "footer.vacation.castleTask",
        locale
    );
    const treasureTaskText = translationService.translate(
        "footer.vacation.treasureTask",
        locale
    );
    const snorkleTaskText = translationService.translate(
        "footer.vacation.snorkleTask",
        locale
    );

    return (
        <div className="c-beach-home">
            <Navbar showHome={true} />
            <img className="blue-line" src={blue_line} alt="" />
            <img className="stone-left" src={stone_left} alt="" />
            <img className="stone-right" src={stone_right} alt="" />
            <div className="top-text">
                <h2 className="title">{titleText}</h2>
                <h5 className="subtitle">{subtitleText}</h5>
            </div>
            <div className="c-beach-home__stages">
                <div className="beach-stages__list">
                    <Link to="/snorkle" className="beach-stage">
                        <div className="beach-stage__rank">
                            <StarsRank
                                doneItem={
                                    snorkleStage!.subStages.filter(
                                        (sub) => sub.completed === true
                                    ).length
                                }
                                totalItem={snorkleStage!.subStages.length}
                                startsNumber={starscount}
                            />
                        </div>
                        <img
                            className="beach-stage__thumbnail"
                            src={snorkle_thumbnail}
                            alt=""
                        />
                        <h5 className="beach-stage__name">{snorkleTaskText}</h5>
                    </Link>
                    <Link to="/castle" className="beach-stage">
                        <div className="beach-stage__rank">
                            <StarsRank
                                doneItem={
                                    castleStage!.subStages.filter(
                                        (sub) => sub.completed === true
                                    ).length
                                }
                                totalItem={castleStage!.subStages.length}
                                startsNumber={starscount}
                            />
                        </div>
                        <img
                            className="beach-stage__thumbnail"
                            src={castle_thumbnail}
                            alt=""
                        />
                        <h5 className="beach-stage__name">{castleTaskText}</h5>
                    </Link>
                    <Link to="/treasures" className="beach-stage">
                        <div className="beach-stage__rank">
                            <StarsRank
                                doneItem={
                                    treasuresStage!.subStages.filter(
                                        (sub) => sub.completed === true
                                    ).length
                                }
                                totalItem={treasuresStage!.subStages.length}
                                startsNumber={starscount}
                            />
                        </div>
                        <img
                            className="beach-stage__thumbnail"
                            src={treasures_thumbnail}
                            alt=""
                        />
                        <h5 className="beach-stage__name">
                            {treasureTaskText}
                        </h5>
                    </Link>
                </div>
                <div className="c-beach-home__stages-go-home">
                    <Link
                        to="/packup-pre-beach"
                        className="c-button button-background button--medium button-blue-large go-home"
                    >
                        <BackHomeBackground className="button-bg" />
                        {backHomeText}
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default BeachHome;
