import Lottie, { LottieRefCurrentProps } from "lottie-react";
import { useRef } from "react";
import firework_red from "src/assets/svg/firework-red.svg";
import firework_blue from "src/assets/svg/firework-blue.svg";
import firework_green from "src/assets/svg/firework-green.svg";
import delfin from "src/assets/svg/background/bravo/delfin.svg";
import mort from "src/assets/svg/background/bravo/mort.svg";
import seagull from "src/assets/svg/background/bravo/seagull.svg";
import sportfiskaren from "src/assets/images/background/sportfiskaren@400x.png";
import duck from "src/assets/svg/background/bravo/duck.svg";
import bird from "src/assets/svg/background/bravo/bird.svg";
import sill from "src/assets/svg/background/bravo/sill.svg";
import skarpsill from "src/assets/svg/background/bravo/skarpsill.svg";
import torsk from "src/assets/svg/background/bravo/torsk.svg";
import tumlare from "src/assets/svg/background/bravo/tumlare.svg";
import fish_3 from "src/assets/svg/background/fish-5.svg";
import fish_4 from "src/assets/svg/background/fish-4.svg";
import fish_5 from "src/assets/svg/background/fish-5.svg";
import veg_1 from "src/assets/svg/background/veg-1.svg";
import veg_2 from "src/assets/svg/background/veg-2.svg";
import veg_3 from "src/assets/svg/background/veg-3.svg";
import veg_4 from "src/assets/svg/background/veg-4.svg";
import aborre from "src/assets/images/adventure/aborre.png";
import fix_background from "src/assets/svg/background/bravo/fix-items.svg";
import firework_sound_1 from "src/assets/sounds/fireworking/FireWorks-Double-A.mp3";
import firework_sound_2 from "src/assets/sounds/fireworking/FireWorks-Rocket-Whistles.mp3";
import firework_sound_3 from "src/assets/sounds/fireworking/FireWorks-Single.mp3";
import firework_sound_4 from "src/assets/sounds/fireworking/FireWorks-Single-B.mp3";
import firework_sound_5 from "src/assets/sounds/fireworking/Fireworks-Crackling.mp3";
import useVotoStore from "src/store";
import { translationService } from "src/services/translation.service";
import Scientist from "src/compnents/scientist/Scientist";

const BravoScene = () => {
    const fireworksRef = useRef<LottieRefCurrentProps>(null);
    const { locale } = useVotoStore();

    const scientistText = translationService.translate(
        "bravoPage.scientist",
        locale
    );

    return (
        <div className="guardian-end-bravo">
            <img
                className="fix-background absolute"
                src={fix_background}
                alt=""
            />

            <img src={seagull} className="seagull absolute" alt="" />
            <img
                src={sportfiskaren}
                className="sportfiskaren absolute"
                alt=""
            />
            <div className="background-vegi absolute">
                <img src={veg_1} className="veg veg-1" alt="" />
                <img src={veg_2} className="veg veg-2" alt="" />
                <img src={veg_3} className="veg veg-3" alt="" />
                <img src={veg_4} className="veg veg-4" alt="" />
                <img src={veg_1} className="veg veg-5" alt="" />
                <img src={veg_2} className="veg veg-6" alt="" />
                <img src={veg_3} className="veg veg-7" alt="" />
                <img src={veg_4} className="veg veg-8" alt="" />
            </div>

            <span className="fish-3 fish">
                <img
                    style={{ animation: "swim-aborre 8s linear infinite" }}
                    src={fish_3}
                    alt=""
                />
            </span>
            <span className="fish-4 fish">
                <img
                    style={{ animation: "swim-gedda 9s linear infinite" }}
                    src={fish_4}
                    alt=""
                />
            </span>
            <span className="fish-5 fish">
                <img
                    style={{ animation: "swim-aborre 14s linear infinite" }}
                    src={fish_5}
                    alt=""
                />
            </span>
            <span className="fish-6 fish">
                <img
                    style={{ animation: "swim-aborre 9s linear infinite" }}
                    src={torsk}
                    alt=""
                />
            </span>
            <span className="fish-7 fish">
                <img
                    style={{ animation: "swim-aborre 11s linear infinite" }}
                    src={delfin}
                    alt=""
                />
            </span>
            <span className="fish-8 fish">
                <img
                    style={{ animation: "swim-gedda 21s linear infinite" }}
                    src={tumlare}
                    alt=""
                />
            </span>

            <span className="fish-15 fish">
                <img
                    style={{
                        animation: "swim-aborre 14s 2s linear infinite",
                    }}
                    src={aborre}
                    alt=""
                />
            </span>
            <span className="fish-16 fish">
                <img
                    style={{
                        animation: "swim-gedda 9s 2s linear infinite",
                    }}
                    src={sill}
                    alt=""
                />
            </span>
            <span className="fish-17 fish">
                <img
                    style={{ animation: "swim-gedda 8s linear infinite" }}
                    src={skarpsill}
                    alt=""
                />
            </span>
            <span className="fish-18 fish">
                <img
                    style={{ animation: "swim-gedda 12s linear infinite" }}
                    src={mort}
                    alt=""
                />
            </span>
            <span className="fish-19">
                <img
                    style={{ animation: "swim-gedda 12s linear infinite" }}
                    src={duck}
                    alt=""
                />
            </span>
            <span className="fish-20">
                <img
                    style={{ animation: "bird-fly 50s linear infinite" }}
                    src={bird}
                    alt=""
                />
            </span>
            <div className="green-box absolute">
                <div className="text-wrapper">
                    <p className="box-text">{scientistText}</p>
                </div>
            </div>
            <img src={firework_red} className="firework firework__red" />
            <img src={firework_green} className="firework firework__green" />
            <img src={firework_blue} className="firework firework__blue" />
            <audio src={firework_sound_1} autoPlay={true} loop={true} />
            <audio src={firework_sound_2} autoPlay={true} loop={true} />
            <audio src={firework_sound_3} autoPlay={true} loop={true} />
            <audio src={firework_sound_4} autoPlay={true} loop={true} />
            <audio src={firework_sound_5} autoPlay={true} loop={true} />
            <Scientist audioIsPlaying={false} />
        </div>
    );
};
export default BravoScene;
