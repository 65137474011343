import { translationService } from "src/services/translation.service";
import useVotoStore from "src/store";
import { SelectedQuestion } from "src/types/index.d";
import AreaIcon from "src/utils/Area-icon";
import { ReactComponent as HistoryIcon } from "src/assets/svg/quiz/history-icon.svg";
import { getTranslatedName } from "src/utils/helpers";

const Feedback = ({
    selectedQuestion,
}: {
    selectedQuestion: SelectedQuestion;
}) => {
    const {
        locale,
        quiz: { playerCount, activePlayer, players },
    } = useVotoStore();

    const nextPlayerName =
        playerCount === 1
            ? ""
            : activePlayer === 1
            ? getTranslatedName(players[1].name, locale)
            : getTranslatedName(players[0].name, locale);

    const rightFeedbackTitle = translationService.translate(
        "quizPage.feedback.right.title",
        locale
    );
    const rightFeedbackText = translationService.translate(
        "quizPage.feedback.right.text",
        locale
    );

    const WrongFeedbackTitle = translationService.translate(
        "quizPage.feedback.wrong.title",
        locale
    );
    const WrongFeedbackText_1 = translationService.translate(
        "quizPage.feedback.wrong.text1",
        locale
    );
    const WrongFeedbackText_2 = translationService.translate(
        "quizPage.feedback.wrong.text2",
        locale,
        nextPlayerName
    );

    return (
        <div
            className={`quiz-feedback ${
                selectedQuestion.answerdCorrect
                    ? "feedback--right"
                    : "feedback--wrong"
            }`}
        >
            <h2 className="white-text feedback-title">
                {selectedQuestion.answerdCorrect
                    ? rightFeedbackTitle
                    : WrongFeedbackTitle}
            </h2>
            <h4 className="white-text feedback-text">
                {selectedQuestion.answerdCorrect
                    ? rightFeedbackText
                    : playerCount === 1
                    ? WrongFeedbackText_1
                    : WrongFeedbackText_2}
            </h4>
            <div className="feedback-icon">
                <AreaIcon area={selectedQuestion.area} />
            </div>
        </div>
    );
};
export default Feedback;
