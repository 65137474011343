import cloud from "src/assets/images/adventure/vacation/castle/cloud.png";
import tree_img from "src/assets/images/adventure/vacation/castle/tree.png";
import top_tower_img from "src/assets/images/adventure/vacation/castle/top-tower.png";
import tower_img from "src/assets/images/adventure/vacation/castle/tower.png";
import triangle_ladder_img from "src/assets/images/adventure/vacation/castle/triangle-ladder.png";
import right_door_img from "src/assets/images/adventure/vacation/castle/right-door.png";
import left_door_img from "src/assets/images/adventure/vacation/castle/left-door.png";
import main_top_tower_img from "src/assets/images/adventure/vacation/castle/main-top-tower.png";
import main_tower_img from "src/assets/images/adventure/vacation/castle/main-tower.png";
import door_img from "src/assets/images/adventure/vacation/castle/door.png";
import ladder_img from "src/assets/images/adventure/vacation/castle/ladder.png";
import tree_placeholder_img from "src/assets/images/adventure/vacation/castle/tree-placeholder.png";
import top_tower_placeholder_img from "src/assets/images/adventure/vacation/castle/top-tower-placeholder.png";
import tower_placeholder_img from "src/assets/images/adventure/vacation/castle/tower-placeholder.png";
import triangle_ladder_placeholder_img from "src/assets/images/adventure/vacation/castle/triangle-placeholder.png";
import right_door_placeholder_img from "src/assets/images/adventure/vacation/castle/right-door-placeholder.png";
import left_door_placeholder_img from "src/assets/images/adventure/vacation/castle/left-door-placeholder.png";
import main_top_tower_placeholder_img from "src/assets/images/adventure/vacation/castle/main-top-tower-placeholder.png";
import main_tower_placeholder_img from "src/assets/images/adventure/vacation/castle/main-tower-placeholder.png";
import door_placeholder_img from "src/assets/images/adventure/vacation/castle/door-placeholder.png";
import ladder_placeholder_img from "src/assets/images/adventure/vacation/castle/ladder-placeholder.png";
import tree_dott_placeholder_img from "src/assets/images/adventure/vacation/castle/tree-dott-placeholder.png";
import top_tower_dott_placeholder_img from "src/assets/images/adventure/vacation/castle/top-tower-dott-placeholder.png";
import tower_dott_placeholder_img from "src/assets/images/adventure/vacation/castle/tower-dott-placeholder.png";
import triangle_ladder_dott_placeholder_img from "src/assets/images/adventure/vacation/castle/triangle-dott-placeholder.png";
import right_door_dott_placeholder_img from "src/assets/images/adventure/vacation/castle/right-door-dott-placeholder.png";
import left_door_dott_placeholder_img from "src/assets/images/adventure/vacation/castle/left-door-dott-placeholder.png";
import main_top_tower_dott_placeholder_img from "src/assets/images/adventure/vacation/castle/main-top-tower-dott-placeholder.png";
import main_tower_dott_placeholder_img from "src/assets/images/adventure/vacation/castle/main-tower-dott-placeholder.png";
import door_dott_placeholder_img from "src/assets/images/adventure/vacation/castle/door-dott-placeholder.png";
import ladder_dott_placeholder_img from "src/assets/images/adventure/vacation/castle/ladder-dott-placeholder.png";
import {
    SubStage_go_vacation,
    SubStage_puzzle_castle,
} from "src/types/index.d";
import useVotoStore from "src/store";
import { useEffect, useRef, useState } from "react";
import Draggable, { DraggableData } from "react-draggable";
import Navbar from "src/compnents/navbar/Navbar";
import FooterCastle from "src/compnents/footer/castle/Footer.castle";

const Castle = () => {
    const castle_container = useRef<HTMLDivElement>(null);
    const tower_1_ph = useRef<HTMLImageElement>(null);
    const tower_2_ph = useRef<HTMLImageElement>(null);
    const door_ph = useRef<HTMLImageElement>(null);
    const triangle_ladder_ph = useRef<HTMLImageElement>(null);
    const main_top_tower_ph = useRef<HTMLImageElement>(null);
    const ladder_ph = useRef<HTMLImageElement>(null);
    const right_door_ph = useRef<HTMLImageElement>(null);
    const left_door_ph = useRef<HTMLImageElement>(null);
    const top_tower_1_ph = useRef<HTMLImageElement>(null);
    const top_tower_2_ph = useRef<HTMLImageElement>(null);
    const main_tower_ph = useRef<HTMLImageElement>(null);
    const tree_1_ph = useRef<HTMLImageElement>(null);
    const tree_2_ph = useRef<HTMLImageElement>(null);

    const tower_1_item = useRef<HTMLImageElement>(null);
    const door_item = useRef<HTMLImageElement>(null);
    const triangle_ladder_item = useRef<HTMLImageElement>(null);
    const main_top_tower_item = useRef<HTMLImageElement>(null);
    const ladder_item = useRef<HTMLImageElement>(null);
    const top_tower_1_item = useRef<HTMLImageElement>(null);
    const tree_1_item = useRef<HTMLImageElement>(null);
    const right_door_item = useRef<HTMLImageElement>(null);
    const top_tower_2_item = useRef<HTMLImageElement>(null);
    const main_tower_item = useRef<HTMLImageElement>(null);
    const left_door_item = useRef<HTMLImageElement>(null);
    const tower_2_item = useRef<HTMLImageElement>(null);
    const tree_2_item = useRef<HTMLImageElement>(null);

    const row_container_1 = useRef<HTMLImageElement>(null);
    const row_container_2 = useRef<HTMLImageElement>(null);
    const box_items_contaienr = useRef<HTMLImageElement>(null);

    const [startDrag, setStartDrag] = useState(false);
    const [placeholder, setPlaceholder] = useState<any>({
        [SubStage_puzzle_castle.tower_1]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        [SubStage_puzzle_castle.tower_2]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        [SubStage_puzzle_castle.door]: { left: 0, right: 0, top: 0, bottom: 0 },
        [SubStage_puzzle_castle.triangle_ladder]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        [SubStage_puzzle_castle.main_top_tower]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        [SubStage_puzzle_castle.ladder]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        [SubStage_puzzle_castle.right_door]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        [SubStage_puzzle_castle.left_door]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        [SubStage_puzzle_castle.top_tower_1]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        [SubStage_puzzle_castle.top_tower_2]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        [SubStage_puzzle_castle.main_tower]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        [SubStage_puzzle_castle.tree_1]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        [SubStage_puzzle_castle.tree_2]: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
    });
    const {
        vacation: { stages },
        vacationStageCompletedById,
        vacationStageFinishedById,
        vacationStageStartById,
        vacationSubstageCompletedByName,
        vacationSubstageDoneWith,
    } = useVotoStore();
    const castleStage = stages[2];

    useEffect(() => {
        if (placeholder[SubStage_puzzle_castle.tower_1].left === 0) {
            setPlaceholder({
                [SubStage_puzzle_castle.tower_1]: {
                    left:
                        castle_container.current && tower_1_ph.current
                            ? castle_container.current?.offsetLeft +
                              tower_1_ph.current?.offsetLeft
                            : 0,
                    right:
                        castle_container.current && tower_1_ph.current
                            ? castle_container.current?.offsetLeft +
                              tower_1_ph.current?.offsetLeft +
                              tower_1_ph.current?.offsetWidth
                            : 0,
                    top:
                        castle_container.current && tower_1_ph.current
                            ? castle_container.current?.offsetTop +
                              tower_1_ph.current?.offsetTop
                            : 0,
                    bottom:
                        castle_container.current && tower_1_ph.current
                            ? castle_container.current?.offsetTop +
                              tower_1_ph.current?.offsetTop +
                              tower_1_ph.current?.offsetHeight
                            : 0,
                },
                [SubStage_puzzle_castle.tower_2]: {
                    left:
                        castle_container.current && tower_2_ph.current
                            ? castle_container.current?.offsetLeft +
                              tower_2_ph.current?.offsetLeft
                            : 0,
                    right:
                        castle_container.current && tower_2_ph.current
                            ? castle_container.current?.offsetLeft +
                              tower_2_ph.current?.offsetLeft +
                              tower_2_ph.current?.offsetWidth
                            : 0,
                    top:
                        castle_container.current && tower_2_ph.current
                            ? castle_container.current?.offsetTop +
                              tower_2_ph.current?.offsetTop
                            : 0,
                    bottom:
                        castle_container.current && tower_2_ph.current
                            ? castle_container.current?.offsetTop +
                              tower_2_ph.current?.offsetTop +
                              tower_2_ph.current?.offsetHeight
                            : 0,
                },
                [SubStage_puzzle_castle.door]: {
                    left:
                        castle_container.current && door_ph.current
                            ? castle_container.current?.offsetLeft +
                              door_ph.current?.offsetLeft
                            : 0,
                    right:
                        castle_container.current && door_ph.current
                            ? castle_container.current?.offsetLeft +
                              door_ph.current?.offsetLeft +
                              door_ph.current?.offsetWidth
                            : 0,
                    top:
                        castle_container.current && door_ph.current
                            ? castle_container.current?.offsetTop +
                              door_ph.current?.offsetTop
                            : 0,
                    bottom:
                        castle_container.current && door_ph.current
                            ? castle_container.current?.offsetTop +
                              door_ph.current?.offsetTop +
                              door_ph.current?.offsetHeight
                            : 0,
                },
                [SubStage_puzzle_castle.triangle_ladder]: {
                    left:
                        castle_container.current && triangle_ladder_ph.current
                            ? castle_container.current?.offsetLeft +
                              triangle_ladder_ph.current?.offsetLeft
                            : 0,
                    right:
                        castle_container.current && triangle_ladder_ph.current
                            ? castle_container.current?.offsetLeft +
                              triangle_ladder_ph.current?.offsetLeft +
                              triangle_ladder_ph.current?.offsetWidth
                            : 0,
                    top:
                        castle_container.current && triangle_ladder_ph.current
                            ? castle_container.current?.offsetTop +
                              triangle_ladder_ph.current?.offsetTop
                            : 0,
                    bottom:
                        castle_container.current && triangle_ladder_ph.current
                            ? castle_container.current?.offsetTop +
                              triangle_ladder_ph.current?.offsetTop +
                              triangle_ladder_ph.current?.offsetHeight
                            : 0,
                },
                [SubStage_puzzle_castle.main_top_tower]: {
                    left:
                        castle_container.current && main_top_tower_ph.current
                            ? castle_container.current?.offsetLeft +
                              main_top_tower_ph.current?.offsetLeft
                            : 0,
                    right:
                        castle_container.current && main_top_tower_ph.current
                            ? castle_container.current?.offsetLeft +
                              main_top_tower_ph.current?.offsetLeft +
                              main_top_tower_ph.current?.offsetWidth
                            : 0,
                    top:
                        castle_container.current && main_top_tower_ph.current
                            ? castle_container.current?.offsetTop +
                              main_top_tower_ph.current?.offsetTop
                            : 0,
                    bottom:
                        castle_container.current && main_top_tower_ph.current
                            ? castle_container.current?.offsetTop +
                              main_top_tower_ph.current?.offsetTop +
                              main_top_tower_ph.current?.offsetHeight
                            : 0,
                },
                [SubStage_puzzle_castle.ladder]: {
                    left:
                        castle_container.current && ladder_ph.current
                            ? castle_container.current?.offsetLeft +
                              ladder_ph.current?.offsetLeft
                            : 0,
                    right:
                        castle_container.current && ladder_ph.current
                            ? castle_container.current?.offsetLeft +
                              ladder_ph.current?.offsetLeft +
                              ladder_ph.current?.offsetWidth
                            : 0,
                    top:
                        castle_container.current && ladder_ph.current
                            ? castle_container.current?.offsetTop +
                              ladder_ph.current?.offsetTop
                            : 0,
                    bottom:
                        castle_container.current && ladder_ph.current
                            ? castle_container.current?.offsetTop +
                              ladder_ph.current?.offsetTop +
                              ladder_ph.current?.offsetHeight
                            : 0,
                },
                [SubStage_puzzle_castle.right_door]: {
                    left:
                        castle_container.current && right_door_ph.current
                            ? castle_container.current?.offsetLeft +
                              right_door_ph.current?.offsetLeft
                            : 0,
                    right:
                        castle_container.current && right_door_ph.current
                            ? castle_container.current?.offsetLeft +
                              right_door_ph.current?.offsetLeft +
                              right_door_ph.current?.offsetWidth
                            : 0,
                    top:
                        castle_container.current && right_door_ph.current
                            ? castle_container.current?.offsetTop +
                              right_door_ph.current?.offsetTop
                            : 0,
                    bottom:
                        castle_container.current && right_door_ph.current
                            ? castle_container.current?.offsetTop +
                              right_door_ph.current?.offsetTop +
                              right_door_ph.current?.offsetHeight
                            : 0,
                },
                [SubStage_puzzle_castle.left_door]: {
                    left:
                        castle_container.current && left_door_ph.current
                            ? castle_container.current?.offsetLeft +
                              left_door_ph.current?.offsetLeft
                            : 0,
                    right:
                        castle_container.current && left_door_ph.current
                            ? castle_container.current?.offsetLeft +
                              left_door_ph.current?.offsetLeft +
                              left_door_ph.current?.offsetWidth
                            : 0,
                    top:
                        castle_container.current && left_door_ph.current
                            ? castle_container.current?.offsetTop +
                              left_door_ph.current?.offsetTop
                            : 0,
                    bottom:
                        castle_container.current && left_door_ph.current
                            ? castle_container.current?.offsetTop +
                              left_door_ph.current?.offsetTop +
                              left_door_ph.current?.offsetHeight
                            : 0,
                },
                [SubStage_puzzle_castle.top_tower_1]: {
                    left:
                        castle_container.current && top_tower_1_ph.current
                            ? castle_container.current?.offsetLeft +
                              top_tower_1_ph.current?.offsetLeft
                            : 0,
                    right:
                        castle_container.current && top_tower_1_ph.current
                            ? castle_container.current?.offsetLeft +
                              top_tower_1_ph.current?.offsetLeft +
                              top_tower_1_ph.current?.offsetWidth
                            : 0,
                    top:
                        castle_container.current && top_tower_1_ph.current
                            ? castle_container.current?.offsetTop +
                              top_tower_1_ph.current?.offsetTop
                            : 0,
                    bottom:
                        castle_container.current && top_tower_1_ph.current
                            ? castle_container.current?.offsetTop +
                              top_tower_1_ph.current?.offsetTop +
                              top_tower_1_ph.current?.offsetHeight
                            : 0,
                },
                [SubStage_puzzle_castle.top_tower_2]: {
                    left:
                        castle_container.current && top_tower_2_ph.current
                            ? castle_container.current?.offsetLeft +
                              top_tower_2_ph.current?.offsetLeft
                            : 0,
                    right:
                        castle_container.current && top_tower_2_ph.current
                            ? castle_container.current?.offsetLeft +
                              top_tower_2_ph.current?.offsetLeft +
                              top_tower_2_ph.current?.offsetWidth
                            : 0,
                    top:
                        castle_container.current && top_tower_2_ph.current
                            ? castle_container.current?.offsetTop +
                              top_tower_2_ph.current?.offsetTop
                            : 0,
                    bottom:
                        castle_container.current && top_tower_2_ph.current
                            ? castle_container.current?.offsetTop +
                              top_tower_2_ph.current?.offsetTop +
                              top_tower_2_ph.current?.offsetHeight
                            : 0,
                },
                [SubStage_puzzle_castle.main_tower]: {
                    left:
                        castle_container.current && main_tower_ph.current
                            ? castle_container.current?.offsetLeft +
                              main_tower_ph.current?.offsetLeft
                            : 0,
                    right:
                        castle_container.current && main_tower_ph.current
                            ? castle_container.current?.offsetLeft +
                              main_tower_ph.current?.offsetLeft +
                              main_tower_ph.current?.offsetWidth
                            : 0,
                    top:
                        castle_container.current && main_tower_ph.current
                            ? castle_container.current?.offsetTop +
                              main_tower_ph.current?.offsetTop
                            : 0,
                    bottom:
                        castle_container.current && main_tower_ph.current
                            ? castle_container.current?.offsetTop +
                              main_tower_ph.current?.offsetTop +
                              main_tower_ph.current?.offsetHeight
                            : 0,
                },
                [SubStage_puzzle_castle.tree_1]: {
                    left:
                        castle_container.current && tree_1_ph.current
                            ? castle_container.current?.offsetLeft +
                              tree_1_ph.current?.offsetLeft
                            : 0,
                    right:
                        castle_container.current && tree_1_ph.current
                            ? castle_container.current?.offsetLeft +
                              tree_1_ph.current?.offsetLeft +
                              tree_1_ph.current?.offsetWidth * 2
                            : 0,
                    top:
                        castle_container.current && tree_1_ph.current
                            ? castle_container.current?.offsetTop +
                              tree_1_ph.current?.offsetTop
                            : 0,
                    bottom:
                        castle_container.current && tree_1_ph.current
                            ? castle_container.current?.offsetTop +
                              tree_1_ph.current?.offsetTop +
                              tree_1_ph.current?.offsetHeight * 2
                            : 0,
                },
                [SubStage_puzzle_castle.tree_2]: {
                    left:
                        castle_container.current && tree_2_ph.current
                            ? castle_container.current?.offsetLeft +
                              tree_2_ph.current?.offsetLeft
                            : 0,
                    right:
                        castle_container.current && tree_2_ph.current
                            ? castle_container.current?.offsetLeft +
                              tree_2_ph.current?.offsetLeft +
                              tree_2_ph.current?.offsetWidth * 2
                            : 0,
                    top:
                        castle_container.current && tree_2_ph.current
                            ? castle_container.current?.offsetTop +
                              tree_2_ph.current?.offsetTop
                            : 0,
                    bottom:
                        castle_container.current && tree_2_ph.current
                            ? castle_container.current?.offsetTop +
                              tree_2_ph.current?.offsetTop +
                              tree_2_ph.current?.offsetHeight * 2
                            : 0,
                },
            });
        }
    }, [placeholder]);

    useEffect(() => {
        if (!castleStage.started) {
            vacationStageStartById(castleStage.id);
        }

        return () => {};
    }, [castleStage.id, castleStage.started, vacationStageStartById]);

    useEffect(() => {
        if (castleStage.started && !castleStage.completed) {
            if (castleStage.subStages.every((sub) => sub.completed === true)) {
                vacationStageCompletedById(castleStage.id);
                vacationStageFinishedById(castleStage.id);
            }
        }

        return () => {};
    }, [
        castleStage.completed,
        castleStage.id,
        castleStage.started,
        castleStage.subStages,
        vacationStageCompletedById,
        vacationStageFinishedById,
    ]);

    const onDrag = (e: any, ui: DraggableData) => {
        setStartDrag(true);

        if (
            box_items_contaienr.current &&
            row_container_1.current &&
            Object.keys(placeholder).includes(e.target.id)
        ) {
            const leftCenterOffset =
                box_items_contaienr.current.offsetLeft +
                row_container_1.current.offsetLeft +
                e.target.offsetLeft +
                e.target.offsetWidth / 2 +
                ui.lastX;
            const topCenterOffset =
                box_items_contaienr.current.offsetTop +
                row_container_1.current.offsetTop +
                e.target.offsetTop +
                e.target.offsetTop / 2 +
                ui.lastY;
        }
    };
    const onStop = (e: any, ui: DraggableData) => {
        setStartDrag(false);
        if (box_items_contaienr.current && row_container_1.current) {
            const leftCenterOffset =
                box_items_contaienr.current.offsetLeft +
                row_container_1.current.offsetLeft +
                e.target.offsetLeft +
                e.target.offsetWidth / 2 +
                ui.lastX;
            const topCenterOffset =
                box_items_contaienr.current.offsetTop +
                row_container_1.current.offsetTop +
                e.target.offsetTop +
                e.target.offsetTop / 2 +
                ui.lastY;

            if (
                e.target.id === SubStage_puzzle_castle.top_tower_1 ||
                e.target.id === SubStage_puzzle_castle.top_tower_2
            ) {
                if (
                    leftCenterOffset >=
                        placeholder[SubStage_puzzle_castle.top_tower_1].left &&
                    leftCenterOffset <=
                        placeholder[SubStage_puzzle_castle.top_tower_1].right &&
                    topCenterOffset >=
                        placeholder[SubStage_puzzle_castle.top_tower_1].top &&
                    topCenterOffset <=
                        placeholder[SubStage_puzzle_castle.top_tower_1].bottom
                ) {
                    vacationSubstageCompletedByName(
                        castleStage.id,
                        SubStage_puzzle_castle.top_tower_1
                    );
                    vacationSubstageDoneWith(
                        castleStage.id,
                        e.target.id,
                        e.target.id
                    );
                }
                if (
                    leftCenterOffset >=
                        placeholder[SubStage_puzzle_castle.top_tower_2].left &&
                    leftCenterOffset <=
                        placeholder[SubStage_puzzle_castle.top_tower_2].right &&
                    topCenterOffset >=
                        placeholder[SubStage_puzzle_castle.top_tower_2].top &&
                    topCenterOffset <=
                        placeholder[SubStage_puzzle_castle.top_tower_2].bottom
                ) {
                    vacationSubstageCompletedByName(
                        castleStage.id,
                        SubStage_puzzle_castle.top_tower_2
                    );
                    vacationSubstageDoneWith(
                        castleStage.id,
                        e.target.id,
                        e.target.id
                    );
                }
            }

            if (
                e.target.id === SubStage_puzzle_castle.tree_1 ||
                e.target.id === SubStage_puzzle_castle.tree_2
            ) {
                if (
                    leftCenterOffset >=
                        placeholder[SubStage_puzzle_castle.tree_1].left &&
                    leftCenterOffset <=
                        placeholder[SubStage_puzzle_castle.tree_1].right &&
                    topCenterOffset >=
                        placeholder[SubStage_puzzle_castle.tree_1].top &&
                    topCenterOffset <=
                        placeholder[SubStage_puzzle_castle.tree_1].bottom
                ) {
                    vacationSubstageCompletedByName(
                        castleStage.id,
                        SubStage_puzzle_castle.tree_1
                    );
                    vacationSubstageDoneWith(
                        castleStage.id,
                        e.target.id,
                        e.target.id
                    );
                }
                if (
                    leftCenterOffset >=
                        placeholder[SubStage_puzzle_castle.tree_2].left &&
                    leftCenterOffset <=
                        placeholder[SubStage_puzzle_castle.tree_2].right &&
                    topCenterOffset >=
                        placeholder[SubStage_puzzle_castle.tree_2].top &&
                    topCenterOffset <=
                        placeholder[SubStage_puzzle_castle.tree_2].bottom
                ) {
                    vacationSubstageCompletedByName(
                        castleStage.id,
                        SubStage_puzzle_castle.tree_2
                    );
                    vacationSubstageDoneWith(
                        castleStage.id,
                        e.target.id,
                        e.target.id
                    );
                }
            }
            if (
                e.target.id === SubStage_puzzle_castle.tower_1 ||
                e.target.id === SubStage_puzzle_castle.tower_2
            ) {
                if (
                    leftCenterOffset >=
                        placeholder[SubStage_puzzle_castle.tower_1].left &&
                    leftCenterOffset <=
                        placeholder[SubStage_puzzle_castle.tower_1].right &&
                    topCenterOffset >=
                        placeholder[SubStage_puzzle_castle.tower_1].top &&
                    topCenterOffset <=
                        placeholder[SubStage_puzzle_castle.tower_1].bottom
                ) {
                    vacationSubstageCompletedByName(
                        castleStage.id,
                        SubStage_puzzle_castle.tower_1
                    );
                    vacationSubstageDoneWith(
                        castleStage.id,
                        e.target.id,
                        e.target.id
                    );
                }
                if (
                    leftCenterOffset >=
                        placeholder[SubStage_puzzle_castle.tower_2].left &&
                    leftCenterOffset <=
                        placeholder[SubStage_puzzle_castle.tower_2].right &&
                    topCenterOffset >=
                        placeholder[SubStage_puzzle_castle.tower_2].top &&
                    topCenterOffset <=
                        placeholder[SubStage_puzzle_castle.tower_2].bottom
                ) {
                    vacationSubstageCompletedByName(
                        castleStage.id,
                        SubStage_puzzle_castle.tower_2
                    );
                    vacationSubstageDoneWith(
                        castleStage.id,
                        e.target.id,
                        e.target.id
                    );
                }
            }

            if (
                e.target.id !== SubStage_puzzle_castle.tree_1 &&
                e.target.id !== SubStage_puzzle_castle.tree_2 &&
                e.target.id !== SubStage_puzzle_castle.top_tower_1 &&
                e.target.id !== SubStage_puzzle_castle.top_tower_2 &&
                e.target.id !== SubStage_puzzle_castle.tower_1 &&
                e.target.id !== SubStage_puzzle_castle.tower_2
            )
                if (
                    leftCenterOffset >= placeholder[e.target.id].left &&
                    leftCenterOffset <= placeholder[e.target.id].right &&
                    topCenterOffset >= placeholder[e.target.id].top &&
                    topCenterOffset <= placeholder[e.target.id].bottom
                ) {
                    console.log(e.target.id, "done");
                    vacationSubstageCompletedByName(
                        castleStage.id,
                        e.target.id
                    );
                }
        }
    };

    return (
        <div className="c-castle__wrapper">
            <div className="c-castle">
                <Navbar showHome={true} />
                <img src={cloud} className="c-castle__cloud" alt="" />
                <div
                    className="c-castle__puzzle-pieces__top"
                    ref={box_items_contaienr}
                >
                    <div className="first-row" ref={row_container_1}>
                        <div className="tower-1">
                            {castleStage.subStages.find(
                                (sub) =>
                                    sub.name === SubStage_puzzle_castle.tower_1
                            )?.doneWith !== SubStage_puzzle_castle.tower_1 && (
                                <Draggable
                                    nodeRef={tower_1_item}
                                    position={{ x: 0, y: 0 }}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                >
                                    <img
                                        ref={tower_1_item}
                                        id={SubStage_puzzle_castle.tower_1}
                                        src={tower_img}
                                        className="puzzle-piece c-castle__puzzle-pieces__item tower-1-img"
                                        alt=""
                                    />
                                </Draggable>
                            )}
                            <img
                                src={tower_placeholder_img}
                                className="puzzle-piece tower-1-placeholder-img"
                                alt=""
                            />
                        </div>
                        <div className="door">
                            {!castleStage.subStages.find(
                                (sub) =>
                                    sub.name === SubStage_puzzle_castle.door
                            )!.completed && (
                                <Draggable
                                    nodeRef={door_item}
                                    position={{ x: 0, y: 0 }}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                >
                                    <img
                                        ref={door_item}
                                        id={SubStage_puzzle_castle.door}
                                        src={door_img}
                                        className="puzzle-piece c-castle__puzzle-pieces__item door-img"
                                        alt=""
                                    />
                                </Draggable>
                            )}
                            <img
                                src={door_placeholder_img}
                                className="puzzle-piece door-placeholder-img"
                                alt=""
                            />
                        </div>
                        <div className="triangle-ladder">
                            {!castleStage.subStages.find(
                                (sub) =>
                                    sub.name ===
                                    SubStage_puzzle_castle.triangle_ladder
                            )?.completed && (
                                <Draggable
                                    nodeRef={triangle_ladder_item}
                                    position={{ x: 0, y: 0 }}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                >
                                    <img
                                        ref={triangle_ladder_item}
                                        id={
                                            SubStage_puzzle_castle.triangle_ladder
                                        }
                                        src={triangle_ladder_img}
                                        className="puzzle-piece c-castle__puzzle-pieces__item triangle-ladder-img"
                                        alt=""
                                    />
                                </Draggable>
                            )}
                            <img
                                src={triangle_ladder_placeholder_img}
                                className="puzzle-piece triangle-ladder-placeholder-img"
                                alt=""
                            />
                        </div>
                        <div className="main-top-tower">
                            {!castleStage.subStages.find(
                                (sub) =>
                                    sub.name ===
                                    SubStage_puzzle_castle.main_top_tower
                            )?.completed && (
                                <Draggable
                                    nodeRef={main_top_tower_item}
                                    position={{ x: 0, y: 0 }}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                >
                                    <img
                                        ref={main_top_tower_item}
                                        id={
                                            SubStage_puzzle_castle.main_top_tower
                                        }
                                        src={main_top_tower_img}
                                        className="puzzle-piece c-castle__puzzle-pieces__item main-top-tower-img"
                                        alt=""
                                    />
                                </Draggable>
                            )}
                            <img
                                src={main_top_tower_placeholder_img}
                                className="puzzle-piece main-top-tower-placeholder-img"
                                alt=""
                            />
                        </div>
                        <div className="ladder">
                            {!castleStage.subStages.find(
                                (sub) =>
                                    sub.name === SubStage_puzzle_castle.ladder
                            )?.completed && (
                                <Draggable
                                    nodeRef={ladder_item}
                                    position={{ x: 0, y: 0 }}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                >
                                    <img
                                        ref={ladder_item}
                                        id={SubStage_puzzle_castle.ladder}
                                        src={ladder_img}
                                        className="puzzle-piece c-castle__puzzle-pieces__item ladder-img"
                                        alt=""
                                    />
                                </Draggable>
                            )}
                            <img
                                src={ladder_placeholder_img}
                                className="puzzle-piece ladder-placeholder-img"
                                alt=""
                            />
                        </div>
                        <div className="top-tower-1">
                            {castleStage.subStages.find(
                                (sub) =>
                                    sub.name ===
                                    SubStage_puzzle_castle.top_tower_1
                            )?.doneWith !==
                                SubStage_puzzle_castle.top_tower_1 && (
                                <Draggable
                                    nodeRef={top_tower_1_item}
                                    position={{ x: 0, y: 0 }}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                >
                                    <img
                                        ref={top_tower_1_item}
                                        id={SubStage_puzzle_castle.top_tower_1}
                                        src={top_tower_img}
                                        className="puzzle-piece c-castle__puzzle-pieces__item top-tower-1-img"
                                        alt=""
                                    />
                                </Draggable>
                            )}
                            <img
                                src={top_tower_placeholder_img}
                                className="puzzle-piece top-tower-placeholder-img"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="second-row" ref={row_container_2}>
                        <div className="tree-1">
                            {castleStage.subStages.find(
                                (sub) =>
                                    sub.name === SubStage_puzzle_castle.tree_1
                            )?.doneWith !== SubStage_puzzle_castle.tree_1 && (
                                <Draggable
                                    nodeRef={tree_1_item}
                                    position={{ x: 0, y: 0 }}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                >
                                    <img
                                        ref={tree_1_item}
                                        id={SubStage_puzzle_castle.tree_1}
                                        src={tree_img}
                                        className="puzzle-piece c-castle__puzzle-pieces__item tree-1-img"
                                        alt=""
                                    />
                                </Draggable>
                            )}
                            <img
                                src={tree_placeholder_img}
                                className="puzzle-piece tree-1-placeholder-img"
                                alt=""
                            />
                        </div>
                        <div className="right-door">
                            {!castleStage.subStages.find(
                                (sub) =>
                                    sub.name ===
                                    SubStage_puzzle_castle.right_door
                            )?.completed && (
                                <Draggable
                                    nodeRef={right_door_item}
                                    position={{ x: 0, y: 0 }}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                >
                                    <img
                                        ref={right_door_item}
                                        id={SubStage_puzzle_castle.right_door}
                                        src={right_door_img}
                                        className="puzzle-piece c-castle__puzzle-pieces__item right-door-img"
                                        alt=""
                                    />
                                </Draggable>
                            )}
                            <img
                                src={right_door_placeholder_img}
                                className="puzzle-piece right-door-placeholder-img"
                                alt=""
                            />
                        </div>
                        <div className="top-tower-2">
                            {castleStage.subStages.find(
                                (sub) =>
                                    sub.name ===
                                    SubStage_puzzle_castle.top_tower_2
                            )?.doneWith !==
                                SubStage_puzzle_castle.top_tower_2 && (
                                <Draggable
                                    nodeRef={top_tower_2_item}
                                    position={{ x: 0, y: 0 }}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                >
                                    <img
                                        ref={top_tower_2_item}
                                        id={SubStage_puzzle_castle.top_tower_2}
                                        src={top_tower_img}
                                        className="puzzle-piece c-castle__puzzle-pieces__item top-tower-2-img"
                                        alt=""
                                    />
                                </Draggable>
                            )}
                            <img
                                src={top_tower_placeholder_img}
                                className="puzzle-piece top-tower-2-placeholder-img"
                                alt=""
                            />
                        </div>
                        <div className="main-tower">
                            {!castleStage.subStages.find(
                                (sub) =>
                                    sub.name ===
                                    SubStage_puzzle_castle.main_tower
                            )?.completed && (
                                <Draggable
                                    nodeRef={main_tower_item}
                                    position={{ x: 0, y: 0 }}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                >
                                    <img
                                        ref={main_tower_item}
                                        id={SubStage_puzzle_castle.main_tower}
                                        src={main_tower_img}
                                        className="puzzle-piece c-castle__puzzle-pieces__item main-tower-img"
                                        alt=""
                                    />
                                </Draggable>
                            )}
                            <img
                                src={main_tower_placeholder_img}
                                className="puzzle-piece main-tower-placeholder-img"
                                alt=""
                            />
                        </div>
                        <div className="left-door">
                            {!castleStage.subStages.find(
                                (sub) =>
                                    sub.name ===
                                    SubStage_puzzle_castle.left_door
                            )?.completed && (
                                <Draggable
                                    nodeRef={left_door_item}
                                    position={{ x: 0, y: 0 }}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                >
                                    <img
                                        ref={left_door_item}
                                        id={SubStage_puzzle_castle.left_door}
                                        src={left_door_img}
                                        className="puzzle-piece c-castle__puzzle-pieces__item left-door-img"
                                        alt=""
                                    />
                                </Draggable>
                            )}
                            <img
                                src={left_door_placeholder_img}
                                className="puzzle-piece left-door-placeholder-img"
                                alt=""
                            />
                        </div>
                        <div className="tower-2">
                            {castleStage.subStages.find(
                                (sub) =>
                                    sub.name === SubStage_puzzle_castle.tower_2
                            )?.doneWith !== SubStage_puzzle_castle.tower_2 && (
                                <Draggable
                                    nodeRef={tower_2_item}
                                    position={{ x: 0, y: 0 }}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                >
                                    <img
                                        ref={tower_2_item}
                                        id={SubStage_puzzle_castle.tower_2}
                                        src={tower_img}
                                        className="puzzle-piece c-castle__puzzle-pieces__item tower-2-img"
                                        alt=""
                                    />
                                </Draggable>
                            )}
                            <img
                                src={tower_placeholder_img}
                                className="puzzle-piece tower-2-placeholder-img"
                                alt=""
                            />
                        </div>
                        <div className="tree-2">
                            {castleStage.subStages.find(
                                (sub) =>
                                    sub.name === SubStage_puzzle_castle.tree_2
                            )?.doneWith !== SubStage_puzzle_castle.tree_2 && (
                                <Draggable
                                    nodeRef={tree_2_item}
                                    position={{ x: 0, y: 0 }}
                                    onDrag={onDrag}
                                    onStop={onStop}
                                >
                                    <img
                                        ref={tree_2_item}
                                        id={SubStage_puzzle_castle.tree_2}
                                        src={tree_img}
                                        className="puzzle-piece c-castle__puzzle-pieces__item tree-2-img"
                                        alt=""
                                    />
                                </Draggable>
                            )}
                            <img
                                src={tree_placeholder_img}
                                className="puzzle-piece tree-2-placeholder-img"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="c-castle__puzzle-pieces__bottom"
                    ref={castle_container}
                >
                    <div className="tower-1" ref={tower_1_ph}>
                        {castleStage.subStages.find(
                            (sub) => sub.name === SubStage_puzzle_castle.tower_1
                        )!.completed && (
                            <img
                                src={tower_img}
                                className="puzzle-piece c-castle__puzzle-pieces__item tower-1-img"
                                alt=""
                            />
                        )}
                        <img
                            src={tower_dott_placeholder_img}
                            className="puzzle-piece tower-1-dott-placeholder-img"
                            alt=""
                        />
                    </div>
                    <div className="tower-2" ref={tower_2_ph}>
                        {castleStage.subStages.find(
                            (sub) => sub.name === SubStage_puzzle_castle.tower_2
                        )!.completed && (
                            <img
                                src={tower_img}
                                className="puzzle-piece c-castle__puzzle-pieces__item tower-2-img"
                                alt=""
                            />
                        )}
                        <img
                            src={tower_dott_placeholder_img}
                            className="puzzle-piece tower-2-dott-placeholder-img"
                            alt=""
                        />
                    </div>
                    <div className="door" ref={door_ph}>
                        {castleStage.subStages.find(
                            (sub) => sub.name === SubStage_puzzle_castle.door
                        )!.completed && (
                            <img
                                src={door_img}
                                className="puzzle-piece c-castle__puzzle-pieces__item door-img"
                                alt=""
                            />
                        )}
                        <img
                            src={door_dott_placeholder_img}
                            className="puzzle-piece door-dott-placeholder-img"
                            alt=""
                        />
                    </div>
                    <div className="triangle-ladder" ref={triangle_ladder_ph}>
                        {castleStage.subStages.find(
                            (sub) =>
                                sub.name ===
                                SubStage_puzzle_castle.triangle_ladder
                        )!.completed && (
                            <img
                                src={triangle_ladder_img}
                                className="puzzle-piece c-castle__puzzle-pieces__item triangle-ladder-img"
                                alt=""
                            />
                        )}
                        <img
                            src={triangle_ladder_dott_placeholder_img}
                            className="puzzle-piece triangle-ladder-dott-placeholder-img"
                            alt=""
                        />
                    </div>
                    <div className="main-top-tower" ref={main_top_tower_ph}>
                        {castleStage.subStages.find(
                            (sub) =>
                                sub.name ===
                                SubStage_puzzle_castle.main_top_tower
                        )!.completed && (
                            <img
                                src={main_top_tower_img}
                                className="puzzle-piece c-castle__puzzle-pieces__item main-top-tower-img"
                                alt=""
                            />
                        )}
                        <img
                            src={main_top_tower_dott_placeholder_img}
                            className="puzzle-piece main-top-tower-dott-placeholder-img"
                            alt=""
                        />
                    </div>
                    <div className="ladder" ref={ladder_ph}>
                        {castleStage.subStages.find(
                            (sub) => sub.name === SubStage_puzzle_castle.ladder
                        )!.completed && (
                            <img
                                src={ladder_img}
                                className="puzzle-piece c-castle__puzzle-pieces__item ladder-img"
                                alt=""
                            />
                        )}
                        <img
                            src={ladder_dott_placeholder_img}
                            className="puzzle-piece ladder-dott-placeholder-img"
                            alt=""
                        />
                    </div>

                    <div className="right-door" ref={right_door_ph}>
                        {castleStage.subStages.find(
                            (sub) =>
                                sub.name === SubStage_puzzle_castle.right_door
                        )!.completed && (
                            <img
                                src={right_door_img}
                                className="puzzle-piece c-castle__puzzle-pieces__item right-door-img"
                                alt=""
                            />
                        )}
                        <img
                            src={right_door_dott_placeholder_img}
                            className="puzzle-piece right-door-dott-placeholder-img"
                            alt=""
                        />
                    </div>
                    <div className="left-door" ref={left_door_ph}>
                        {castleStage.subStages.find(
                            (sub) =>
                                sub.name === SubStage_puzzle_castle.left_door
                        )!.completed && (
                            <img
                                src={left_door_img}
                                className="puzzle-piece c-castle__puzzle-pieces__item left-door-img"
                                alt=""
                            />
                        )}
                        <img
                            src={left_door_dott_placeholder_img}
                            className="puzzle-piece left-door-dott-placeholder-img"
                            alt=""
                        />
                    </div>
                    <div className="top-tower-1" ref={top_tower_1_ph}>
                        {castleStage.subStages.find(
                            (sub) =>
                                sub.name === SubStage_puzzle_castle.top_tower_1
                        )!.completed && (
                            <img
                                src={top_tower_img}
                                className="puzzle-piece c-castle__puzzle-pieces__item top-tower-1-img"
                                alt=""
                            />
                        )}
                        <img
                            src={top_tower_dott_placeholder_img}
                            className="puzzle-piece top-tower-1-dott-placeholder-img"
                            alt=""
                        />
                    </div>
                    <div className="top-tower-2" ref={top_tower_2_ph}>
                        {castleStage.subStages.find(
                            (sub) =>
                                sub.name === SubStage_puzzle_castle.top_tower_2
                        )!.completed && (
                            <img
                                src={top_tower_img}
                                className="puzzle-piece c-castle__puzzle-pieces__item top-tower-2-img"
                                alt=""
                            />
                        )}
                        <img
                            src={top_tower_dott_placeholder_img}
                            className="puzzle-piece top-tower-2-dott-placeholder-img"
                            alt=""
                        />
                    </div>
                    <div className="main-tower" ref={main_tower_ph}>
                        {castleStage.subStages.find(
                            (sub) =>
                                sub.name === SubStage_puzzle_castle.main_tower
                        )!.completed && (
                            <img
                                src={main_tower_img}
                                className="puzzle-piece c-castle__puzzle-pieces__item main-tower-img"
                                alt=""
                            />
                        )}
                        <img
                            src={main_tower_dott_placeholder_img}
                            className="puzzle-piece main-tower-dott-placeholder-img"
                            alt=""
                        />
                    </div>
                    <div className="tree-1" ref={tree_1_ph}>
                        {castleStage.subStages.find(
                            (sub) => sub.name === SubStage_puzzle_castle.tree_1
                        )!.completed && (
                            <img
                                src={tree_img}
                                className="puzzle-piece c-castle__puzzle-pieces__item tree-1-img"
                                alt=""
                            />
                        )}
                        <img
                            src={tree_dott_placeholder_img}
                            className="puzzle-piece tree-1-dott-placeholder-img"
                            alt=""
                        />
                    </div>

                    <div className="tree-2" ref={tree_2_ph}>
                        {castleStage.subStages.find(
                            (sub) => sub.name === SubStage_puzzle_castle.tree_2
                        )!.completed && (
                            <img
                                src={tree_img}
                                className="puzzle-piece c-castle__puzzle-pieces__item tree-2-img"
                                alt=""
                            />
                        )}
                        <img
                            src={tree_dott_placeholder_img}
                            className="puzzle-piece tree-2-dott-placeholder-img"
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <FooterCastle />
        </div>
    );
};
export default Castle;
