import { useEffect, useRef, useState } from "react";
import Draggable, {
    DraggableData,
    DraggableEventHandler,
} from "react-draggable";
import ferry from "src/assets/images/adventure/guardian/ferry.png";
import ferry_border from "src/assets/images/adventure/guardian/boat-border.png";
import dragg from "src/assets/svg/hand/point-hand.svg";
import checked from "src/assets/svg/check-green.svg";
import cross_red from "src/assets/svg/red-cross.svg";
import useVotoStore from "src/store";
import { translationService } from "src/services/translation.service";

const Ferry = () => {
    const ferryRef = useRef<HTMLDivElement>(null);
    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    const [pos, setPos] = useState(0);
    const [startDrag, setStartdrag] = useState(false);
    const [ferryClicked, setFerryClicked] = useState<boolean>(false);
    const [disable, setDisable] = useState(true);
    const [draggStopped, setDraggStopped] = useState(false);
    const [direction, setDirection] = useState("");

    const {
        locale,
        guardian: { stages, introStart, startMotorBoat },
        guardianStageCompletedById,
        guardianStageFinishedById,
        guardianStageStartById,
        updateIntroStart,
    } = useVotoStore();

    const {
        protectedAreaOffset,
        started,
        completed,
        finished,
        time,
        id,
        tryCount,
    } = stages[0];

    const motorboatTipsText = translationService.translate(
        "footer.guardian.motorboatTips",
        locale
    );

    useEffect(() => {
        if (
            (startMotorBoat && tryCount !== 2) ||
            (startMotorBoat && tryCount === 2)
        ) {
            guardianStageStartById(id);
        }
    }, [startMotorBoat, guardianStageStartById, id, tryCount]);

    useEffect(() => {
        if (completed) {
            setPosition({ x: -200, y: 0 });
            setDisable(true);
        }
        if (finished && !completed) {
            setPosition({ x: -800, y: 0 });
        }
    }, [
        finished,
        completed,
        guardianStageCompletedById,
        guardianStageFinishedById,
    ]);

    useEffect(() => {
        if (tryCount === 2) {
            setPosition({ x: 0, y: 0 });
        }
    }, [tryCount]);

    const handleStopDrag = (e: any, ui: DraggableData) => {
        if (started && startDrag) {
            if (
                protectedAreaOffset &&
                protectedAreaOffset < pos + ui.lastX &&
                !completed &&
                !finished
            ) {
                guardianStageCompletedById(id);
                guardianStageFinishedById(id);
                setFerryClicked(false);
            } else if (!finished) {
                setDisable(true);
                guardianStageFinishedById(id);
                setFerryClicked(false);
            }
        }
    };

    const onDrag = (e: any, ui: DraggableData) => {
        if (started) {
            setStartdrag(true);
            setDirection(ui.deltaX < 0 ? "left" : ui.deltaX > 0 ? "right" : "");
            if (!completed && !finished) {
                setFerryClicked(false);
            } else if (!finished) {
                setFerryClicked(false);
            }
        }
    };

    useEffect(() => {
        let subscriber: any;
        const ferryWidh = ferryRef.current?.clientWidth as number;
        if (started && !finished && !completed && !startDrag) {
            setDisable(false);
            subscriber = setInterval(() => {
                let posLeft = ferryRef.current?.offsetLeft as number;
                if (protectedAreaOffset && posLeft) {
                    setPos(posLeft);
                }
            }, 100);
        }

        if (
            started &&
            protectedAreaOffset &&
            pos !== 0 &&
            pos + ferryWidh + 80 <= protectedAreaOffset
        ) {
            clearInterval(subscriber);
        }

        return () => {
            clearInterval(subscriber);
        };
    }, [started, pos, completed, finished, protectedAreaOffset, startDrag]);

    const onClickSafeArea = (e: React.MouseEvent) => {
        if (
            protectedAreaOffset &&
            e.clientX > protectedAreaOffset + 100 &&
            !finished &&
            !completed &&
            ferryClicked &&
            !finished
        ) {
            guardianStageCompletedById(id);
            guardianStageFinishedById(id);
            setDisable(true);
            setFerryClicked(false);
        }
    };

    return (
        <div className={`stage-ferry ${completed ? "done" : ""}`}>
            {/* {protectedAreaOffset &&
            ferryRef.current &&
            pos !== 0 &&
            pos <= protectedAreaOffset + ferryRef.current.clientWidth &&
            !finished ? (
                <span className="alert-bg"></span>
            ) : (
                ""
            )} */}

            {started && !finished && (
                <div className="dragg-hand">
                    <img src={dragg} alt="" />
                    <p>{motorboatTipsText}</p>
                </div>
            )}

            <Draggable
                onDrag={onDrag}
                onMouseDown={(e) => {
                    started && !completed && !finished && setFerryClicked(true);
                }}
                onStop={handleStopDrag}
                nodeRef={ferryRef}
                // disabled={disable}
                // axis="x"
                position={{ x: position.x, y: position.y }}
                grid={[20, 20]}
                bounds={{ top: 0, bottom: 30 }}
            >
                <div
                    onMouseLeave={() => console.log("mouse leave")}
                    ref={ferryRef}
                    className="stage-ferry__dragg-box"
                    style={
                        started && !draggStopped && !finished
                            ? {
                                  animation: `ferry-move ${6 + time}s linear`,
                              }
                            : {}
                    }
                >
                    {completed && finished && (
                        <img
                            className="stage-ferry__checked"
                            src={checked}
                            alt=""
                        />
                    )}
                    {finished && !completed && (
                        <img
                            className="stage-ferry__checked"
                            src={cross_red}
                            alt=""
                        />
                    )}
                    <img
                        className="stage-ferry__image"
                        style={
                            direction === "right"
                                ? { transform: "rotateY(0deg)" }
                                : {}
                        }
                        src={ferry}
                        alt=""
                    />
                    {ferryClicked ? (
                        <img
                            className="stage-ferry__image stage-ferry__image-border"
                            src={ferry_border}
                            alt=""
                        />
                    ) : (
                        ""
                    )}
                </div>
            </Draggable>
            <span
                onClick={onClickSafeArea}
                className={`un-safe-area ${
                    (!ferryClicked || disable) && "un-safe-area__hidden"
                }`}
            ></span>
        </div>
    );
};
export default Ferry;
