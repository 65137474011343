import { ReactComponent as QuizFrame } from "src/assets/svg/quiz/quiz-frame.svg";
import QuestionQuiz from "src/compnents/quiz/question/Question.quiz";
import Spinner from "src/compnents/spinner/Spinner";
import useVotoStore from "src/store";
import { showAreaBg } from "src/utils/helpers";
import { useEffect, useRef } from "react";
import PlayerSection from "src/compnents/quiz/player/Player";

const TwoPlayerQuiz = () => {
    const {
        quiz: {
            players,
            countdown,
            activePlayer,
            selectedQuestion,
            winner,
            secondPlayerLastTry,
            showFeedback,
        },
        handleAfterQuestionAnswerd,
        updateRound,
    } = useVotoStore();

    const player1 = players[0];
    const player2 = players[1];

    useEffect(() => {
        if (selectedQuestion.answered && !countdown.state) {
            handleAfterQuestionAnswerd(selectedQuestion);
        }
    }, [
        countdown,
        selectedQuestion.answered,
        winner,
        secondPlayerLastTry,
        showFeedback,
    ]);

    useEffect(() => {
        if (
            winner.length === 0 ||
            (winner.length === 1 && !secondPlayerLastTry)
        ) {
            setTimeout(() => {
                if (showFeedback) {
                    updateRound(false, true);
                }
            }, 0);
        }
    }, [winner, secondPlayerLastTry, showFeedback]);

    return (
        <>
            <div className="two-player-quiz">
                <QuizFrame className="quiz-frame" />
                <Spinner />
                <div className="two-player-quiz__players">
                    <div
                        className={`two-player-quiz__players-1 ${
                            player1.id !== activePlayer
                                ? "bg-disactive"
                                : "bg-dark-blue"
                            // : "bg-light-blue"
                        }`}
                    >
                        <PlayerSection
                            player={player1}
                            active={player1.id === activePlayer}
                        />
                    </div>
                    <div
                        className={`two-player-quiz__players-2 ${
                            player2.id !== activePlayer
                                ? "bg-disactive"
                                : "bg-dark-blue"
                        }`}
                    >
                        <PlayerSection
                            player={player2}
                            active={player2.id === activePlayer}
                        />
                    </div>
                </div>
                <div className="two-player-quiz__questions">
                    <div
                        className={`two-player-quiz__questions-1 ${
                            player1.id !== activePlayer
                                ? "bg-disactive"
                                : showAreaBg(selectedQuestion.area)
                        }`}
                    >
                        {player1.id === activePlayer && (
                            <>
                                <QuestionQuiz player={player1} />
                            </>
                        )}
                    </div>
                    <div
                        className={`two-player-quiz__questions-2 ${
                            player2.id !== activePlayer
                                ? "bg-disactive"
                                : showAreaBg(selectedQuestion.area)
                        }`}
                    >
                        {player2.id === activePlayer && (
                            <>
                                <QuestionQuiz player={player2} />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default TwoPlayerQuiz;
