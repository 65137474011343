import { useEffect, useRef, useState } from "react";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import waveBottomAnimation from "src/assets/animations/Waves_Bottom.json";
import waveTopAnimation from "src/assets/animations/Waves_Top.json";
import amber_stone from "src/assets/svg/treasures/amber-stone.svg";
import shellsnack from "src/assets/svg/treasures/shellsnacka.svg";
import conch from "src/assets/svg/treasures/conch.svg";
import fossil from "src/assets/svg/treasures/fossil.svg";
import vassen from "src/assets/svg/treasures/vassen.svg";
import empty_coffin from "src/assets/svg/treasures/empty-coffin.svg";
import FooterTreasures from "src/compnents/footer/treasures/Footer.treasures";
import useVotoStore from "src/store";
import { Stage_3, SubStage_find_treasures } from "src/types/index.d";
import Navbar from "src/compnents/navbar/Navbar";
import Draggable, { DraggableData } from "react-draggable";

const TreasuresPage = () => {
    const topWaveRef = useRef<LottieRefCurrentProps>(null);
    const bottomWaveRef = useRef<LottieRefCurrentProps>(null);

    const amberStoneRef = useRef<HTMLImageElement>(null);
    const shellsnackRef = useRef<HTMLImageElement>(null);
    const conchRef = useRef<HTMLImageElement>(null);
    const fossilRef = useRef<HTMLImageElement>(null);
    const vassenRef = useRef<HTMLImageElement>(null);
    const coffinRef = useRef<HTMLImageElement>(null);

    const [currentFrame, setCurrentFrame] = useState(0);
    const [startDrag, setStartDrag] = useState(false);
    const [dragItem, setDragItem] = useState<string>("");

    const [coffinPosition, setCoffinPosition] = useState({
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    });

    const {
        vacation: { stages },
        vacationStageStartById,
        vacationSubstageCompletedByName,
        vacationStageCompletedById,
        vacationStageFinishedById,
        setTotalFrame,
    } = useVotoStore();
    const {
        id,
        tryCount,
        finished,
        completed,
        started,
        subStages,
        totalFrame,
    } = stages[3];

    useEffect(() => {
        if (!started) {
            vacationStageStartById(id);
        }
        coffinRef.current &&
            setCoffinPosition({
                left: coffinRef.current.offsetLeft,
                top: coffinRef.current.offsetTop,
                bottom:
                    coffinRef.current.offsetTop +
                    coffinRef.current.offsetHeight,
                right:
                    coffinRef.current.offsetLeft +
                    coffinRef.current.offsetWidth,
            });
        return () => {};
    }, [started, id, vacationStageStartById]);

    useEffect(() => {
        if (subStages.every((subS) => subS.completed === true)) {
            if (!completed) {
                vacationStageCompletedById(id);
                vacationStageFinishedById(id);
            }
        }

        return () => {};
    }, [
        id,
        subStages,
        vacationStageCompletedById,
        completed,
        vacationStageFinishedById,
    ]);

    useEffect(() => {
        if (started && !finished && !completed) {
            topWaveRef.current?.goToAndPlay(0);
            bottomWaveRef.current?.goToAndPlay(0);
            if (
                topWaveRef.current?.animationItem &&
                topWaveRef.current?.animationItem?.totalFrames !== 0
            ) {
                setTotalFrame(topWaveRef.current?.animationItem?.totalFrames);
            }
        }
        return () => {};
    }, [started, finished, completed, setTotalFrame]);

    useEffect(() => {
        const subscriber = setInterval(() => {
            topWaveRef.current?.animationItem &&
                setCurrentFrame(topWaveRef.current?.animationItem.currentFrame);
        }, 180);
        return () => {
            clearInterval(subscriber);
        };
    }, []);

    const removeItem = (item: HTMLImageElement) => {
        item.id && vacationSubstageCompletedByName(id, item.id);
    };

    const coffinHover = () => {
        if (coffinRef.current) {
            coffinRef.current.style.transform = "scale(1.2)";
        }
    };

    const coffinUnHover = () => {
        if (coffinRef.current) {
            coffinRef.current.style.transform = "scale(1)";
        }
    };

    const onStop = (e: any, ui: DraggableData) => {
        startDrag && setStartDrag(false);
        setDragItem("");
        coffinUnHover();
        const selectedItem = e.target;
        if (startDrag) {
            selectedItem.style.opacity = 1;
            selectedItem.style.zIndex = 0;
        }
        if (Object.values(SubStage_find_treasures).includes(selectedItem.id)) {
            const centerLeftItem =
                e.target.offsetLeft + ui.lastX + e.target.offsetWidth / 2;
            const centerTopItem =
                e.target.offsetTop + ui.lastY + e.target.offsetHeight / 2;

            if (coffinPosition.left !== 0) {
                if (
                    centerLeftItem >= coffinPosition.left &&
                    centerLeftItem <= coffinPosition.right &&
                    centerTopItem >= coffinPosition.top &&
                    centerTopItem <= coffinPosition.bottom
                ) {
                    removeItem(e.target);
                }
            }
        }
    };

    const onDrag = (e: any, ui: DraggableData) => {
        !startDrag &&
            dragItem === SubStage_find_treasures.amber_stone &&
            setStartDrag(true);
        if (Object.values(SubStage_find_treasures).includes(e.target.id)) {
            e.target.style.opacity = 0.6;
            e.target.style.zIndex = 3;
            setDragItem(e.target.id);

            const centerLeftItem =
                e.target.offsetLeft + ui.lastX + e.target.offsetWidth / 2;
            const centerTopItem =
                e.target.offsetTop + ui.lastY + e.target.offsetHeight / 2;

            if (coffinPosition.left !== 0) {
                if (
                    centerLeftItem >= coffinPosition.left &&
                    centerLeftItem <= coffinPosition.right &&
                    centerTopItem >= coffinPosition.top &&
                    centerTopItem <= coffinPosition.bottom
                ) {
                    coffinHover();
                } else {
                    coffinUnHover();
                }
            }
        }
    };

    return (
        <div className="c-treasures">
            <Navbar showHome={finished ? true : false} />
            <Lottie
                lottieRef={bottomWaveRef}
                className="c-treasures__wave-bottom"
                animationData={waveBottomAnimation}
                loop={false}
                autoplay={false}
            />
            {!subStages.find(
                (sub) => sub.name === SubStage_find_treasures.amber_stone
            )!.completed && (
                <>
                    {dragItem !== SubStage_find_treasures.amber_stone && (
                        <p
                            className={`c-treasures__amber_stone__points c-treasures__points ${
                                totalFrame &&
                                currentFrame > totalFrame / 10 &&
                                currentFrame < totalFrame * 0.6
                                    ? "c-treasures__treasure--appear"
                                    : ""
                            }`}
                        >
                            +{" "}
                            {
                                subStages.find(
                                    (sub) =>
                                        sub.name ===
                                        SubStage_find_treasures.amber_stone
                                )!.point
                            }
                        </p>
                    )}
                    <Draggable
                        nodeRef={amberStoneRef}
                        onDrag={onDrag}
                        onStop={onStop}
                        position={{ x: 0, y: 0 }}
                    >
                        <img
                            ref={amberStoneRef}
                            id={SubStage_find_treasures.amber_stone}
                            className={`c-treasures__amber_stone c-treasures__treasure ${
                                totalFrame &&
                                currentFrame > totalFrame / 10 &&
                                currentFrame < totalFrame * 0.6
                                    ? "c-treasures__treasure--appear"
                                    : ""
                            }`}
                            src={amber_stone}
                            alt=""
                        />
                    </Draggable>
                </>
            )}
            {!subStages.find(
                (sub) => sub.name === SubStage_find_treasures.shellsnack
            )!.completed && (
                <>
                    {dragItem !== SubStage_find_treasures.shellsnack && (
                        <p
                            className={`c-treasures__shellsnack__points c-treasures__points ${
                                totalFrame &&
                                currentFrame > totalFrame / 10 &&
                                currentFrame < totalFrame * 0.8
                                    ? "c-treasures__treasure--appear"
                                    : ""
                            }`}
                        >
                            +{" "}
                            {
                                subStages.find(
                                    (sub) =>
                                        sub.name ===
                                        SubStage_find_treasures.shellsnack
                                )!.point
                            }
                        </p>
                    )}
                    <Draggable
                        nodeRef={shellsnackRef}
                        onDrag={onDrag}
                        onStop={onStop}
                        position={{ x: 0, y: 0 }}
                    >
                        <img
                            ref={shellsnackRef}
                            id={SubStage_find_treasures.shellsnack}
                            className={`c-treasures__shellsnack c-treasures__treasure ${
                                totalFrame &&
                                currentFrame > totalFrame / 10 &&
                                currentFrame < totalFrame * 0.8
                                    ? "c-treasures__treasure--appear"
                                    : ""
                            }`}
                            src={shellsnack}
                            alt=""
                        />
                    </Draggable>
                </>
            )}
            {!subStages.find(
                (sub) => sub.name === SubStage_find_treasures.conch
            )!.completed && (
                <>
                    {dragItem !== SubStage_find_treasures.conch && (
                        <p
                            className={`c-treasures__conch__points c-treasures__points ${
                                totalFrame &&
                                currentFrame > totalFrame / 10 &&
                                currentFrame < totalFrame * 0.6
                                    ? "c-treasures__treasure--appear"
                                    : ""
                            }`}
                        >
                            +{" "}
                            {
                                subStages.find(
                                    (sub) =>
                                        sub.name ===
                                        SubStage_find_treasures.conch
                                )!.point
                            }
                        </p>
                    )}
                    <Draggable
                        nodeRef={conchRef}
                        onDrag={onDrag}
                        onStop={onStop}
                        position={{ x: 0, y: 0 }}
                    >
                        <img
                            ref={conchRef}
                            id={SubStage_find_treasures.conch}
                            className={`c-treasures__conch c-treasures__treasure ${
                                totalFrame &&
                                currentFrame > totalFrame / 10 &&
                                currentFrame < totalFrame * 0.6
                                    ? "c-treasures__treasure--appear"
                                    : ""
                            }`}
                            src={conch}
                            alt=""
                        />
                    </Draggable>
                </>
            )}
            {!subStages.find(
                (sub) => sub.name === SubStage_find_treasures.fossil
            )!.completed && (
                <>
                    {dragItem !== SubStage_find_treasures.fossil && (
                        <p
                            className={`c-treasures__fossil__points c-treasures__points ${
                                totalFrame &&
                                currentFrame > totalFrame / 10 &&
                                currentFrame < totalFrame * 0.8
                                    ? "c-treasures__treasure--appear"
                                    : ""
                            }`}
                        >
                            +{" "}
                            {
                                subStages.find(
                                    (sub) =>
                                        sub.name ===
                                        SubStage_find_treasures.fossil
                                )!.point
                            }
                        </p>
                    )}
                    <Draggable
                        nodeRef={fossilRef}
                        onDrag={onDrag}
                        onStop={onStop}
                        position={{ x: 0, y: 0 }}
                    >
                        <img
                            ref={fossilRef}
                            id={SubStage_find_treasures.fossil}
                            className={`c-treasures__fossil c-treasures__treasure ${
                                totalFrame &&
                                currentFrame > totalFrame / 10 &&
                                currentFrame < totalFrame * 0.8
                                    ? "c-treasures__treasure--appear"
                                    : ""
                            }`}
                            src={fossil}
                            alt=""
                        />
                    </Draggable>
                </>
            )}
            {!subStages.find(
                (sub) => sub.name === SubStage_find_treasures.vassen
            )!.completed && (
                <>
                    {dragItem !== SubStage_find_treasures.vassen && (
                        <p
                            className={`c-treasures__vassen__points c-treasures__points ${
                                totalFrame &&
                                currentFrame > totalFrame / 10 &&
                                currentFrame < totalFrame * 0.4
                                    ? "c-treasures__treasure--appear"
                                    : ""
                            }`}
                        >
                            +{" "}
                            {
                                subStages.find(
                                    (sub) =>
                                        sub.name ===
                                        SubStage_find_treasures.vassen
                                )!.point
                            }
                        </p>
                    )}
                    <Draggable
                        nodeRef={vassenRef}
                        onDrag={onDrag}
                        onStop={onStop}
                        position={{ x: 0, y: 0 }}
                    >
                        <img
                            ref={vassenRef}
                            id={SubStage_find_treasures.vassen}
                            className={`c-treasures__vassen c-treasures__treasure ${
                                totalFrame &&
                                currentFrame > totalFrame / 10 &&
                                currentFrame < totalFrame * 0.4
                                    ? "c-treasures__treasure--appear"
                                    : ""
                            }`}
                            src={vassen}
                            alt=""
                        />
                    </Draggable>
                </>
            )}
            <img
                ref={coffinRef}
                className="c-treasures__empty-coffin"
                src={empty_coffin}
                alt=""
            />
            <div className="c-treasures__empty-coffin__treasure">
                {subStages.find(
                    (sub) => sub.name === SubStage_find_treasures.vassen
                )!.completed && (
                    <img
                        className={`c-treasures__empty-coffin__treasure__vassen`}
                        src={vassen}
                        alt=""
                    />
                )}
                {subStages.find(
                    (sub) => sub.name === SubStage_find_treasures.fossil
                )!.completed && (
                    <img
                        className={`c-treasures__empty-coffin__treasure__fossil`}
                        src={fossil}
                        alt=""
                    />
                )}
                {subStages.find(
                    (sub) => sub.name === SubStage_find_treasures.amber_stone
                )!.completed && (
                    <img
                        className={`c-treasures__empty-coffin__treasure__amber_stone`}
                        src={amber_stone}
                        alt=""
                    />
                )}
                {subStages.find(
                    (sub) => sub.name === SubStage_find_treasures.shellsnack
                )!.completed && (
                    <img
                        className={`c-treasures__empty-coffin__treasure__shellsnack`}
                        src={shellsnack}
                        alt=""
                    />
                )}
                {subStages.find(
                    (sub) => sub.name === SubStage_find_treasures.conch
                )!.completed && (
                    <img
                        className={`c-treasures__empty-coffin__treasure__conch`}
                        src={conch}
                        alt=""
                    />
                )}
            </div>

            <Lottie
                lottieRef={topWaveRef}
                className="c-treasures__wave-top"
                animationData={waveTopAnimation}
                loop={false}
                autoplay={false}
            />
            <FooterTreasures />
        </div>
    );
};
export default TreasuresPage;
