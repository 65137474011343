import { FC } from "react";
import useVotoStore from "src/store";
import "./footer.quiz.scss";
import { NextButton, TryAgainButton } from "src/elemets/Buttons";
import { translationService } from "src/services/translation.service";
import { ReactComponent as SpinnerBtn } from "src/assets/svg/quiz/spinner/spin-btn.svg";
import { routes } from "src/routes/routes";
import { useNavigate } from "react-router-dom";
import { getTranslatedName } from "src/utils/helpers";
import { quiz_question_number } from "src/constants";

type QuizFooterProps = {
    isWinPage?: boolean;
};

const QuizFooter: FC<QuizFooterProps> = ({ isWinPage = false }) => {
    const navigate = useNavigate();
    const {
        locale,
        quiz: {
            players,
            activePlayer,
            readyForSpin,
            spinning,
            round,
            winner,
            playerCount,
            secondPlayerLastTry,
            gameFinish,
            countdown,
            nextPlayer,
        },
        updateRound,
        resetQuiz,
        setWellcomeQuizIsDone,
        forceHandleAfterQuestionAnswerd,
    } = useVotoStore();

    const spinTurnText = translationService.translate(
        "quizPage.spinTurn",
        locale
    );
    const winnerText = translationService.translate("quizPage.winner", locale);

    const handleSpin = () => {
        if (!spinning) {
            const spinnerRef = document.getElementsByClassName(
                "spin"
            )[0] as HTMLElement;
            spinnerRef.click();
            if (!round.start) {
                updateRound(true, false);
            }
        }
    };

    const nextText = translationService.translate("shared.next", locale);
    const andText = translationService.translate("shared.and", locale);
    const spinText = translationService.translate("quizPage.spin", locale);
    const tryAgainText = translationService.translate(
        "shared.tryAgain",
        locale
    );

    const handleResetQuiz = () => {
        resetQuiz();
        setWellcomeQuizIsDone(true);
        navigate(routes.selectPlayer);
    };

    const showWinnerName = () => {
        if (winner.length === 1) {
            return getTranslatedName(
                players.find((p) => p.id === winner[0])!.name,
                locale
            );
        }
        if (winner.length === 2) {
            return (
                getTranslatedName(
                    players.find((p) => p.id === winner[0])!.name,
                    locale
                ) +
                ` ${andText} ` +
                getTranslatedName(
                    players.find((p) => p.id === winner[1])!.name,
                    locale
                )
            );
        }
    };

    const quizFooterText = () => {
        if (gameFinish) {
            return "";
        }
        if (!gameFinish && playerCount === 2) {
            if (
                players[0].points === quiz_question_number &&
                !secondPlayerLastTry
            ) {
                return (
                    getTranslatedName(players[1]!.name, locale) +
                    ", " +
                    spinTurnText
                );
            }
        }

        return (
            getTranslatedName(
                players.find((p) => p.id === nextPlayer)!.name,
                locale
            ) +
            ", " +
            spinTurnText
        );
    };

    return (
        <nav className="voto-footer__quiz">
            <h3
                className={`voto-footer__quiz-text blue-text ${
                    isWinPage
                        ? ""
                        : (playerCount === 2 && nextPlayer === 2) ||
                          (players[0].points === quiz_question_number &&
                              !secondPlayerLastTry)
                        ? "right-align"
                        : ""
                }`}
            >
                {isWinPage
                    ? winnerText + " " + showWinnerName()
                    : quizFooterText()}
            </h3>
            {isWinPage ? (
                <div className="voto-footer__share">
                    <div className="two-center-btns">
                        <TryAgainButton
                            text={tryAgainText}
                            onclick={handleResetQuiz}
                            customClass="left-button"
                        />
                        <NextButton
                            text={nextText}
                            link={routes.adventure}
                            customClass="right-button"
                        />
                    </div>
                </div>
            ) : (
                <div
                    className={`voto-footer__quiz-spin ${
                        readyForSpin && !gameFinish ? "pulsing" : "disactive"
                    }`}
                    onClick={() => {
                        if (countdown.state) {
                            forceHandleAfterQuestionAnswerd();
                        }
                        handleSpin();
                    }}
                >
                    <SpinnerBtn className={`voto-footer__quiz-spin-btn`} />
                    <h3 className={`voto-footer__quiz-spin-text white-text`}>
                        {spinText}
                    </h3>
                </div>
            )}
        </nav>
    );
};
export default QuizFooter;
