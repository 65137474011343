import BaseBackground from "src/compnents/backgrounds/base/base-background";
import QuizFooter from "src/compnents/footer/quiz/Quiz.footer";
import Navbar from "src/compnents/navbar/Navbar";
import useVotoStore from "src/store";
import firework_sound_1 from "src/assets/sounds/fireworking/FireWorks-Double-A.mp3";
import firework_sound_2 from "src/assets/sounds/fireworking/FireWorks-Rocket-Whistles.mp3";
import firework_sound_3 from "src/assets/sounds/fireworking/FireWorks-Single.mp3";
import firework_sound_4 from "src/assets/sounds/fireworking/FireWorks-Single-B.mp3";
import firework_sound_5 from "src/assets/sounds/fireworking/Fireworks-Crackling.mp3";
import firework_red from "src/assets/svg/firework-red.svg";
import firework_blue from "src/assets/svg/firework-blue.svg";
import firework_green from "src/assets/svg/firework-green.svg";
import crown_icon from "src/assets/svg/quiz/crown.svg";
import { getTranslatedName } from "src/utils/helpers";

const WinPage = () => {
    const {
        quiz: { players, winner, playerCount },
        locale,
    } = useVotoStore();

    return (
        <div className="voto__win-quiz c-voto-page">
            <div className="voto__win-quiz__main dark-background-overlay">
                <Navbar showHome={true} />
                <BaseBackground />
                <img src={firework_red} className="firework firework__red" />
                <img
                    src={firework_green}
                    className="firework firework__green"
                />
                <img src={firework_blue} className="firework firework__blue" />
                <img
                    src={firework_blue}
                    className="firework firework__blue-2"
                />
                <audio src={firework_sound_1} autoPlay={true} loop={true} />
                <audio src={firework_sound_2} autoPlay={true} loop={true} />
                <audio src={firework_sound_3} autoPlay={true} loop={true} />
                <audio src={firework_sound_4} autoPlay={true} loop={true} />
                <audio src={firework_sound_5} autoPlay={true} loop={true} />

                <div
                    className={`voto__win-quiz__players ${
                        playerCount === 1 ? "align-center" : ""
                    }`}
                >
                    <div
                        className={`voto__win-quiz__players-i ${
                            !winner.includes(players[0].id) ? "looser" : ""
                        }`}
                    >
                        {winner.includes(players[0].id) && (
                            <img
                                className="winner-crown"
                                src={crown_icon}
                                alt="crown"
                            />
                        )}
                        {!!players[0]!.avatar ? (
                            <img
                                className="avatar"
                                src={require(`src/assets/svg/quiz/avatars/${
                                    players[0]!.avatar
                                }.svg`)}
                                alt={players[0]!.name}
                            />
                        ) : (
                            ""
                        )}
                        <h3 className="white-text">
                            {getTranslatedName(players[0]!.name, locale)}
                        </h3>
                    </div>
                    {playerCount !== 1 && (
                        <>
                            <h3 className="white-text">VS</h3>
                            <div
                                className={`voto__win-quiz__players-i ${
                                    !winner.includes(players[1].id) ? "looser" : ""
                                }`}
                            >
                                {winner.includes(players[1].id) && (
                                    <img
                                        className="winner-crown"
                                        src={crown_icon}
                                        alt="crown"
                                    />
                                )}
                                {!!players[1]!.avatar ? (
                                    <img
                                        className="avatar"
                                        src={require(`src/assets/svg/quiz/avatars/${
                                            players[1]!.avatar
                                        }.svg`)}
                                        alt={players[1]!.name}
                                    />
                                ) : (
                                    ""
                                )}
                                <h3 className="white-text">
                                    {getTranslatedName(
                                        players[1]!.name,
                                        locale
                                    )}
                                </h3>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <QuizFooter isWinPage={true} />
        </div>
    );
};
export default WinPage;
