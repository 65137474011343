import { Suspense } from "react";
import { Link } from "react-router-dom";
import guardian_img from "src/assets/images/adventure/guardian.png";
import vacation_img from "src/assets/images/adventure/vacation.png";
import submarine_img from "src/assets/images/adventure/submarine.png";
import havquiz_img from "src/assets/images/adventure/havquiz.png";
import { translationService } from "src/services/translation.service";
import useVotoStore from "src/store";
import { routes } from "src/routes/routes";

const AdventureStages = () => {
    const { locale, username, submarine, vacation } = useVotoStore();

    const titleText = translationService.translate(
        "adventurePage.stages.title",
        locale
    );
    const submarineStageText = translationService.translate(
        "adventurePage.stages.submarine",
        locale
    );
    const guardianStageText = translationService.translate(
        "adventurePage.stages.guardian",
        locale
    );
    const vacationStageText = translationService.translate(
        "adventurePage.stages.summer",
        locale
    );
    const quizStageText = translationService.translate(
        "adventurePage.stages.quiz",
        locale
    );

    return (
        <Suspense fallback={<>Loading...</>}>
            <div className="c-adventure__stages">
                <h2 className="title">{titleText}</h2>
                <div className="adventures-list">
                    <Link to={routes.submarine} className="adventure">
                        <img
                            className="adventure-image"
                            src={submarine_img}
                            alt="kid guardian"
                        />
                        <h5 className="adventure-name">{submarineStageText}</h5>
                    </Link>
                    <Link to="/guardian" className="adventure">
                        <img
                            className="adventure-image"
                            src={guardian_img}
                            alt="kid guardian"
                        />
                        <h5 className="adventure-name">{guardianStageText}</h5>
                    </Link>
                    <Link
                        to={
                            vacation.bagVideoSeen
                                ? routes.beach
                                : routes.packupHome
                        }
                        className="adventure"
                    >
                        <img
                            className="adventure-image"
                            src={vacation_img}
                            alt="kid guardian"
                        />
                        <h5 className="adventure-name">{vacationStageText}</h5>
                    </Link>
                    <Link to={routes.selectPlayer} className="adventure">
                        <img
                            className="adventure-image"
                            src={havquiz_img}
                            alt="quiz module"
                        />
                        <h5 className="adventure-name">{quizStageText}</h5>
                    </Link>
                </div>
            </div>
        </Suspense>
    );
};

export default AdventureStages;
