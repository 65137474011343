import { Player } from "src/types/index.d";
import "./question.quiz.scss";
import { useEffect, useState } from "react";
import { showAreaBg } from "src/utils/helpers";
import useVotoStore from "src/store";
import { AreaSelected } from "./Selected.area";
import { SectionQuestion } from "./Selected.question";
import Feedback from "./Feedback";

const QuestionQuiz = ({ player }: { player: Player }) => {
    const {
        quiz: { selectedQuestion, showFeedback, gameFinish },
        setSelectedQuestion,
    } = useVotoStore();
    const [filliped, setFilliped] = useState(false);

    const flipCart = () => {
        if (!Object.keys(selectedQuestion.question).length) {
            setSelectedQuestion(selectedQuestion.area, player.id);
        }
        setFilliped(!filliped);
    };

    useEffect(() => {
        if (!Object.keys(selectedQuestion.question).length) {
            setFilliped(false);
        }
    }, [selectedQuestion]);

    useEffect(() => {
        if (selectedQuestion.area && !filliped) {
            setTimeout(() => {
                flipCart();
            }, 1500);
        }
    }, [selectedQuestion.area]);

    return (
        <div className="c-voto__question">
            <div
                className={`c-voto__question-sides  ${
                    filliped ? "flipped" : ""
                }`}
            >
                <div
                    className={`c-voto__question-sides__side1 ${showAreaBg(
                        selectedQuestion.area
                    )}`}
                >
                    <AreaSelected area={selectedQuestion.area} />
                </div>
                <div className="c-voto__question-sides__side2">
                    {selectedQuestion.question && !showFeedback && (
                        <SectionQuestion
                            area={selectedQuestion.area}
                            question={selectedQuestion.question}
                            playerId={player.id}
                        />
                    )}
                    {selectedQuestion.question &&
                        selectedQuestion.answered &&
                        showFeedback && (
                            <Feedback selectedQuestion={selectedQuestion} />
                        )}
                </div>
            </div>
        </div>
    );
};
export default QuestionQuiz;
