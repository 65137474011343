import { soundsSrcData } from "../db/sounds";

class AudioService {
    public audios: Object;

    constructor() {
        this.audios = {};
        this.getAudios();
    }

    getAudios() {
        this.audios = soundsSrcData;
    }

    public getAudioSrc = (keys: string, locale: string): string => {
        const audioSrc: any = keys
            .concat("." + locale)
            .split(".")
            .reduce((current: any, key) => current[key], this.audios);

        return audioSrc;
    };
}

export const audioService = new AudioService();
