import { StateCreator, create } from "zustand";
import { devtools } from "zustand/middleware";
import produce from "immer";
import { VotoProps, VotoState } from "src/types/index.d";
import { guardianSlice } from "./guardian.slice/guardian.slice";
import { submarineSlice } from "./submarine.slice/submarine.slice";
import { quizSlice } from "./quiz.slice/quiz.slice";
import { baseSlice } from "./base.slice/base.slice";
import { initialState } from "./initial.state";
import { vacationSlice } from "./vacation/vacation.slice";

export const useVotoStore = create<VotoState, any>(
    devtools((set) => ({
        ...initialState,
        //@ts-ignore
        ...guardianSlice(set),
        //@ts-ignore
        ...baseSlice(set),
        //@ts-ignore
        ...submarineSlice(set),
        //@ts-ignore
        ...vacationSlice(set),
        //@ts-ignore
        ...quizSlice(set),
        resetState: () =>
            set(
                produce((state: VotoProps) => (state = initialState)),
                false,
                "Reset Game"
            ),
    }))
);

export default useVotoStore;
