type Routes = {
    home: string;
    endPage: string;
    name: string;
    guide: string;
    adventure: string;
    submarine: string;
    guardian: string;
    packupHome: string;
    packupPreBeach: string;
    packupBeach: string;
    bagVideo: string;
    beach: string;
    snorkle: string;
    castle: string;
    treasures: string;
    selectPlayer: string;
    quiz: string;
    quizWinner: string;
    test: string;
};

export const routes: Routes = {
    home: "/",
    endPage: "/end-page",
    name: "/name",
    guide: "/guide",
    adventure: "/adventure",
    submarine: "/submarine",
    guardian: "/guardian",
    packupHome: "/packup-home",
    packupPreBeach: "/packup-pre-beach",
    packupBeach: "/packup-beach",
    bagVideo: "/bag-video",
    beach: "/beach",
    snorkle: "/snorkle",
    castle: "/castle",
    treasures: "/treasures",
    selectPlayer: "/select-player",
    quiz: "/quiz",
    quizWinner: "/quiz-winner",
    test: "/test",
};
