import { FC } from "react";
import useVotoStore from "src/store";
import "./footer.guardian.scss";
import Clock from "../clock/Clock";
import PinGuardian from "../pin/Pin-guardian";
import { Link } from "react-router-dom";
import guardian_thumbnail from "src/assets/svg/guardian-thumbnail.svg";
import try_again_icon from "src/assets/svg/buttons-blue/tray-again-white.svg";
import right_arrow from "src/assets/svg/buttons-blue/arrow-right-white.svg";
import { ReactComponent as SaveHavetBg } from "src/assets/svg/buttons-blue/save-havet-bg.svg";
import { ReactComponent as NextBg } from "src/assets/svg/buttons-blue/next-bg.svg";
import { ReactComponent as TryAgainBg } from "src/assets/svg/buttons-blue/try-again-bg.svg";
import PointsGuardian from "../points/Points";
import { translationService } from "src/services/translation.service";
import { TryAgainButton, NextButton } from "src/elemets/Buttons";

const FooterGuraidan: FC = () => {
    const {
        locale,
        guardianStageStartById,
        guardian: {
            stages,
            activeStage,
            started,
            wellcomeSpeechIsDone,
            finished,
            saveSeaIntroDone,
            motorboatSlut,
            utslappSlut,
            trashSlut,
            introStart,
            audioIsPlaying,
            loose,
            alert,
        },
        guardianStart,
        tryAgain,
        setActiveStage,
        setAlert,
        setLoose,
        updateIntroStart,
    } = useVotoStore();

    const currentStage = stages[Number(activeStage) - 1];

    const mainTitleText = translationService.translate(
        "footer.guardian.mainTitle",
        locale
    );
    const motorboatTaskText = translationService.translate(
        "footer.guardian.motorboatTask",
        locale
    );
    const motorboatTipsText = translationService.translate(
        "footer.guardian.motorboatTips",
        locale
    );
    const saveSeaText = translationService.translate(
        "footer.guardian.saveSea",
        locale
    );
    const cargoTaskText = translationService.translate(
        "footer.guardian.cargoTask",
        locale
    );
    const cargoTipsText = translationService.translate(
        "footer.guardian.cargoTips",
        locale
    );
    const trashTaskText = translationService.translate(
        "footer.guardian.trashTask",
        locale
    );
    const trashTipsText = translationService.translate(
        "footer.guardian.trashTips",
        locale
    );
    const trawlingTaskText = translationService.translate(
        "footer.guardian.trawlingTask",
        locale
    );
    const trawlingTipsText = translationService.translate(
        "footer.guardian.trawlingTips",
        locale
    );
    const tryAgainText = translationService.translate(
        "shared.tryAgain",
        locale
    );
    const nextText = translationService.translate("shared.next", locale);

    const clickRaddaHavet = () => {
        if (!started && !introStart) {
            guardianStart();
            updateIntroStart(true);
            // guardianStageStartById(currentStage.id);
        }
    };

    const startStageText = (stageId: string) => {
        switch (stageId) {
            case "1":
                return motorboatTaskText;
            case "2":
                return cargoTaskText;
            case "3":
                return trashTaskText;
            case "4":
                return trawlingTaskText;
            default:
                return "";
        }
    };

    const startStageTipsText = (stageId: string) => {
        switch (stageId) {
            case "1":
                return motorboatTipsText;
            case "2":
                return cargoTipsText;
            case "3":
                return trashTipsText;
            case "4":
                return trawlingTipsText;
            default:
                return "";
        }
    };

    const onTryAgain = () => {
        tryAgain();
        alert && setAlert(false);
        loose && setLoose(false);
        updateIntroStart(true);
    };

    const onPlayStage = () => {
        setActiveStage(String(Number(activeStage) + 1));
        updateIntroStart(true);
        alert && setAlert(false);
        loose && setLoose(false);
    };

    return (
        <nav className="voto-footer">
            <div className="stage-text">
                <div className="stage-text__left">
                    <h5 className="title">{mainTitleText}</h5>
                    {currentStage.started && !currentStage.completed ? (
                        <h5 className="task">{startStageText(activeStage)}</h5>
                    ) : (
                        ""
                    )}
                </div>
                <img width={120} src={guardian_thumbnail} alt="" />
                {currentStage.started && !currentStage.completed ? (
                    <h5 className="tips">{startStageTipsText(activeStage)}</h5>
                ) : (
                    ""
                )}
            </div>
            <PinGuardian />
            {!started && wellcomeSpeechIsDone && (
                <button
                    onClick={() => (saveSeaIntroDone ? clickRaddaHavet() : {})}
                    className={`c-button button-background button--small button-blue-small right-side-button ${
                        !saveSeaIntroDone ? "button--blinking" : ""
                    }`}
                >
                    <SaveHavetBg className="button-bg" />
                    {saveSeaText}
                </button>
            )}
            {currentStage.finished &&
                !currentStage.completed &&
                !audioIsPlaying &&
                currentStage.tryCount !== 2 && (
                    <TryAgainButton
                        text={tryAgainText}
                        customClass="right-side-button"
                        onclick={onTryAgain}
                    />
                )}
            {(currentStage?.completed ||
                (currentStage.finished && currentStage.tryCount === 2)) &&
            !finished
                ? ((activeStage === "1" && motorboatSlut) ||
                      (activeStage === "2" && utslappSlut) ||
                      (activeStage === "3" && trashSlut)) && (
                      <NextButton
                          onclick={onPlayStage}
                          text={nextText}
                          customClass="right-side-button"
                      />
                  )
                : started &&
                  finished && (
                      <NextButton
                          link="/adventure"
                          text={nextText}
                          customClass="right-side-button"
                      />
                  )}
            <PointsGuardian />

            {started && <Clock />}
        </nav>
    );
};
export default FooterGuraidan;
