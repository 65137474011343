import { BrowserRouter, Link } from "react-router-dom";
import useVotoStore from "src/store";
import EndGameOverlay from "./end-game-modal/End.game.modal";
import AppRoutes from "./Routes";
import { Basename } from "src/types/index.d";
import { useEffect } from "react";
import SelectBasename from "./Select.basename";
import { getLocationByName, getLocations } from "src/services/location.api";

const App = (): JSX.Element => {
    const {
        overlay,
        setBasename,
        basename,
        submarine: { locations },
    } = useVotoStore();
    const parsedData = window.location.pathname.split("/");
    let param = parsedData[1] || "";

    useEffect(() => {
        if (Object.values(Basename).includes(param as Basename) && !basename) {
            setBasename(param);
        } else if (!Object.values(Basename).includes(param as Basename)) {
            setBasename("");
        }
    }, [param, basename, setBasename]);

    useEffect(() => {
        if (basename) {
            (async () => {
                await getLocationByName(basename);
            })();
        }
    }, [basename]);

    return (
        <div className="App">
            <BrowserRouter basename={basename}>
                {overlay && <EndGameOverlay />}
                <SelectBasename />
                <AppRoutes />
            </BrowserRouter>
        </div>
    );
};

export default App;
