import useVotoStore from "src/store";
import { ReactComponent as OnePlayer } from "src/assets/svg/quiz/one-player.svg";
import { ReactComponent as TwoPlayer } from "src/assets/svg/quiz/two-player.svg";
import SelectPlayerFooter from "src/compnents/footer/quiz/Select.player.footer";
import { translationService } from "src/services/translation.service";
import { AvatarName } from "src/types/index.d";

const Step1 = () => {
    const {
        quiz: { playerCount, players, wellcomeQuizIsDone },
        setNextSelectPlayerStep,
        setAvatar,
        setPlayerCount,
        locale,
    } = useVotoStore();

    const onSlectPlayerCount = (n: number) => {
        setPlayerCount(n);
        if (n === 1 && players[1].avatar !== "empty") {
            setAvatar(2, "empty", AvatarName.none);
        }
    };

    const titleText = translationService.translate("quizPage.title_1", locale);
    const onePlayerText = translationService.translate(
        "quizPage.onePlayer",
        locale
    );
    const twoPlayerText = translationService.translate(
        "quizPage.twoPlayer",
        locale
    );
    const selectPlayerText = translationService.translate(
        "quizPage.selectPlayer",
        locale
    );

    return (
        <>
            <div className="voto-select-player voto-select-player__step1">
                <h2 className="white-text voto-select-player__title">
                    {titleText}
                </h2>
                <div className="voto-select-player__step1-list">
                    <div className="voto-select-player__step1-list__item">
                        <button
                            className={` ${
                                playerCount === 1 ? "selected" : ""
                            }`}
                            onClick={() => onSlectPlayerCount(1)}
                        >
                            <OnePlayer />
                        </button>
                        <h3 className="white-text">{onePlayerText}</h3>
                    </div>
                    <div className="voto-select-player__step1-list__item">
                        <button
                            className={` ${
                                playerCount === 2 ? "selected" : ""
                            }`}
                            onClick={() => onSlectPlayerCount(2)}
                        >
                            <TwoPlayer />
                        </button>
                        <h3 className="white-text">{twoPlayerText}</h3>
                    </div>
                </div>
            </div>
            <SelectPlayerFooter
                guide={""}
                clickNext={setNextSelectPlayerStep}
                showNextButton={wellcomeQuizIsDone && playerCount >= 1}
            />
        </>
    );
};
export default Step1;
