import {
    GuardianProps,
    GuardianState,
} from "src/store/guardian.slice/guardian.slice";

import {
    SubmarineProps,
    SubmarineState,
} from "src/store/submarine.slice/submarine.slice";

import { BaseProps, BaseState } from "src/store/base.slice/base.slice";
import {
    VacationProps,
    VacationState,
} from "src/store/vacation/vacation.slice";
import { QuizProps, QuizState } from "src/store/quiz.slice/quiz.slice";

export enum Basename {
    ekerolinjen = "ekerolinjen",
    skansen = "skansen",
    Sjofartmuseet = "sjofartmuseet",
    havetshus = "havetshus",
    kosteroarna = "kosteroarna",
    sjohistoriska = "sjohistoriska",
    vrakmuseet = "vrakmuseet",
}

export enum Locale {
    en = "en",
    sv = "sv",
}

export enum Supports {
    textSupport = "textSupport",
    signLanguage = "signLanguage",
    imageSupport = "imageSupport",
}

export interface Film {
    film_title_en: string;
    film_title_se: string;
    url: string;
    image: string;
}

export interface MappedFilm {
    url: string;
    title: string;
    description: string;
}

export type SingleLocation = {
    location: string;
    films: Film[];
};

export type Locations = SingleLocation[];

export enum Adventures {
    submarine = "submarine",
    guardian = "guardian",
    vacation = "vacation",
    quiz = "quiz",
}

export enum Stage_1 {
    film_0 = "film-0",
    film_1 = "film-1",
    film_2 = "film-2",
    film_3 = "film-3",
    film_4 = "film-4",
    film_5 = "film-5",
    film_6 = "film-6",
}

export enum Stage_2 {
    ferry_turn = "ferry-turn",
    block_oil = "block-oil",
    remove_trash = "remove-trash",
    stop_trawling = "stop-trawling",
}

export enum Stage_3 {
    got_to_beach = "got-to-beach",
    snorkle_find_hidden = "snorkle-find-hidden",
    puzzle_castle = "puzzle-castle",
    find_treasures = "find-treasures",
    got_to_home = "got-to-home",
}

export enum SubStage_go_vacation {
    sun_glasses = "sun_glasses",
    raquets = "raquets",
    scuba = "scuba",
    dino = "dino",
    ball = "ball",
    camera = "camera",
    bucket = "bucket",
}

export enum SubStage_find_hidden {
    gadde = "gadde",
    jellyfish = "jellyfish",
    star = "star",
    crab = "crab",
    mussels = "mussels",
    piggvar = "piggvar",
}

export enum SubStage_puzzle_castle {
    tree_1 = "tree-1",
    tree_2 = "tree-2",
    top_tower_1 = "top-tower-1",
    tower_1 = "tower-1",
    top_tower_2 = "top-tower-2",
    tower_2 = "tower-2",
    main_top_tower = "main-top-tower",
    main_tower = "main-tower",
    ladder = "ladder",
    triangle_ladder = "triangle-ladder",
    right_door = "right-door",
    left_door = "left-door",
    door = "door",
}

export enum SubStage_find_treasures {
    amber_stone = "amber-stone",
    shellsnack = "shellsnack",
    conch = "conch",
    fossil = "fossil",
    vassen = "vassen",
    fish_bone = "fish-bone",
}

export enum SubStage_go_home {
    sun_glasses = "sun_glasses",
    raquets = "raquets",
    scuba = "scuba",
    dino = "dino",
    ball = "ball",
    camera = "camera",
    bucket = "bucket",
    food_box = "food-box",
    sun_cream = "sun-cream",
    water_flask = "water-flask",
}

export enum QuizAreaName {
    marine = "Marine Life",
    art = "Art",
    history = "History",
    scientist = "Scientist",
    sport = "Sport",
    empty = "",
}

export type Winner = number[];

export enum AvatarName {
    none = "----",
    peppe = "Pepe wings",
    poseidon = "Poseidon",
    explorer = "Explorer",
    furioush = "Furioush",
}

export type Question = {
    question: { en: string; sv: string };
    answers: { en: string; sv: string }[];
    rightAnswer: { en: string; sv: string };
    done: boolean;
};

export type SelectedQuestion = {
    area: QuizAreaName;
    question: Question | "";
    answered: boolean;
    answerdCorrect: boolean;
};

export type QuizArea = {
    name: QuizAreaName;
    questions: Question[];
};

export type Player = {
    id: 1 | 2;
    name: AvatarName;
    avatar: string;
    quizAreas: QuizArea[];
    points: number;
};

export type Step = "step1" | "step2" | "step3" | "step4";

export interface VotoProps
    extends BaseProps,
        GuardianProps,
        SubmarineProps,
        VacationProps,
        QuizProps {}

export interface VotoState
    extends BaseState,
        GuardianState,
        SubmarineState,
        VacationState,
        QuizState {
    resetState: () => void;
}
