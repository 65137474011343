import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./compnents/App";
import reportWebVitals from "./reportWebVitals";
import TagManager from "react-gtm-module";

const GTM_ID = process.env.REACT_APP_ROOT_GTM_ID;

GTM_ID && TagManager.initialize({ gtmId: GTM_ID });

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
