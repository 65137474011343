import { Question, QuizAreaName } from "src/types/index.d";
import useVotoStore from "src/store";
import { COUNTDOWN_MESSAGE_ANSWER } from "src/constants";
import AreaIcon from "src/utils/Area-icon";

export const SectionQuestion = ({
    area,
    question,
    playerId,
}: {
    area: QuizAreaName;
    question: Question;
    playerId: 1 | 2;
}) => {
    const {
        locale,
        quiz: { selectedQuestion },
        setAnswered,
        setCountdown,
    } = useVotoStore();

    const handleQuestionAnswer = (
        answer: { en: string; sv: string },
        event: any
    ) => {
        if (!selectedQuestion.answered) {
            setCountdown(5, COUNTDOWN_MESSAGE_ANSWER);
            const isRightAnswer = answer.en === question.rightAnswer.en;
            if (isRightAnswer) {
                event.target
                    .closest(".question-section__bottom-row")
                    .classList.add("right-answer");
            } else {
                event.target
                    .closest(".question-section__bottom-row")
                    .classList.add("wrong-answer");
            }
            setAnswered(area, question, playerId, isRightAnswer);
        }
    };

    return (
        <div className="question-section">
            <div className="question-section__top">
                <div className="selected-section">
                    <div className="selected-section__icon">
                        <AreaIcon area={area} />
                    </div>
                    <h4 className="selected-section__text white-text">
                        {question.question[locale]}
                    </h4>
                </div>
            </div>
            <div className="question-section__bottom">
                {question.answers.map((answer, index) => (
                    <div
                        key={index}
                        className={`question-section__bottom-row`}
                        onClick={(e) => handleQuestionAnswer(answer, e)}
                    >
                        <h5 className="white-text">
                            {index === 0 ? "a." : index === 1 ? "b." : "c."}{" "}
                        </h5>
                        <p>{answer[locale]}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};
