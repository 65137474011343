import { useEffect, useRef } from "react";
import useVotoStore from "src/store";
import { audioService } from "src/services/audio.service";
import { ReactComponent as Cross_icon } from "src/assets/svg/circle-cross.svg";
import { translationService } from "src/services/translation.service";

const Welcome = () => {
    const welRef = useRef<HTMLAudioElement>(null);

    const {
        locale,
        guardianWelcomeSpeechIsDone,
        setAudioIsPlaying,
        guardian: { audioIsPlaying, wellcomeSpeechIsDone },
    } = useVotoStore();

    const wellcome_havet = audioService.getAudioSrc("intro_havet", locale);
    const wellcome_havet_text1 = translationService.translate(
        "scientist.guardian.wellcome.1",
        locale
    );
    const wellcome_havet_text2 = translationService.translate(
        "scientist.guardian.wellcome.2",
        locale
    );

    useEffect(() => {
        if (!audioIsPlaying && !wellcomeSpeechIsDone) {
            welRef.current?.play();
            if (!audioIsPlaying) {
                setAudioIsPlaying(true);
            }
        }

        return () => {};
    }, [audioIsPlaying, wellcomeSpeechIsDone, setAudioIsPlaying]);

    const endWellcomeAudio = () => {
        welRef.current?.pause();
        setAudioIsPlaying(false);
        guardianWelcomeSpeechIsDone(true);
    };

    const onWellcomeMetaData = (e: any) => {};

    return (
        <div className="voto-welcome-vo">
            <audio
                // autoPlay={true}
                onLoadedMetadata={onWellcomeMetaData}
                onEnded={endWellcomeAudio}
                ref={welRef}
                src={wellcome_havet}
            />
            <Cross_icon className="cross-icon" onClick={endWellcomeAudio} />
            <p>
                {wellcome_havet_text1}
                <br />
                {wellcome_havet_text2}
            </p>
        </div>
    );
};
export default Welcome;
