import { FC } from "react";
import useVotoStore from "src/store";
import "../packup.scss";
import { translationService } from "src/services/translation.service";
import { Stage_3 } from "src/types/index.d";

const FooterPackupHome: FC = () => {
    const {
        locale,
        vacation: { stages },
    } = useVotoStore();

    const packupHomeStage = stages.find(
        (stage) => stage.name === Stage_3.got_to_beach
    );

    const ofText = translationService.translate("shared.of", locale);
    const mainTitleText = translationService.translate(
        "footer.vacation.mainTitle",
        locale
    );
    const packupHomeTaskText = translationService.translate(
        "footer.vacation.packupHomeTask",
        locale
    );
    const packupHomeTipsText = translationService.translate(
        "footer.vacation.packupHomeTips",
        locale
    );

    return (
        <nav className="voto-footer-packup-home">
            <div className="title">
                <h5 className="top-text">{mainTitleText}</h5>
                <h5 className="bottom-text">{packupHomeTaskText}</h5>
            </div>
            <div className="description">
                <h5>{packupHomeTipsText}</h5>
            </div>
            <div className="points">
                <h4>
                    {
                        packupHomeStage?.subStages.filter(
                            (sub) => sub.completed === true
                        ).length
                    }{" "}
                    {ofText} 4
                </h4>
            </div>
        </nav>
    );
};
export default FooterPackupHome;
