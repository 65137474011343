import useVotoStore from "src/store";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import countdown_lottie from "src/assets/animations/countdown.json";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "src/routes/routes";
import SelectPlayerFooter from "src/compnents/footer/quiz/Select.player.footer";
import { translationService } from "src/services/translation.service";
import { getTranslatedName } from "src/utils/helpers";

const Step4 = () => {
    const navigate = useNavigate();
    const countdownRef = useRef<LottieRefCurrentProps>(null);

    const {
        quiz: { players, countdown, selectPlayerDone, playerCount },
        setCountdown,
        setPreviousSelectPlayerStep,
        setSelectPlayerDone,
        locale,
    } = useVotoStore();

    useEffect(() => {
        if (selectPlayerDone && !countdown.state) {
            navigate(routes.quiz);
        }
    }, [selectPlayerDone, countdown]);

    const goodLuck_1Text = translationService.translate(
        "quizPage.goodLuck_1",
        locale
    );
    const goodLuck_2Text = translationService.translate(
        "quizPage.goodLuck_2",
        locale
    );
    const readyText = translationService.translate("quizPage.ready", locale);
    const readySingleText = translationService.translate(
        "quizPage.readySingle",
        locale
    );

    return (
        <>
            <div className="voto-select-player voto-select-player__step4">
                <h2 className="white-text voto-select-player__title">
                    {!selectPlayerDone &&
                        (playerCount !== 2 ? readySingleText : readyText)}
                </h2>
                <div
                    className={`voto-select-player__step4__list ${
                        playerCount === 1 ? "align-center" : ""
                    }`}
                >
                    <div>
                        {!!players[0]!.avatar ? (
                            <img
                                className="avatar"
                                src={require(`src/assets/svg/quiz/avatars/${
                                    players[0]!.avatar
                                }.svg`)}
                                alt={players[0]!.name}
                            />
                        ) : (
                            ""
                        )}
                        <h3 className="white-text">
                            {getTranslatedName(players[0]!.name, locale)}
                        </h3>
                    </div>
                    {playerCount !== 1 && (
                        <>
                            <h3 className="white-text">VS</h3>
                            <div>
                                {!!players[1]!.avatar ? (
                                    <img
                                        className="avatar"
                                        src={require(`src/assets/svg/quiz/avatars/${
                                            players[1]!.avatar
                                        }.svg`)}
                                        alt={players[1]!.name}
                                    />
                                ) : (
                                    ""
                                )}
                                <h3 className="white-text">
                                    {getTranslatedName(
                                        players[1]!.name,
                                        locale
                                    )}
                                </h3>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {countdown.state && (
                <Lottie
                    lottieRef={countdownRef}
                    className="countdown-circle__lottie"
                    animationData={countdown_lottie}
                    loop={false}
                    autoplay={true}
                />
            )}
            <SelectPlayerFooter
                guide={playerCount === 1 ? goodLuck_1Text : goodLuck_2Text}
                showNextButton={!countdown.state && !selectPlayerDone}
                clickNext={() => {
                    setSelectPlayerDone(true);
                    setCountdown(4, "ready_game");
                }}
                showBackButton={!countdown.state && !selectPlayerDone}
                clickBack={setPreviousSelectPlayerStep}
            />
        </>
    );
};
export default Step4;
