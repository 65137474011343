import useVotoStore from "src/store";
import trawling_ship from "src/assets/images/adventure/guardian/trawling-ship-1500.png";
import group_fish from "src/assets/svg/spigg.svg";
import { useEffect, useRef, useState } from "react";
import checked from "src/assets/svg/check-green.svg";
import spoknat from "src/assets/svg/spoknat.svg";
import cross_red from "src/assets/svg/red-cross.svg";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import net_loop from "src/assets/animations/Net_Loop.json";
import net_anim from "src/assets/animations/Net_Pull.json";

const Trawling = () => {
    const netRef = useRef<LottieRefCurrentProps>(null);
    const [animationDone, setAnimationDone] = useState(false);

    const {
        guardian,
        guardianStageCompletedById,
        guardianStageFinishedById,
        guardianStageStartById,
        guardianFinished,
        setLoose,
    } = useVotoStore();
    const { completed, finished, id, time, tryCount, started } =
        guardian.stages[3];

    useEffect(() => {
        if (
            (guardian.trawlIntro && tryCount !== 2) ||
            (guardian.trawlIntro && tryCount === 2)
        ) {
            guardianStageStartById(id);
        }
    }, [
        guardian.introStart,
        guardianStageStartById,
        id,
        guardian.trawlIntro,
        tryCount,
    ]);

    useEffect(() => {
        let subs: any;
        if (completed && guardian.trawlSlut) {
            subs = setTimeout(() => {
                guardianFinished();
                guardian.loose && setLoose(false);
            }, 1000);
        }
        if (guardian.finished) {
            clearTimeout(subs);
        }

        return () => {
            subs && clearTimeout(subs);
        };
    }, [
        guardian.finished,
        finished,
        completed,
        guardianStageCompletedById,
        guardianStageFinishedById,
        id,
        guardianFinished,
        guardian.trawlSlut,
        tryCount,
    ]);

    const onTouchStart = () => {
        netRef.current?.animationItem?.goToAndPlay(0);
        guardianStageCompletedById(id);
        guardianStageFinishedById(id);
        setTimeout(() => {
            setAnimationDone(true);
        }, 2000);
    };

    return (
        <div className={`stage-trawling`}>
            <div className="stage-trawling__container">
                <img
                    className="stage-trawling__container-ship"
                    src={trawling_ship}
                    alt=""
                />
                <div
                    className={`stage-trawling__container__nest ${
                        animationDone || finished ? "nest--drope" : ""
                    }`}
                >
                    {!completed && started && (
                        <>
                            {!completed || (!completed && finished) ? (
                                <Lottie
                                    animationData={net_loop}
                                    autoplay={true}
                                    loop={true}
                                    onClick={onTouchStart}
                                    onTouchStart={onTouchStart}
                                    className="stage-trawling__container__nest-image"
                                />
                            ) : (
                                ""
                            )}
                        </>
                    )}
                    {started && completed && !animationDone && (
                        <Lottie
                            lottieRef={netRef}
                            animationData={net_anim}
                            autoplay={true}
                            loop={false}
                            id="net"
                            className="stage-trawling__container__nest-image"
                            // style={{
                            //     opacity: !animationDone ? 1 : 0,
                            // }}
                        />
                    )}

                    {finished && !completed ? (
                        ""
                    ) : (
                        <img
                            src={group_fish}
                            className="stage-trawling__container__nest-fish"
                            style={{
                                animation: `move-left ${
                                    time * 2
                                }s linear forwards`,
                            }}
                            alt=""
                        />
                    )}
                    {/* 
                    {(!finished || completed) && (
                        <img
                            src={group_fish}
                            className="stage-trawling__container__nest-fish"
                            style={{
                                animation: `move-left ${
                                    time * 2
                                }s linear forwards`,
                            }}
                            alt=""
                        />
                    )} */}
                    {finished && !completed && (
                        <img
                            src={spoknat}
                            className="stage-trawling__container__spoknat"
                            alt=""
                        />
                    )}
                </div>

                {completed && finished && (
                    <img
                        className="stage-trawling__checked"
                        src={checked}
                        alt=""
                    />
                )}
                {finished && !completed && (
                    <img
                        className="stage-trawling__checked"
                        src={cross_red}
                        alt=""
                    />
                )}
            </div>
        </div>
    );
};
export default Trawling;
