import StarsRank from "../../stars/Stars.rank";
import { ReactComponent as StarBgDark } from "src/assets/svg/stars/star-bg-dark.svg";
import { ReactComponent as StarBgLight } from "src/assets/svg/stars/star-bg-light.svg";
import "./player.scss";
import { Player } from "src/types/index.d";
import useVotoStore from "src/store";
import { getTranslatedName } from "src/utils/helpers";
import { translationService } from "src/services/translation.service";
import { quiz_question_number } from "src/constants";

const PlayerSection = ({
    player,
    active,
}: {
    player: Player;
    active: boolean;
}) => {
    const {
        locale,
        quiz: { playerCount },
    } = useVotoStore();

    const turnText = translationService.translate("quizPage.turn", locale);

    return (
        <div className={`c-voto-player`}>
            <p className="c-voto-player__yourTurn white-text">
                {playerCount === 2 && active ? turnText : ""}
            </p>
            <h2 className="c-voto-player__name white-text">
                {getTranslatedName(player.name, locale)}
            </h2>
            <div className="c-voto-player__info">
                <img
                    className="c-voto-player__info-avatar"
                    src={require(`src/assets/svg/quiz/avatars/${player.avatar}.svg`)}
                    alt={player.name}
                />
                <div className={`c-voto-player__info-points`}>
                    {active ? (
                        <StarBgLight className="star-bg" />
                    ) : (
                        <StarBgDark className="star-bg" />
                    )}
                    <StarsRank
                        startsNumber={quiz_question_number}
                        totalItem={quiz_question_number}
                        doneItem={player.points || 0}
                        starColor={active ? "light" : "dark"}
                    />
                </div>
            </div>
        </div>
    );
};

export default PlayerSection;
