import { useEffect, useRef, useState } from "react";
import useVotoStore from "src/store";
import { AudioNames } from "src/store/guardian.slice/guardian.slice";

const AudioText = ({
    text,
    vo,
    audioName,
    text2,
}: {
    text: string;
    vo: any;
    audioName: AudioNames;
    text2?: string;
}) => {
    const audioRef = useRef<HTMLAudioElement>(null);

    const { setAudioIsPlaying, guardian, guardianAudioDoneByName } =
        useVotoStore();

    useEffect(() => {
        if (!guardian[audioName] && !guardian.audioIsPlaying) {
            audioRef.current?.play();
            setAudioIsPlaying(true);
        }

        return () => {};
    }, [setAudioIsPlaying, audioName, guardian]);

    const onEndAudio = () => {
        if (!guardian[audioName]) {
            audioRef.current?.pause();
            setAudioIsPlaying(false);
            guardianAudioDoneByName(audioName);
        }
    };

    return (
        <div
            className={`voto-scientist__text-box ${
                guardian.loose ? "text-box--loose" : ""
            }`}
        >
            {text && !guardian[audioName] && (
                <div className="text-wrapper">
                    <p className="box-text">
                        {text}
                        {text2 ? (
                            <>
                                <br />
                                {text2}
                            </>
                        ) : (
                            ""
                        )}
                    </p>
                </div>
            )}
            <audio onEnded={onEndAudio} ref={audioRef} src={vo} />
        </div>
    );
};
export default AudioText;
