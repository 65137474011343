import { useEffect, useState } from "react";
import "./clock.scss";
import useVotoStore from "src/store";

const Clock = () => {
    const [passTime, setPassTime] = useState<number>(0);
    const { guardian, setTimingout, setAlert, setLoose } = useVotoStore();

    const { started, finished, time, timeout, completed } =
        guardian.stages[Number(guardian.activeStage) - 1];

    useEffect(() => {
        let timeSubscriber: any;
        let counter = 1;
        if (started && !finished && !completed) {
            timeSubscriber = setInterval(() => {
                counter += passTime;
                setPassTime(counter);
            }, 1000);

            if (passTime >= time && !timeout) {
                setTimingout(true);
                clearInterval(timeSubscriber);
                counter = 0;
                setPassTime(0);
            }
        }

        if (passTime >= time || finished || completed) {
            // console.log("remove set time out");
            clearInterval(timeSubscriber);
            counter = 0;
            setPassTime(0);
        }
        return () => {
            timeSubscriber && clearInterval(timeSubscriber);
        };
    }, [finished, time, started, setTimingout, timeout, completed, passTime]);

    useEffect(() => {
        if (
            passTime >= time - time / 5 &&
            !guardian.alert &&
            !(finished || completed)
        ) {
            setAlert(true);
        }

        if (time <= passTime || (finished && !completed)) {
            if (guardian.alert) {
                setAlert(false);
            }
            if (!guardian.finished) {
                setLoose(true);
            } else if (guardian.loose) {
                setLoose(false);
            }
        }
    }, [
        passTime,
        time,
        finished,
        completed,
        setAlert,
        setLoose,
        guardian.alert,
        guardian.finished,
    ]);

    return (
        <></>
        // <div
        //     className={`voto-clock__container ${
        //         timeout || (finished && !completed)
        //             ? "loose"
        //             : completed
        //             ? "win"
        //             : passTime !== 0 && passTime >= (time * 1.5) / 3
        //             ? "alert"
        //             : ""
        //     }`}
        // >
        //     <img className="clock" src={clock_1} alt="" />
        //     <img className="clock" src={clock_2} alt="" />
        //     <div className="countdown">
        //         {timeout && <p>Time out!</p>}
        //         {completed && <p>Good job!</p>}
        //         {finished && !timeout && !completed && <p>You are loosed!</p>}
        //         {!finished && (
        //             <>
        //                 <p>
        //                     {time - passTime >= 0 ? time - passTime : 0} . .
        //                     .&nbsp;
        //                 </p>
        //                 <p>
        //                     {time - passTime - 1 >= 0 ? time - passTime - 1 : 0}{" "}
        //                     . . .&nbsp;
        //                 </p>
        //                 <p>
        //                     {" "}
        //                     {time - passTime - 2 >= 0 ? time - passTime - 2 : 0}
        //                 </p>
        //             </>
        //         )}
        //     </div>
        // </div>
    );
};
export default Clock;
