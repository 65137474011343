import StarsRank from "src/compnents/stars/Stars.rank";
import { translationService } from "src/services/translation.service";
import useVotoStore from "src/store";
import { Stage_2 } from "src/types/index.d";

const PointsGuardian = () => {
    const { guardian, locale } = useVotoStore();

    const ofText = translationService.translate("shared.of", locale);

    return (
        <div className="points">
            {guardian.activeStage === "3" && (
                <div className="points__trash-stage">
                    <h4>
                        {
                            guardian.stages
                                .find((st) => st.name === Stage_2.remove_trash)!
                                .subStages?.filter(
                                    (sub) => sub.completed === true
                                ).length
                        }
                        &nbsp;
                        {ofText}&nbsp;
                        {
                            guardian.stages.find(
                                (st) => st.name === Stage_2.remove_trash
                            )!.subStages?.length
                        }
                    </h4>
                </div>
            )}
            <div className="guardian-stars">
                <StarsRank
                    totalItem={guardian.stages.length}
                    doneItem={
                        guardian.stages.filter((st) => st.completed).length
                    }
                    startsNumber={guardian.stages.length}
                />
            </div>
        </div>
    );
};
export default PointsGuardian;
