import { ReactComponent as HistoryIcon } from "src/assets/svg/quiz/history-icon.svg";
import { ReactComponent as ArtIcon } from "src/assets/svg/quiz/art-icon.svg";
import { ReactComponent as ScientistIcon } from "src/assets/svg/quiz/scientist-icon.svg";
import { ReactComponent as SportIcon } from "src/assets/svg/quiz/sport-icon.svg";
import { ReactComponent as MarineIcon } from "src/assets/svg/quiz/marine-icon.svg";
import { QuizAreaName } from "src/types/index.d";
import useVotoStore from "src/store";
import { translationService } from "src/services/translation.service";

export const AreaSelected = ({ area }: { area: QuizAreaName }) => {
    const { locale } = useVotoStore();

    const marineText = translationService.translate(
        "quizPage.quizAreas.names.marine",
        locale
    );
    const artText = translationService.translate(
        "quizPage.quizAreas.names.art",
        locale
    );
    const historyText = translationService.translate(
        "quizPage.quizAreas.names.history",
        locale
    );
    const scientistText = translationService.translate(
        "quizPage.quizAreas.names.scientist",
        locale
    );
    const sportText = translationService.translate(
        "quizPage.quizAreas.names.sport",
        locale
    );

    const areaContent = () => {
        switch (area) {
            case QuizAreaName.history:
                return {
                    component: <HistoryIcon className="area-icon" />,
                    text: historyText,
                };
            case QuizAreaName.art:
                return {
                    component: <ArtIcon className="area-icon" />,
                    text: artText,
                };
            case QuizAreaName.marine:
                return {
                    component: <MarineIcon className="area-icon" />,
                    text: marineText,
                };
            case QuizAreaName.scientist:
                return {
                    component: <ScientistIcon className="area-icon" />,
                    text: scientistText,
                };
            case QuizAreaName.sport:
                return {
                    component: <SportIcon className="area-icon" />,
                    text: sportText,
                };
            default:
                return { component: <></>, text: "" };
        }
    };

    return (
        <div className="selected-area">
            <div className="selected-area__icon">{areaContent().component}</div>
            <h3 className="selected-area__text white-text">
                {areaContent().text}
            </h3>
        </div>
    );
};
