import "./keyboard.scss";
import backspace_black_svg from "src/assets/svg/backspace-black.svg";
import backspace_white_svg from "src/assets/svg/backspace-white.svg";

const customKeys = [
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "del"],
    ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P", "Å"],
    ["A", "S", "D", "F", "G", "H", "J", "K", "L", "Ö", "Ä"],
    ["Z", "X", "C", "V", "B", "N", "M"],
    ["space"],
];

const Keyboard = ({ inputOnChange, theme = "dark" }: any) => {
    const handleKeyClick = (key: number | string) => {
        inputOnChange(key);
    };
    return (
        <div className={`c-keyboard theme--${theme}`}>
            <div>
                {customKeys.map((keyboardRow, index) => (
                    <ul key={index} className="c-keyboard__row">
                        {keyboardRow.map((key, index) => {
                            return (
                                <button
                                    key={index}
                                    className={`c-keyboard__key key-${key}`}
                                    onClick={() => handleKeyClick(key)}
                                >
                                    {key}
                                    {key === "del" && (
                                        <img
                                            src={
                                                theme === "dark"
                                                    ? backspace_black_svg
                                                    : backspace_white_svg
                                            }
                                            alt="Backspace"
                                            width={"35px"}
                                        />
                                    )}
                                </button>
                            );
                        })}
                    </ul>
                ))}
            </div>
        </div>
    );
};
export default Keyboard;
