import { useEffect, useRef } from "react";
import useVotoStore from "src/store";
import intro_audio from "src/assets/sounds/intro_ubat_kor.mp3";
import { SubStage_find_hidden } from "src/types";
import Scientist from "src/compnents/scientist/Scientist";

interface SnorkleAudioProps {
    // onAudioEnd: () => void;
    // onStartAudio: () => void;
    audioName: SubStage_find_hidden;
    audioSrc: any;
}

const SnorkleAudioText = ({
    // onAudioEnd,
    // onStartAudio,

    audioName,
    audioSrc,
}: SnorkleAudioProps) => {
    const audioRef = useRef<HTMLAudioElement>(null);

    const {
        vacation: { stages },
        snorkleAudioIntroDone,
        updateSnorkleAudioIsPlaying,
    } = useVotoStore();

    const snorkleStage = stages[1];

    useEffect(() => {
        if (
            !snorkleStage.subStages.find((sub) => sub.name === audioName)
                ?.intro &&
            !snorkleStage.audioIsPlaying
        ) {
            audioRef.current?.play();
            if (!snorkleStage.audioIsPlaying) {
                updateSnorkleAudioIsPlaying(true);
            }
        }

        return () => {};
    }, [
        audioName,
        snorkleStage.audioIsPlaying,
        snorkleStage.subStages,
        updateSnorkleAudioIsPlaying,
    ]);

    const endWellcomeAudio = () => {
        audioRef.current?.pause();
        snorkleAudioIntroDone(audioName);
        updateSnorkleAudioIsPlaying(false);
    };

    return (
        <>
            <audio
                // autoPlay={true}
                onEnded={endWellcomeAudio}
                ref={audioRef}
                src={audioSrc}
            />
        </>
    );
};

export default SnorkleAudioText;
