export const translationsData = {
    translations: {
        firstPage: {
            title: {
                1: { sv: "Vad du", en: "What you" },
                2: { sv: "behöver veta om", en: "need to know about" },
                3: { sv: "Havet", en: "the Ocean" },
            },
            translate: { en: "På Svenska", sv: "In English" },
            start: { en: "Start here", sv: "Börja här" },
        },
        namePage: {
            welcome: { en: "Welcome!", sv: "Välkommen!" },
            title: { en: "Whats your name?", sv: "Vad heter du?" },
            hint: {
                en: "Leave empty if you want to continue without name",
                sv: "Lämna tomt om du vill fortsätta utan namn",
            },
            placeholder: {
                en: "Write your name here",
                sv: "Skriv ditt namn här",
            },
        },
        quizPage: {
            wellcome: {
                en: "Hi and welcome to the ocean quiz. Here you can challenge a friend to a quiz or play yourself, whichever suits you best. Click on one or two players and then follow the on screen instructions to get started. Good luck with the game and hope you learn new things about the ocean.",
                sv: "Hej och välkommen till havsquizet! Här kan du utmana en kompis i frågesport eller så spelar du själv, vilket som passar dig bäst. Klicka på en eller två spelare och följ sedan instruktionerna på skärmen för att komma igång. Lycka till och hoppas att du kommer att lära dig nya saker om havet!",
            },
            title_1: {
                en: "How do you want to play today?",
                sv: "Hur vill du spela idag?",
            },
            onePlayer: {
                en: "One player",
                sv: "En spelare",
            },
            twoPlayer: {
                en: "Two players",
                sv: "Två spelare",
            },
            selectPlayer: {
                en: "Select one or two players",
                sv: "Välj en eller två spelare",
            },
            whichPlayer: {
                en: "Which character do you want to play with?",
                sv: "Vilken karaktär vill du spela med?",
            },
            pick_1: {
                en: "Player 1. pick your character",
                sv: "Spelare 1. Välj din karaktär",
            },
            pick_2: {
                en: "Player 2. pick your character",
                sv: "Spelare 2. Välj din karaktär",
            },
            poseidon: {
                en: "Poseidon",
                sv: "Poseidon",
            },
            explorer: {
                en: "The Diver",
                sv: "Dykaren",
            },
            pepewings: {
                en: "Pepe wings",
                sv: "Sigge fiskmås",
            },
            furioush: {
                en: "Furioush",
                sv: "Arga fisken",
            },
            selectChar: {
                en: 'Select your character and click "next"',
                sv: 'Välj din karaktär och klicka på "Nästa"',
            },
            goodLuck_1: {
                en: "Good luck!",
                sv: "Lycka till!",
            },
            goodLuck_2: {
                en: "Good luck competitors!",
                sv: "Lycka till!",
            },
            turn: {
                en: "Your turn",
                sv: "Din tur",
            },
            ready: {
                en: "Ready to play?",
                sv: "Är ni redo att spela?",
            },
            readySingle: {
                en: "Ready to play?",
                sv: "Är du redo att spela?",
            },
            spin: {
                en: "Spin",
                sv: "Snurra",
            },
            spinTurn: {
                en: "It is your turn to spin the wheel.",
                sv: "Det är din tur att snurra på hjulet!",
            },
            playTurn: {
                en: "It is your turn to play!",
                sv: "Det är din tur att spela!",
            },
            readyGame: {
                en: "Get ready. The game is about to start.",
                sv: "Gör dig förberedd. Spelet startar snart.",
            },
            spinBtn: {
                en: "Click the red button to spin the roulette.",
                sv: "Klicka på den röda knappen för att snurra på hjulet.",
            },
            pickAnswer: {
                en: "Time to pick an answer, xxx!",
                sv: "Nu måste du svara på frågan, xxx!",
            },
            luckNext: {
                en: "Better luck next time.",
                sv: "Rackarns! Bättre lycka nästa försök.",
            },
            quizAreas: {
                names: {
                    marine: { en: "Marine Life", sv: "Livet i havet" },
                    art: { en: "Art & Culture", sv: "Konst & Kultur" },
                    history: { en: "Ocean History", sv: "Havets historia" },
                    scientist: { en: "Ocean Science", sv: "Havsvetenskap" },
                    sport: { en: "Sports & recreations", sv: "Sport & fritid" },
                },
            },
            feedback: {
                right: {
                    title: {
                        en: "Well done king of ocean!",
                        sv: "Bra gjort, havets hjälte!",
                    },
                    text: {
                        en: "Looks like you nailed this section.",
                        //  "For being awesome, you get to keep your turn! Prepare to spin the wheel again.",
                        sv: "Du klarade av den här delen galant.",
                        // "och du får behålla din tur! Gör dig redo att snurra hjulet igen.",
                    },
                },
                wrong: {
                    title: {
                        en: "Rackarns! Better luck next time.",
                        sv: "Rackarns, du svarade fel!",
                    },
                    text1: {
                        en: "",
                        sv: "",
                        // en: "Try again.",
                        // sv: "Försök igen.",
                    },
                    text2: {
                        en: "",
                        sv: "",
                        // en: "Now let's see how the how the {0} does.",
                        // sv: "Nu ska vi se hur {0} klarar sig.",
                    },
                },
            },
            winner: {
                en: "The winner is",
                sv: "Vinnaren är",
            },
        },
        guidePage: {
            title: {
                en: "One last thing before we start",
                sv: "En sista sak innan vi börjar",
            },
            subtitle: {
                en: "Do you want any of the following",
                sv: "Vill du ha någon av de följande",
            },
            textGuideTitle: {
                en: "Text support",
                sv: "Textstöd",
            },
            textGuideInfo: {
                en: "You will be able to read the text as support for the sound",
                sv: "Du kommer kunna läsa texten som stöd till ljudet",
            },
            signLanguageTitle: {
                en: "Sign language",
                sv: "Teckenspråk",
            },
            signLanguageInfo: {
                en: "Sign language support will appear instead of audio",
                sv: "Teckenspråksstöd kommer dyka upp istället för ljud",
            },
            imageSupportTitle: {
                en: "Replacement of movies with images",
                sv: "Ersättning av filmer med bilder",
            },
            imageSupportInfo: {
                en: "No fun! Instead, replace everything with still images",
                sv: "Inget rörligt! Istället ersätt allt med stillbilder",
            },
        },
        adventurePage: {
            stages: {
                title: {
                    en: "Pick today's adventure",
                    sv: "Välj ett äventyr",
                },
                submarine: {
                    en: "Dive in a submarine",
                    sv: "Upptäck havet under ytan",
                },
                guardian: {
                    en: "Guardians of the sea",
                    sv: "Hjälp till att rädda havet",
                },
                summer: {
                    en: "The perfect summer vacations",
                    sv: "Roliga strandaktiviteter",
                },
                quiz: {
                    en: "Quiz about the sea",
                    sv: "Havsquiz",
                },
            },
        },
        submarinePage: {
            intro: {
                first: {
                    sv: "Hej, och välkommen in till u-båten. Här inne finns filmer som du kan titta på för att lära dig mer om havet.",
                    en: "Hello, and welcome to the submarine. Inside there are films that you can watch to learn more about the sea.",
                },
                second: {
                    sv: "Välj film och klicka på Kör för att se filmen.",
                    en: "Select a film and click on GO to watch the film.",
                },
            },
            windowText: {
                sv: "Välj en film och tryck",
                en: "Select one film and press",
            },
            films: {
                title: {
                    1: {
                        en: "The underwater world of the Ray",
                        sv: "Flyg med i rockans värld",
                    },
                    2: {
                        en: "Say hello to the fish below the surface",
                        sv: "Hälsa på hos fiskarna under ytan",
                    },
                    3: {
                        en: "Explore the National Park of Koster Islands",
                        sv: "Utforska Kosterhavets nationalpark",
                    },
                    4: {
                        en: "Discover a wreck",
                        sv: "Upptäck vraket på havets botten",
                    },
                    5: {
                        en: "Seals in the Baltic Sea",
                        sv: "Våra sälar i Östersjön",
                    },
                    6: {
                        en: "Baltic Sea below the surface",
                        sv: "Östersjön under ytan",
                    },
                },
                description: {
                    1: {
                        en: "",
                        sv: "",
                    },
                    2: {
                        en: "",
                        sv: "",
                    },
                    3: {
                        en: "",
                        sv: "",
                    },
                    4: {
                        en: "",
                        sv: "",
                    },
                    5: {
                        en: "",
                        sv: "",
                    },
                    6: {
                        en: "",
                        sv: "",
                    },
                },
            },
        },
        bravoPage: {
            scientist: {
                sv: "Tack för att du hjälper till att hålla havet friskt och rent! Nu mår djuren och växterna bra.",
                en: "Thank you for taking care of the ocean!",
            },
        },
        beachPage: {
            title: {
                sv: "Välkommen till stranden!",
                en: "Welcome to the beach!",
            },
            subtitle: {
                sv: "Välj vad du vill göra idag",
                en: "Choose what you want to do today",
            },
            backHome: {
                sv: "Nu åker vi hem!",
                en: "Now we're going home",
            },
        },
        vacation: {
            snorkle: {
                title: {
                    sv: "Hitta djuren som har gömt sig på botten",
                    en: "Find the animals that have hidden down there",
                },
            },
        },
        endPage: {
            title: {
                sv: "Tack för att du hjälper till att ta hand om Havet!",
                en: "Thank you so much for taking care of the Ocean!",
            },
            replay: {
                sv: "Börja om",
                en: "Play again",
            },
        },
        footer: {
            vacation: {
                mainTitle: {
                    sv: "Roliga strandaktiviteter",
                    en: "Fun beach activities",
                },
                treasureTask: {
                    sv: "Leta skatter",
                    en: "Find Treasures",
                },
                snorkleTask: {
                    sv: "Snorkla i havet",
                    en: "Snorkle in the ocean",
                },
                castleTask: {
                    sv: "Bygg ett sandslott",
                    en: "Build a sandcastle",
                },
                packupBeachTask: {
                    sv: "Nu åker vi hem från stranden",
                    en: "Now we're going home from the beach",
                },
                packupBeachTips: {
                    sv: "Klicka eller dra och släpp dina saker på ryggsäcken för att ta med de hem",
                    en: "Click or drag your items on the backpack to take them home",
                },
                packupHomeTask: {
                    sv: "Nu ska vi till stranden",
                    en: "Now we're going to the beach",
                },
                packupHomeTips: {
                    sv: "Samla ihop fyra stycken saker som du vill ha med dig till stranden. Klicka eller dra och släpp sakerna på ryggsäcken.",
                    en: "Collect four things you want to bring to the beach. Click or drag the items on the backpack.",
                },
            },
            guardian: {
                mainTitle: {
                    sv: "Hjälp till att rädda havet",
                    en: "Guardians of the sea",
                },
                saveSea: {
                    sv: "Rädda havet",
                    en: "Save the sea",
                },
                motorboatTask: {
                    sv: "Stoppa motorbåten!",
                    en: "Stop motor boat!",
                },
                motorboatTips: {
                    sv: "Klicka eller dra för att stoppa motorbåten",
                    en: "Click or drag the motorboat to stop it",
                },
                utslappTips: {
                    sv: "Klicka på utsläppet för att få det att försvinna.",
                    en: "Click on the pollution to make it disappear.",
                },
                cargoTask: {
                    sv: "Stoppa utsläppen!",
                    en: "Stop the pollution!",
                },
                cargoTips: {
                    sv: "Klicka för att ta bort utsläppen!",
                    en: "Click to remove the pollution",
                },
                trashTask: {
                    sv: "Plocka upp skräpet!",
                    en: "Remove the rubbish!",
                },
                trashTips: {
                    sv: "Klicka eller dra skräpet till soptunnan",
                    en: "Click or drag the rubbish and put it in the bin",
                },
                trawlingTask: {
                    sv: "Rädda fiskarna!",
                    en: "Save the fish!",
                },
                trawlingTips: {
                    sv: "Klicka för att ta bort nätet för att rädda fiskarna",
                    en: "Click to remove the net to save the fish",
                },
            },
        },
        navbar: {
            home: { sv: "HEM", en: "HOME" },
            playAs: { sv: "Spelar som", en: "Play as" },
        },
        overlay: {
            title: {
                sv: "Är du säker du vill avsluta",
                en: "Are you sure you want to finish",
            },
            subtitle: {
                sv: "Din session kommer sluta och du kommer behöva starta om från början",
                en: "Your session will end and you will have to restart from the beginning",
            },
            cancel: {
                sv: "JAG VILL FORTSÄTTA SPELA",
                en: "I WANT TO CONTINUE PLAYING",
            },
        },
        shared: {
            go: { sv: "KÖR", en: "GO" },
            end: { sv: "Avsluta", en: "End game" },
            back: { en: "Back", sv: "Tillbaka" },
            next: {
                en: "Next",
                sv: "Nästa",
            },
            start: { en: "Start", sv: "Börja" },
            tryAgain: {
                sv: "Försök igen",
                en: "Try again",
            },
            points: {
                sv: "Poäng",
                en: "Points",
            },
            of: {
                sv: "av",
                en: "of",
            },
            close: {
                sv: "Stäng",
                en: "Close",
            },
            and: {
                sv: "Och",
                en: "And",
            },
        },
        scientist: {
            guardian: {
                wellcome: {
                    1: {
                        sv: "Visste du att vårt fantastiska hav är en förutsättning för allt liv på jorden?",
                        en: "Did you know that our amazing ocean is essential for all life on Earth?",
                    },
                    2: {
                        sv: "Lyssna på vad forskaren har att säga, han kommer att guida dig och hjälpa dig att hålla havet friskt och rent.",
                        en: "Listen to what our scientist has to say, she will guide you and help you keep the sea healthy and clean.",
                    },
                },
                saveSeaIntro: {
                    sv: "Klicka på Rädda havet för att hjälpa till att hålla havet friskt och rent.",
                    en: "Click on Save the Sea to help the ocean keept healthy and clean.",
                },
                startMotorBoat: {
                    sv: "Åh nej! En motorbåt är på väg in i det skyddade området. Klicka på båten för att stoppa den!",
                    en: "Oh no! A motorboat is entering the marine protected area. Click on the boat to stop it!",
                },
                motorboatBra: {
                    sv: "Bra gjort! Du har hjälpt till att skydda området.",
                    en: "Well done! You have helped to protect the area.",
                },
                motorboatNeg: {
                    sv: "Åh nej! Båten har åkt in på det skyddade området, vilket inte är tillåtet.",
                    en: "Oh no! The boat has entered the protected area, which is not alowed.",
                },
                motorboatSlut: {
                    sv: "Att respektera och ta hand om marina skyddsområden är viktigt för att hjälpa det marina livet att trivas och återhämta sig. ",
                    en: "Respecting and caring for marine protected areas is essential to help marine life thrive and recover. ",
                },
                utslappIntro: {
                    sv: "Hjälp till att hålla vattnet och luften ren genom att förhindra att spill och utsläpp.",
                    en: "Help keep the water and air clean by preventing spills and emissions.",
                },
                utslappBra: {
                    sv: "Fantastiskt! Nu har du hjälpt till att hålla luften och vattnet rent!",
                    en: "Fantastic! Now you've helped keep the air and water clean!",
                },
                utslappNeg: {
                    sv: "Ledsen! Du hann inte i tid!",
                    en: "Sorry! You didn't make it in time!",
                },
                utslappTryAgain: {
                    sv: "Försök igen.",
                    en: "Try again.",
                },
                utslappSlut: {
                    sv: "Havet reglerar temperaturen på jorden och förser oss med 50 % av syret på vår planet. Det är bland annat därför det är så viktigt att ta hand om havet.",
                    en: "The ocean regulates the temperature of the Earth and provides us with 50% of the oxygen that we breath. This is one of the reasons why taking care of the ocean is so important.",
                },
                trashIntro: {
                    sv: "Åh nej! Det är en massa skräp har hamnat i vattnet. Hjälp till att ta bort det!",
                    en: "Oh no! There is a lot of rubbish and waste in the water. Help remove it!",
                },
                trashBra: {
                    sv: "Fantastiskt! Du har säkerställt att skräp, plaster och andra gifter inte finns kvar i vattnet.",
                    en: "Fantastic! You have ensured that rubbish, plastics and other toxins are not left in the water.",
                },
                trashNeg: {
                    sv: "Oups, du hann inte samla in allt skräp i tid!",
                    en: "Oops, you didn't collect all the rubbish in time!",
                },
                trashSlut: {
                    sv: "Skräp och andra giftiga ämnen hör såklart inte hemma i havet, därför är det viktigt att hålla rent efter sig.",
                    en: "Rubbish and other toxic substances do not belong in the sea, so it is important to keep it clean.",
                },
                trashFakta: {
                    sv: "",
                    en: "Did you know that it takes up to 20 years for a plastic bag to dissolve and 'disappear'? But they almost never disappear completely; instead, the plastic breaks down into smaller and smaller parts and ends up as microplastics that can end up in our drinking water or in the fish we eat.",
                },
                trawlPreIntro: {
                    1: {
                        sv: "Havet tillhör alla!",
                        en: "The ocean belongs to everyone!",
                    },
                    2: {
                        sv: "Vi måste ta hand om och dela med oss av havets resurser så att alla kan ha tillgång till dem. Både nu och för framtiden.",
                        en: "We must take care of and share the ocean's resources so that everyone can have access to them. Both now and for the future.",
                    },
                },
                trawlIntro: {
                    sv: "Nu behöver du hjälpa till att ta bort nätet från havet. Hamnar det på botten kan det ligga där förevigt och fiska, det kallas för spöknät. Klicka för att ta bort det.",
                    en: "Now you need to help remove the net from the sea. If it ends up on the bottom, it can stay there forever and fish, it is called a ghost net. Click to remove it.",
                },
                trawlBra: {
                    sv: "Vad bra att du tog bort nätet från havet.",
                    en: "How nice that you removed the net from the sea. ",
                },
                trawlNeg: {
                    sv: "Stopp! Nätet ska inte vara kvar i havet.",
                    en: "Stop! The net should not remain in the sea.",
                },
                trawlSlut: {
                    sv: "Det måste finnas tillräckligt mycket fisk i havet för att ekosystemet ska må bra och så att det räcker till mat lång tid framöver. Spöknät riskerar dessutom att fånga in fisk helt i onödan då ingen tar upp fisken som fångas i nätet.",
                    en: "It is essential that humans do not overfish our oceans. There must be enough fish in the sea to keep the ecosystem healthy and preserve biodiversity. In addition, ghost nets risk catching fish unnecessarily, as no one takes the fish caught in the net.",
                },
            },
            snorkle: {
                flundra: {
                    sv: "Flundror har båda sina ögon på ena sidan av kroppen. När de är unga har de en symetrisk kroppsform, men under utvecklingen vandrar ena ögat över huvudet för att hamna bredvid det andra ögat på den andra sidan. Detta gör att flundran kan ligga på havsbotten och hålla utkik efter föda med båda ögonen samtidigt. Väldigt konstigt, eller hur?",
                    en: "Flounder have both eyes on one side of their body. When they are young, they have a symmetrical body shape, but as they develop, one eye moves across the head to join the other eye on the other side. This allows the flounder to lie on the seabed and look for food at the same time. Very strange, isn't it?",
                },
                sjostjarna: {
                    sv: "Sjöstjärnor är kända för sin unika förmåga att återskapa förlorade kroppsdelar. Om en sjöstjärna förlorar en arm kan den faktiskt växa tillbaka! Detta beror på att de har ett speciellt nervsystem och interna organ som gör det möjligt.",
                    en: "Starfish are known for their unique ability to regenerate lost body parts. If a starfish loses an arm, it can actually grow back! This is because they have a special nervous system and internal organs that make this possible.",
                },

                mussla: {
                    sv: "Blåmusslorna är föda för många djur. Men musslor har även en viktig roll för att hålla våra hav rena och friska genom att de filtrerar vatten och rensar bort föroreningar. Dessutom lagrar de koldioxid från vattnet i sina skal. När blåmusslor dör sjunker deras skal till botten och tar koldioxiden med sig och den stannar i vattnet, vilket är väldigt bra.",
                    en: "Did you know that all blue mussels are initially male? Later in life, some of them become females. Interesting, isn't it?",
                },
                gadda: {
                    sv: "Gäddan har små, små vita prickar på sin kropp? Detta mönster är helt unikt för varje gädda, precis som människans fingeravtryck är unikt för var och en av oss. Är det inte fantastiskt?",
                    en: "The pike has small, tiny white dots on its body? This pattern is completely unique to each pike, just like the human fingerprint is unique to each of us. Isn't it amazing?",
                },
                manet: {
                    sv: "Det finns en manet som heter lejonmanet. Den är en av de största i världen. Den största lejonmaneten som någonsin registrerats var mer än 2 meter bred och hade stickande tentakler som var 36 meter långa. Kan du föreställa dig det?",
                    en: "There is a jellyfish called the lion jellyfish. It is one of the largest in the world. The largest lion jellyfish ever recorded was more than 2 metres wide and had stinging tentacles 36 metres long. Can you imagine that?",
                },
                krabba: {
                    sv: "Strandkrabbor ändrar sitt skal för att bli större. Den här processen kallas ömsning. När krabban ömsar så bildas det nya, mjuka skalet under det gamla. När krabban lämnat sitt gamla hårda skal tar det några dagar för det nya skalet att hårdna. Under den tiden är krabban mycket utsatt för att bli uppäten av andra rovdjur.",
                    en: "Female shore crabs change their shells to become larger. This process is called molting. When they abandon their old hard shell and are left with a soft body that becomes hard after a few days or weeks. While their body is soft, they are very vulnerable to being eaten by predators.",
                },
            },
        },
        quizAreas: {
            names: {
                marine: { en: "Marine Life", sv: "Marint liv" },
                art: { en: "Art & Culture", sv: "Konst & Kultur" },
                history: { en: "Ocean History", sv: "Havshistoria" },
                scientist: { en: "Ocean Science", sv: "Havsvetenskap" },
                sport: { en: "Sport & Recreation", sv: "Sport & Fritid" },
            },
        },
    },
};
