import VideoPlayer from "src/hooks/video-player/Video-player";
import go_home_part_1_video_SWE from "src/assets/videos/Voto_Strandfilmen_PART2_REV6_SWE.mp4";
import go_home_part_1_video_EN from "src/assets/videos/Voto_Strandfilmen_PART2_REV6_EN.mp4";
import { useNavigate } from "react-router-dom";
import useVotoStore from "src/store";
import { Locale } from "src/types/index.d";

const PackupPreBeach = () => {
    const navigate = useNavigate();

    const { locale } = useVotoStore();

    const go_home_part_1_video =
        locale === Locale.en
            ? go_home_part_1_video_EN
            : go_home_part_1_video_SWE;

    return (
        <VideoPlayer
            url={go_home_part_1_video}
            autoplay={true}
            onEndedVideo={() => {
                setTimeout(() => {
                    navigate("/packup-beach");
                }, 1000);
            }}
        />
    );
};
export default PackupPreBeach;
