import { Suspense } from "react";
import { Link } from "react-router-dom";
import BaseBackground from "src/compnents/backgrounds/base/base-background";
import { translationService } from "src/services/translation.service";
import useVotoStore from "src/store";
import useAnalyticsEventTracker from "src/hooks/google-analytics/useAnalyticsEventTracker";
import { routes } from "src/routes/routes";
import { ReactComponent as StartBg } from "src/assets/svg/buttons-blue/start-bg.svg";

const FirstPage = () => {
    const { changeLocale, locale } = useVotoStore();

    const gaEventTracker = useAnalyticsEventTracker("Click event");

    const languageText = translationService.translate(
        "firstPage.translate",
        locale
    );
    const mainText1 = translationService.translate("firstPage.title.1", locale);
    const mainText2 = translationService.translate("firstPage.title.2", locale);
    const mainText3 = translationService.translate("firstPage.title.3", locale);
    const startBtn = translationService.translate("firstPage.start", locale);

    return (
        <Suspense fallback={<>Loading...</>}>
            <div className="c-first-page c-voto-page">
                <button
                    className="c-button button--primary btn-translate"
                    onClick={changeLocale}
                >
                    {languageText}
                </button>
                <BaseBackground hasOverlay={true} />
                <div className="title">
                    <h2>{mainText1}</h2>
                    <h2>{mainText2}</h2>
                    <h2>{mainText3}</h2>
                </div>

                <Link
                    to={routes.adventure}
                    className="c-button button-background button-blue-start button--large btn-start"
                    onClick={() => gaEventTracker("Click on Start")}
                >
                    <StartBg className="button-bg" />
                    {startBtn}
                </Link>
            </div>
        </Suspense>
    );
};
export default FirstPage;
