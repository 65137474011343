import { useEffect } from "react";
import {
    BrowserRouter,
    Route,
    Routes,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import AdventureHome from "src/pages/adventures-pages/Adventure.home";
import FirstPage from "src/pages/first-page/First.page";
import NamePage from "src/pages/name-page/Name.page";
import SelectGuidePage from "src/pages/select-guide-page/Select.guide.page";
import useVotoStore from "src/store";
import Submarine from "src/pages/adventures-pages/submarine-page/Submarine.page";
import Guardian from "src/pages/adventures-pages/guardian-page/Guardian.page";
import PackupHome from "src/pages/vacation-pages/pack-up-home/Packup.to-beach.page";
import BagVideo from "src/pages/vacation-pages/bag.video";
import BeachHome from "src/pages/vacation-pages/beach/Beach.home";
import SnorklePage from "src/pages/vacation-pages/beach/snorkle/Snorkle.page";
import CastlePage from "src/pages/vacation-pages/beach/castle/Castle.page";
import TreasuresPage from "src/pages/vacation-pages/beach/treasures/Treasures.page";
import PackupBeach from "src/pages/vacation-pages/pack-up-beach/Packup.to-home.page";
import PackupPreBeach from "src/pages/vacation-pages/pack-up-pre-beach/Packup-pre-beach";
import EndPage from "src/pages/end-page/End-page";
import { routes } from "src/routes/routes";
import TagManager from "react-gtm-module";
import SelectPlayer from "src/pages/adventures-pages/quiz-page/player-select/Select-player";
import QuizPage from "src/pages/adventures-pages/quiz-page/quiz/Quiz.page";
import WinPage from "src/pages/adventures-pages/quiz-page/Win.page";

const AppRoutes = (): JSX.Element => {
    const location = useLocation();
    const { basename } = useVotoStore();

    const GTM_ID = process.env.REACT_APP_ROOT_GTM_ID;

    useEffect(() => {
        if (GTM_ID && basename) {
            const tagManagerArgs = {
                dataLayer: {
                    userProject: basename,
                    page: location.pathname + location.search,
                },
                dataLayerName: basename,
            };
            TagManager.dataLayer(tagManagerArgs);
        }
    }, [location, basename]);

    return (
        <Routes>
            <Route path={routes.home} element={<FirstPage />} />
            <Route path={routes.endPage} element={<EndPage />} />
            <Route path={routes.name} element={<NamePage />} />
            <Route path={routes.guide} element={<SelectGuidePage />} />
            <Route path={routes.adventure} element={<AdventureHome />} />
            <Route path={routes.submarine} element={<Submarine />} />
            <Route path={routes.guardian} element={<Guardian />} />
            <Route path={routes.packupHome} element={<PackupHome />} />
            <Route path={routes.packupPreBeach} element={<PackupPreBeach />} />
            <Route path={routes.packupBeach} element={<PackupBeach />} />
            <Route path={routes.bagVideo} element={<BagVideo />} />
            <Route path={routes.beach} element={<BeachHome />} />
            <Route path={routes.snorkle} element={<SnorklePage />} />
            <Route path={routes.castle} element={<CastlePage />} />
            <Route path={routes.treasures} element={<TreasuresPage />} />
            <Route path={routes.selectPlayer} element={<SelectPlayer />} />
            <Route path={routes.quiz} element={<QuizPage />} />
            <Route path={routes.quizWinner} element={<WinPage />} />
        </Routes>
    );
};

export default AppRoutes;
