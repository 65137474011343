import { useEffect, useRef } from "react";
import useVotoStore from "src/store";
import { audioService } from "src/services/audio.service";

const SubmarineIntro = () => {
    const welRef = useRef<HTMLAudioElement>(null);
    const {
        locale,
        submarineUpdateIntro,
        submarine: { intro },
    } = useVotoStore();

    const intro_audio = audioService.getAudioSrc("intro_ubat_kor", locale);

    useEffect(() => {
        if (!intro) {
            welRef.current?.play();
        }

        return () => {};
    }, [intro]);

    const endWellcomeAudio = () => {
        welRef.current?.pause();
        submarineUpdateIntro(true);
    };

    return (
        <audio
            // autoPlay={true}
            onEnded={endWellcomeAudio}
            ref={welRef}
            src={intro_audio}
        />
    );
};
export default SubmarineIntro;
