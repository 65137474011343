import { FC } from "react";
import useVotoStore from "src/store";
import "./footer.quiz.scss";
import { BackButton, NextButton } from "src/elemets/Buttons";
import { translationService } from "src/services/translation.service";

type SelectPlayerFooterProps = {
    guide?: string;
    showNextButton?: boolean;
    clickNext?: () => void;
    showBackButton?: boolean;
    clickBack?: () => void;
};

const SelectPlayerFooter: FC<SelectPlayerFooterProps> = ({
    guide,
    clickNext,
    clickBack,
    showNextButton,
    showBackButton,
}) => {
    const {
        locale,
        vacation: { stages },
    } = useVotoStore();

    const nextText = translationService.translate("shared.next", locale);
    const backText = translationService.translate("shared.back", locale);

    return (
        <nav className="voto-footer__select-player">
            <h3 className="blue-text">{guide}</h3>
            <div className="back-next-btns">
                <span>
                    {showBackButton && (
                        <BackButton
                            text={backText}
                            onclick={clickBack}
                            customClass="back-button"
                        />
                    )}
                </span>
                <span>
                    {showNextButton && (
                        <NextButton
                            text={nextText}
                            onclick={clickNext}
                            customClass="next-button"
                        />
                    )}
                </span>
            </div>
        </nav>
    );
};
export default SelectPlayerFooter;
