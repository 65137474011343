import cloud from "src/assets/images/adventure/guardian/clouds.png";
import land_left from "src/assets/images/background/land-left.png";
import weed_front from "src/assets/images/adventure/weed_front.png";
import Bubbles from "src/compnents/bubbles/Bubbles";
import Fish from "src/compnents/fish/Fish";
import land_right from "src/assets/images/background/land-right.png";
import bottom_rocks from "src/assets/images/background/bottom-rocks.svg";
import wreck from "src/assets/images/background/wreck.svg";
import bottom_1 from "src/assets/images/background/bottom-1.svg";
import bottom_2 from "src/assets/images/background/bottom-2.svg";
import bottom_3 from "src/assets/images/background/bottom-3.svg";
import bottom_4 from "src/assets/images/background/bottom-4.svg";
import bottom_fix_items from "src/assets/images/background/bottom-fix-items.svg";
import "./base-background.scss";

const BaseBackground = ({ hasOverlay = false }) => {
    return (
        <div className={`base-background ${hasOverlay ? "has--overlay" : ""}`}>
            <img className="topp-cloud" src={cloud} alt="" />
            <img className="land-left" src={land_left} alt="" />
            <img className="land-right" src={land_right} alt="" />
            <Bubbles />
            <img className="bottom-1 bottom" src={bottom_1} alt="" />
            <img className="bottom-2 bottom" src={bottom_2} alt="" />
            <img className="bottom-3 bottom" src={bottom_3} alt="" />
            <img className="bottom-4 bottom" src={bottom_4} alt="" />
            <img className="weed-front" src={weed_front} alt="" />
            <img className="wreck" src={wreck} alt="" />
            <img className="bottom-rocks" src={bottom_rocks} alt="" />
            <img
                className="bottom-fix-items bottom"
                src={bottom_fix_items}
                alt=""
            />
            <Fish />
        </div>
    );
};
export default BaseBackground;
