import produce from "immer";
import { Adventures, Stage_2, VotoState } from "src/types/index.d";
import { StateCreator } from "zustand";

export interface GuardianState extends GuardianProps {
    setProtectedAreaOffset: (num: number) => void;
    guardianStageStartById: (id: string) => void;
    guardianStageFinishedById: (id: string) => void;
    guardianStageCompletedById: (id: string) => void;
    tryAgain: () => void;
    setActiveStage: (id: string) => void;
    guardianStart: () => void;
    guardianFinished: () => void;
    setTimingout: (b: boolean) => void;
    setAudioIsPlaying: (b: boolean) => void;
    guardianWelcomeSpeechIsDone: (b: boolean) => void;
    guardianAudioDoneByName: (n: AudioNames) => void;
    setAlert: (b: boolean) => void;
    setLoose: (b: boolean) => void;
    guardianSubstageCompletedByName: (
        stageId: string,
        subStageId: string
    ) => void;
    updateIntroStart: (b: boolean) => void;
}

export enum Substage_cargo {
    oil = "oil",
    smoke = "smoke",
}
export enum Substage_trash {
    can = "can",
    plastic = "plastic",
    flask_1 = "flask-1",
    flask_2 = "flask-2",
    tier = "tier",
}

export interface GuardianProps {
    [Adventures.guardian]: {
        id: string;
        name: Adventures.guardian;
        started: boolean;
        completed: boolean;
        finished: boolean;
        activeStage: string;
        alert: boolean;
        loose: boolean;
        audioIsPlaying: boolean;
        wellcomeSpeechIsDone: boolean;
        introStart: boolean;
        saveSeaIntroDone: boolean;
        startMotorBoat: boolean;
        motorboatBra: boolean;
        motorboatNeg: boolean;
        motorboatSlut: boolean;
        utslappIntro: boolean;
        utslappBra: boolean;
        utslappNeg: boolean;
        utslappSlut: boolean;
        trashIntro: boolean;
        trashBra: boolean;
        trashNeg: boolean;
        trashSlut: boolean;
        trawlPreIntro: boolean;
        trawlIntro: boolean;
        trawlBra: boolean;
        trawlNeg: boolean;
        trawlSlut: boolean;
        stages: {
            id: string;
            name: Stage_2;
            protectedAreaOffset?: number;
            time: number;
            timeout: boolean;
            tryCount: number;
            started: boolean;
            finished: boolean;
            completed: boolean;
            subStages?: {
                id: string;
                name: Substage_cargo | Substage_trash;
                completed: boolean;
            }[];
        }[];
    };
}

export enum AudioNames {
    saveSeaIntroDone = "saveSeaIntroDone",
    startMotorBoat = "startMotorBoat",
    motorboatBra = "motorboatBra",
    motorboatNeg = "motorboatNeg",
    motorboatSlut = "motorboatSlut",
    utslappIntro = "utslappIntro",
    utslappBra = "utslappBra",
    utslappNeg = "utslappNeg",
    utslappSlut = "utslappSlut",
    trashIntro = "trashIntro",
    trashBra = "trashBra",
    trashNeg = "trashNeg",
    trashSlut = "trashSlut",
    trawlPreIntro = "trawlPreIntro",
    trawlIntro = "trawlIntro",
    trawlBra = "trawlBra",
    trawlNeg = "trawlNeg",
    trawlSlut = "trawlSlut",
}

export const guardianIntialState: GuardianProps = {
    [Adventures.guardian]: {
        id: "2",
        name: Adventures.guardian,
        started: false,
        completed: false,
        finished: false,
        alert: false,
        loose: false,
        audioIsPlaying: false,
        wellcomeSpeechIsDone: false,
        introStart: false,
        saveSeaIntroDone: false,
        activeStage: "1",
        startMotorBoat: false,
        motorboatBra: false,
        motorboatNeg: false,
        motorboatSlut: false,
        utslappIntro: false,
        utslappBra: false,
        utslappNeg: false,
        utslappSlut: false,
        trashIntro: false,
        trashBra: false,
        trashNeg: false,
        trashSlut: false,
        trawlPreIntro: false,
        trawlIntro: false,
        trawlBra: false,
        trawlNeg: false,
        trawlSlut: false,
        stages: [
            {
                id: "1",
                name: Stage_2.ferry_turn,
                protectedAreaOffset: 0,
                time: 15,
                timeout: false,
                started: false,
                tryCount: 0,
                finished: false,
                completed: false,
            },
            {
                id: "2",
                name: Stage_2.block_oil,
                time: 20,
                timeout: false,
                tryCount: 0,
                started: false,
                finished: false,
                completed: false,
                subStages: [
                    { id: "1", name: Substage_cargo.oil, completed: false },
                    { id: "2", name: Substage_cargo.smoke, completed: false },
                ],
            },
            {
                id: "3",
                name: Stage_2.remove_trash,
                time: 20,
                timeout: false,
                tryCount: 0,
                started: false,
                finished: false,
                completed: false,
                subStages: [
                    { id: "1", name: Substage_trash.can, completed: false },
                    { id: "2", name: Substage_trash.flask_1, completed: false },
                    { id: "3", name: Substage_trash.flask_2, completed: false },
                    { id: "4", name: Substage_trash.tier, completed: false },
                    { id: "5", name: Substage_trash.plastic, completed: false },
                ],
            },
            {
                id: "4",
                name: Stage_2.stop_trawling,
                time: 15,
                timeout: false,
                tryCount: 0,
                started: false,
                finished: false,
                completed: false,
            },
        ],
    },
};

export const guardianSlice: StateCreator<VotoState, [], [], GuardianState> = (
    set: (
        partial: (state: GuardianState) => Partial<GuardianState>,
        replace?: boolean,
        name?: string
    ) => void
) => ({
    ...guardianIntialState,
    setProtectedAreaOffset: (num: number) =>
        set(
            produce((state: GuardianState) => {
                state[Adventures.guardian].stages.find(
                    (stage) => stage.id === "1"
                )!.protectedAreaOffset = num;
            }),
            false,
            "setProtectedAreaOffset"
        ),
    guardianStageStartById: (id: string) => {
        set(
            produce((state: GuardianState) => {
                state[Adventures.guardian].stages.find(
                    (stage) => stage.id === id
                )!.started = true;
            }),
            false,
            `Stage ${id} started`
        );
    },
    guardianStageFinishedById: (id: string) => {
        set(
            produce((state: GuardianState) => {
                state[Adventures.guardian].stages.find(
                    (stage) => stage.id === id
                )!.finished = true;
            }),
            false,
            `Stage ${id} finished`
        );
    },
    guardianStageCompletedById: (id: string) => {
        set(
            produce((state: GuardianState) => {
                state[Adventures.guardian].stages.find(
                    (stage) => stage.id === id
                )!.completed = true;
            }),
            false,
            `Stage ${id} completed`
        );
    },
    tryAgain: () =>
        set(
            produce((state: GuardianState) => {
                let stage = state[Adventures.guardian].stages.find(
                    (stage) =>
                        stage.id === state[Adventures.guardian].activeStage
                );

                if (stage) {
                    stage.completed = false;
                    stage.finished = false;
                    stage.started = false;
                    stage.timeout = false;
                    stage.tryCount = 2;
                }

                if (state[Adventures.guardian].activeStage === "1") {
                    // state[Adventures.guardian].startMotorBoat = false;
                    state[Adventures.guardian].motorboatNeg = false;
                }
                if (state[Adventures.guardian].activeStage === "2") {
                    // state[Adventures.guardian].utslappIntro = false;
                    state[Adventures.guardian].utslappNeg = false;
                    stage?.subStages?.map((sub) => (sub.completed = false));
                }

                if (state[Adventures.guardian].activeStage === "3") {
                    // state[Adventures.guardian].trashIntro = false;
                    state[Adventures.guardian].trashNeg = false;
                    stage?.subStages?.map((sub) => (sub.completed = false));
                }

                if (state[Adventures.guardian].activeStage === "4") {
                    // state[Adventures.guardian].trawlIntro = false;
                    state[Adventures.guardian].trawlNeg = false;
                }

                if (stage) {
                    state[Adventures.guardian].stages[Number(stage.id) - 1] =
                        stage;
                }
            }),
            false,
            `Stage reset`
        ),
    setActiveStage: (id: string) => {
        set(
            produce((state: GuardianState) => {
                state[Adventures.guardian].activeStage = id;
            }),
            false,
            `Stage ${id} set active`
        );
    },
    guardianStart: () => {
        set(
            produce((state: GuardianState) => {
                state[Adventures.guardian].started = true;
            }),
            false,
            `Guardian started`
        );
    },
    guardianFinished: () => {
        set(
            produce((state: GuardianState) => {
                state[Adventures.guardian].finished = true;
            }),
            false,
            `Guardian finished`
        );
    },
    guardianWelcomeSpeechIsDone: (b: boolean) => {
        set(
            produce((state: GuardianState) => {
                state[Adventures.guardian].wellcomeSpeechIsDone = b;
            }),
            false,
            `Guardian welcome speech is done : ${b}`
        );
    },
    setTimingout: (b: boolean) =>
        set(
            produce((state: GuardianState) => {
                const activeStage = state[Adventures.guardian].activeStage;
                state[Adventures.guardian].stages.find(
                    (stage) => stage.id === activeStage
                )!.timeout = b;
            }),
            false,
            `Set timeout ${b}`
        ),

    setAudioIsPlaying: (b: boolean) =>
        set(
            produce((state: GuardianState) => {
                state[Adventures.guardian].audioIsPlaying = b;
            }),
            false,
            `Set Audio is playing ${b}`
        ),
    guardianAudioDoneByName: (n: AudioNames) => {
        set(
            produce((state: GuardianState) => {
                state[Adventures.guardian][n] = true;
            }),
            false,
            `Guardian ${n} is done`
        );
    },
    setAlert: (b: boolean) => {
        set(
            produce((state: GuardianState) => {
                state[Adventures.guardian].alert = b;
            }),
            false,
            `Guardian stage alert ${b}`
        );
    },
    setLoose: (b: boolean) => {
        set(
            produce((state: GuardianState) => {
                state[Adventures.guardian].loose = b;
            }),
            false,
            `Guardian stage loose ${b}`
        );
    },
    guardianSubstageCompletedByName: (
        stageId: string,
        subStageName: string
    ) => {
        set(
            produce((state: GuardianState) => {
                state[Adventures.guardian].stages
                    .find((stage) => stage.id === stageId)!
                    .subStages!.find(
                        (sub) => sub.name === subStageName
                    )!.completed = true;
            }),
            false,
            `Stage ${stageId}, subStage ${subStageName} completed`
        );
    },
    updateIntroStart: (b: boolean) => {
        set(
            produce((state: GuardianState) => {
                state[Adventures.guardian].introStart = b;
            }),
            false,
            `Stage intro ${b}`
        );
    },
});
