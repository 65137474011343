import React, { Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BaseBackground from "src/compnents/backgrounds/base/base-background";
import Keyboard from "src/compnents/keyboard/Keyboard";
import { routes } from "src/routes/routes";
import { translationService } from "src/services/translation.service";
import useVotoStore from "src/store";

const NamePage = () => {
    const [name, setName] = useState("");
    const [keybardVisible, setKeyboardVisible] = useState(false);
    const { locale, registerName, username } = useVotoStore();

    const welcome = translationService.translate("namePage.welcome", locale);
    const placeholder = translationService.translate(
        "namePage.placeholder",
        locale
    );
    const title = translationService.translate("namePage.title", locale);
    const back = translationService.translate("shared.back", locale);
    const next = translationService.translate("shared.next", locale);
    const hint = translationService.translate("namePage.hint", locale);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const touchKeybordOnChange = (key: string | number) => {
        if (key === "space") {
            key = " ";
        }
        if (key === "del") {
            setName(name.substring(0, name.length - 1));
        }

        if (key !== "del") {
            setName(name + key);
        }
    };

    useEffect(() => {
        if (username && username !== "Anonym") {
            setName(username);
        }

        return () => {};
    }, [username]);

    const submitName = () => {
        if (!name.trim()) {
            registerName("Anonym");
        } else {
            registerName(name);
        }
    };

    return (
        <Suspense fallback={<>Loading...</>}>
            <div className="c-name-page__wrapper c-voto-page">
                <BaseBackground hasOverlay={true} />
                <div className="c-name-page">
                    <div className="name-form">
                        <h2>{welcome}</h2>
                        <h5>{title}</h5>
                        <input
                            className="name-form__input"
                            type="text"
                            value={name}
                            placeholder={placeholder}
                            onChange={onChange}
                            onClick={() => setKeyboardVisible(true)}
                            readOnly={true}
                        />
                        {keybardVisible && (
                            <Keyboard
                                theme="light"
                                inputOnChange={touchKeybordOnChange}
                            />
                        )}
                        <p className="caption">{hint}!</p>
                    </div>

                    <div className="bottom-buttons">
                        <Link
                            to={"/"}
                            className="c-button button--primary button--large btn-back"
                        >
                            {back}
                        </Link>
                        <Link
                            to={routes.adventure}
                            className="c-button button--primary button--large"
                            onClick={submitName}
                        >
                            {next}
                        </Link>
                    </div>
                </div>
            </div>
        </Suspense>
    );
};
export default NamePage;
