import { translationService } from "src/services/translation.service";
import useVotoStore from "src/store";
import { AvatarName, Locale, QuizAreaName } from "src/types/index.d";

export const showAreaBg = (areaName: QuizAreaName) => {
    switch (areaName) {
        case QuizAreaName.art:
            return "bg-art";
        case QuizAreaName.history:
            return "bg-history";
        case QuizAreaName.marine:
            return "bg-marine";
        case QuizAreaName.scientist:
            return "bg-scientist";
        case QuizAreaName.sport:
            return "bg-sport";
        default:
            return "bg-light-blue";
    }
};

export const getTranslatedName = (name: AvatarName, locale: Locale) => {
    const poseidonText = translationService.translate(
        "quizPage.poseidon",
        locale
    );
    const explorerText = translationService.translate(
        "quizPage.explorer",
        locale
    );
    const pepewingsText = translationService.translate(
        "quizPage.pepewings",
        locale
    );
    const furioushText = translationService.translate(
        "quizPage.furioush",
        locale
    );
    switch (name) {
        case AvatarName.peppe:
            return pepewingsText;
        case AvatarName.poseidon:
            return poseidonText;
        case AvatarName.explorer:
            return explorerText;
        case AvatarName.furioush:
            return furioushText;
        default:
            return AvatarName.none;
    }
};
