import produce from "immer";
import {
    Adventures,
    Locations,
    Player,
    Winner,
    Question,
    VotoState,
    Step,
    QuizAreaName,
    AvatarName,
    SelectedQuestion,
} from "src/types/index.d";
import { StateCreator } from "zustand";
import { quizAreas } from "./quize-data";
import { COUNTDOWN_MESSAGE_ANSWER, quiz_question_number } from "src/constants";

export const getRandomInRange = (number: number) => {
    const randomIndex = Math.floor(Math.random() * number);
    return randomIndex;
};

export interface QuizProps {
    [Adventures.quiz]: {
        id: string;
        name: Adventures.quiz;
        started: boolean;
        completed: boolean;
        intro: boolean;
        playerCount: number;
        quizAudioIsPlaying: boolean;
        wellcomeQuizIsDone: boolean;
        secondPlayerLastTry: boolean;
        showFeedback: boolean;
        countdown: {
            state: boolean;
            message: string;
        };
        nextPlayer: 1 | 2;
        step: Step;
        selectPlayerDone: boolean;
        spinning: boolean;
        readyForSpin: boolean;
        gameFinish: boolean;
        activePlayer: 1 | 2;
        winner: Winner;
        selectedQuestion: SelectedQuestion;
        round: {
            start: boolean;
            end: boolean;
        };
        players: Player[];
    };
}

export const quizInitialState: QuizProps = {
    [Adventures.quiz]: {
        id: "4",
        name: Adventures.quiz,
        started: false,
        completed: false,
        intro: false,
        playerCount: 0,
        step: "step1",
        quizAudioIsPlaying: false,
        wellcomeQuizIsDone: false,
        secondPlayerLastTry: false,
        countdown: {
            state: false,
            message: "",
        },
        winner: [],
        selectPlayerDone: false,
        nextPlayer: 1,
        readyForSpin: true,
        spinning: false,
        showFeedback: true,
        activePlayer: 1,
        gameFinish: false,
        selectedQuestion: {
            area: QuizAreaName.empty,
            question: "",
            answered: false,
            answerdCorrect: false,
        },
        round: {
            start: false,
            end: false,
        },
        players: [
            {
                id: 1,
                avatar: "empty",
                name: AvatarName.none,
                quizAreas: quizAreas,
                points: 0,
            },
            {
                id: 2,
                avatar: "empty",
                name: AvatarName.none,
                quizAreas: quizAreas,
                points: 0,
            },
        ],
    },
};

export interface QuizState extends QuizProps {
    setPlayerCount: (n: number) => void;
    setAvatar: (playerId: number, avtar: string, name: AvatarName) => void;
    setCountdown: (n: number, m: string) => void;
    setNextSelectPlayerStep: () => void;
    setPreviousSelectPlayerStep: () => void;
    setSelectPlayerDone: (b: boolean) => void;
    setSpinning: (b: boolean) => void;
    setSelectedArea: (name: QuizAreaName) => void;
    setSelectedQuestion: (questionArea: QuizAreaName, playerId: 1 | 2) => void;
    // resetQuestion: () => void;
    setAnswered: (
        quizArea: QuizAreaName,
        question: Question,
        playerId: 1 | 2,
        answerdCorrect: boolean
    ) => void;
    changeTurn: () => void;
    updateRound: (start: boolean, end: boolean) => void;
    handleWinner: () => void;
    resetQuiz: () => void;
    setQuizAudioIsPlaying: (b: boolean) => void;
    setWellcomeQuizIsDone: (b: boolean) => void;
    handleAfterQuestionAnswerd: (selectedQuestion: SelectedQuestion) => void;
    forceHandleAfterQuestionAnswerd: () => void;
    setGameFinish: () => void;
}

// Helper functions
const setPlayerCount = (state: QuizState, n: number) => {
    state[Adventures.quiz].playerCount = n;
};

const setAvatar = (
    state: QuizState,
    pId: number,
    avatar: string,
    name: AvatarName
) => {
    const player = state[Adventures.quiz].players.find((p) => p.id === pId);
    if (player) {
        player.avatar = avatar;
        player.name = name;
    }
};

const setWinner = (state: QuizState, playerId: number) => {
    state[Adventures.quiz].winner.push(playerId);
};

const setSecondPlayerLastTry = (state: QuizState) => {
    state[Adventures.quiz].secondPlayerLastTry = true;
};

const changeTurn = (state: QuizState) => {
    state[Adventures.quiz].activePlayer =
        state[Adventures.quiz].activePlayer === 1 ? 2 : 1;
};

const resetQuestion = (state: QuizState) => {
    state[Adventures.quiz].selectedQuestion =
        quizInitialState[Adventures.quiz].selectedQuestion;
};

const setReadyForSpin = (state: QuizState, isReady: boolean) => {
    state[Adventures.quiz].readyForSpin = isReady;
};

const handleShowFeedback = (state: QuizState, show: boolean) => {
    state[Adventures.quiz].showFeedback = show;
};

const handleSecondPlayerLastTry = (state: QuizState) => {
    const { winner, players, activePlayer } = state[Adventures.quiz];
    if (
        winner.includes(players[0].id) &&
        players[1].points + 1 !== quiz_question_number
    ) {
        setSecondPlayerLastTry(state);
    }
    if (winner.includes(players[0].id) && activePlayer === 2) {
        setSecondPlayerLastTry(state);
    }

    if (winner.includes(players[1].id)) {
        setSecondPlayerLastTry(state);
    }
};

const handleNextPlayer = (
    state: QuizState,
    playerId: 1 | 2,
    answerdCorrect: boolean
) => {
    if (state[Adventures.quiz].playerCount === 2) {
        if (!answerdCorrect) {
            state[Adventures.quiz].nextPlayer = playerId === 1 ? 2 : 1;
        }
    }
};

// Zustand slice
export const quizSlice: StateCreator<VotoState, [], [], QuizState> = (
    set: (
        partial: (state: QuizState) => Partial<QuizState>,
        replace?: boolean,
        name?: string
    ) => void
) => ({
    ...quizInitialState,
    setPlayerCount: (n: number) =>
        set(
            produce((state: QuizState) => setPlayerCount(state, n)),
            false,
            `Set player count ${n}`
        ),
    setAvatar: (pId: number, avatar: string, name: AvatarName) =>
        set(
            produce((state: QuizState) => setAvatar(state, pId, avatar, name)),
            false,
            `Set player ${pId} avatar ${name}, # ${avatar}`
        ),
    setGameFinish: () =>
        set(
            produce((state: QuizState) => {
                state[Adventures.quiz].gameFinish = true;
                setReadyForSpin(state, false);
            }),
            false,
            `Set game finish`
        ),

    handleWinner: () =>
        set(
            produce((state: QuizState) => {
                const { players, winner } = state[Adventures.quiz];
                if (
                    players[0].points === quiz_question_number &&
                    !winner.includes(players[0].id)
                ) {
                    setWinner(state, players[0].id);
                }
                if (
                    players[1].points === quiz_question_number &&
                    !winner.includes(players[1].id)
                ) {
                    setWinner(state, players[1].id);
                }
                handleSecondPlayerLastTry(state);
            }),
            false,
            `handle winner`
        ),
    setCountdown: (n: number, message: string) => {
        let countdownTimer: any;
        let feedbackTimer: any;
        set(
            produce((state: QuizState) => {
                state[Adventures.quiz].countdown.state = true;
                state[Adventures.quiz].countdown.message = message;
                if (countdownTimer) {
                    clearTimeout(countdownTimer);
                }
            }),
            false,
            `Countdown => ${message} start for ${n} seconds`
        );
        feedbackTimer =
            message === COUNTDOWN_MESSAGE_ANSWER
                ? setTimeout(() => {
                      set(
                          produce((state: QuizState) => {
                              handleShowFeedback(state, true);
                              if (
                                  state[Adventures.quiz].countdown.state ===
                                  false
                              ) {
                                  clearTimeout(feedbackTimer);
                              }
                          }),
                          false,
                          `Countdown => Feedback start`
                      );
                  }, 2000)
                : "";
        countdownTimer = setTimeout(() => {
            set(
                produce((state: QuizState) => {
                    state[Adventures.quiz].countdown.state = false;
                    state[Adventures.quiz].countdown.message = "";
                    handleShowFeedback(state, false);
                    if (state[Adventures.quiz].countdown.state === false) {
                        clearTimeout(countdownTimer);
                        clearTimeout(feedbackTimer);
                    }
                }),
                false,
                `Countdown => ${message} finished after ${n} seconds`
            );
        }, n * 1000);
    },
    setNextSelectPlayerStep: () =>
        set(
            produce((state: QuizState) => {
                switch (state[Adventures.quiz].step) {
                    case "step1":
                        state[Adventures.quiz].step = "step2";
                        break;
                    case "step2":
                        if (state[Adventures.quiz].playerCount === 1) {
                            state[Adventures.quiz].step = "step4";
                        }
                        if (state[Adventures.quiz].playerCount === 2) {
                            state[Adventures.quiz].step = "step3";
                        }
                        break;
                    case "step3":
                        state[Adventures.quiz].step = "step4";
                        break;
                    default:
                        break;
                }
            }),
            false,
            `Next step`
        ),
    setPreviousSelectPlayerStep: () =>
        set(
            produce((state: QuizState) => {
                switch (state[Adventures.quiz].step) {
                    case "step2":
                        state[Adventures.quiz].step = "step1";
                        break;
                    case "step3":
                        state[Adventures.quiz].step = "step2";
                        break;
                    case "step4":
                        if (state[Adventures.quiz].playerCount === 1) {
                            state[Adventures.quiz].step = "step2";
                        }
                        if (state[Adventures.quiz].playerCount === 2) {
                            state[Adventures.quiz].step = "step3";
                        }
                        break;
                    default:
                        break;
                }
            }),
            false,
            `Back step`
        ),
    setSelectPlayerDone: (b: boolean) =>
        set(
            produce((state: QuizState) => {
                state[Adventures.quiz].selectPlayerDone = b;
            }),
            false,
            `Select player is done : ${b}`
        ),
    setSpinning: (b: boolean) =>
        set(
            produce((state: QuizState) => {
                state[Adventures.quiz].spinning = b;
                if (b) {
                    setReadyForSpin(state, false);
                }
            }),
            false,
            `Spinning : ${b}`
        ),
    updateRound: (start: boolean, end: boolean) =>
        set(
            produce((state: QuizState) => {
                state[Adventures.quiz].round.start = start;
                state[Adventures.quiz].round.end = end;
                const readySpin = !start && end;
                setReadyForSpin(state, readySpin);
            }),
            false,
            `Round start: ${start}, end: ${end}`
        ),
    setSelectedArea: (n: QuizAreaName) =>
        set(
            produce((state: QuizState) => {
                state[Adventures.quiz].selectedQuestion.area = n;
            }),
            false,
            `Selected area is : ${n}`
        ),
    setSelectedQuestion: (questionArea: QuizAreaName, playerId: 1 | 2) =>
        set(
            produce((state: QuizState) => {
                const notAnsweredQuestions = state[Adventures.quiz].players
                    .find((p) => p.id === playerId)
                    ?.quizAreas.find((area) => area.name === questionArea)
                    ?.questions.filter((q) => q.done === false) as Question[];
                const question =
                    notAnsweredQuestions[
                        getRandomInRange(notAnsweredQuestions?.length)
                    ];

                state[Adventures.quiz].selectedQuestion.question = question;
            }),
            false,
            `Selected question from ${questionArea}`
        ),
    handleAfterQuestionAnswerd: (selectedQuestion) =>
        set(
            produce((state: QuizState) => {
                const { winner, playerCount, players, activePlayer } =
                    state[Adventures.quiz];

                resetQuestion(state);

                if (playerCount === 2) {
                    handleSecondPlayerLastTry(state);
                    if (
                        winner.includes(players[0].id) &&
                        selectedQuestion.answerdCorrect &&
                        activePlayer === 1 &&
                        players[1].points + 1 === quiz_question_number
                    ) {
                        changeTurn(state);
                    }

                    if (!selectedQuestion.answerdCorrect && !winner.length) {
                        changeTurn(state);
                    }
                }
            }),
            false,
            `Reset question and/or change turn`
        ),

    forceHandleAfterQuestionAnswerd: () =>
        set(
            produce((state: QuizState) => {
                handleShowFeedback(state, false);
                state[Adventures.quiz].countdown.state = false;
                state[Adventures.quiz].countdown.message = "";
            }),
            false,
            `Force Reset question and/or change turn`
        ),
    // resetQuestion: () => set(
    //     produce((state: QuizState) => {
    //         resetQuestion(state);
    //     }),
    //     false,
    //     `Reset Selected question`
    // ),
    setAnswered: (
        area: QuizAreaName,
        question: Question,
        playerId: 1 | 2,
        answerdCorrect: boolean
    ) =>
        set(
            produce((state: QuizState) => {
                if (answerdCorrect) {
                    state[Adventures.quiz].players
                        .find((p) => p.id === playerId)!
                        .quizAreas.find((a) => a.name === area)!
                        .questions.find(
                            (q) => q.question.en === question.question.en
                        )!.done = true;
                    state[Adventures.quiz].players.find(
                        (p) => p.id === playerId
                    )!.points += 1;
                }

                state[Adventures.quiz].selectedQuestion.answered = true;
                state[Adventures.quiz].selectedQuestion.answerdCorrect =
                    answerdCorrect;

                handleNextPlayer(state, playerId, answerdCorrect);
            }),
            false,
            `Question  ${area}, ${question.question.en} is answered ${answerdCorrect}`
        ),
    changeTurn: () =>
        set(
            produce((state: QuizState) => {
                changeTurn(state);
            }),
            false,
            `Change player turn`
        ),
    setWellcomeQuizIsDone: (b: boolean) =>
        set(
            produce((state: QuizState) => {
                state[Adventures.quiz].wellcomeQuizIsDone = b;
            }),
            false,
            `Quiz welcome speech is done : ${b}`
        ),
    setQuizAudioIsPlaying: (b: boolean) =>
        set(
            produce((state: QuizState) => {
                state[Adventures.quiz].quizAudioIsPlaying = b;
            }),
            false,
            `Set Quiz Audio is playing ${b}`
        ),
    resetQuiz: () =>
        set(
            produce((state: QuizProps) => (state = quizInitialState)),
            false,
            "Reset Quiz Game"
        ),
});
