import line_bg from "src/assets/svg/line-packup-bg.svg";
import fix_stuffa from "src/assets/images/adventure/vacation/packup/fix-stuff-packup-bg.png";
import bag from "src/assets/images/adventure/vacation/packup/bag.png";
import bag_1 from "src/assets/images/adventure/vacation/packup/bag-1.png";
import bag_2 from "src/assets/images/adventure/vacation/packup/bag-2.png";
import bag_3 from "src/assets/images/adventure/vacation/packup/bag-3.png";
import bucket from "src/assets/images/adventure/vacation/packup/bucket.png";
import ball from "src/assets/images/adventure/vacation/packup/ball.png";
import scuba from "src/assets/images/adventure/vacation/packup/scuba.png";
import sun_glasses from "src/assets/images/adventure/vacation/packup/sun-glasses.png";
import raquets from "src/assets/images/adventure/vacation/packup/raquets.png";
import camera from "src/assets/images/adventure/vacation/packup/camera.png";
import dino from "src/assets/images/adventure/vacation/packup/dino.png";
import bucket_border from "src/assets/images/adventure/vacation/packup/bucket-border.png";
import ball_border from "src/assets/images/adventure/vacation/packup/ball-border.png";
import scuba_border from "src/assets/images/adventure/vacation/packup/scuba-border.png";
import sun_glasses_border from "src/assets/images/adventure/vacation/packup/sun-glasses-border.png";
import raquets_border from "src/assets/images/adventure/vacation/packup/raquets-border.png";
import camera_border from "src/assets/images/adventure/vacation/packup/camer-border.png";
import dino_border from "src/assets/images/adventure/vacation/packup/dino-border.png";
import Draggable, { DraggableData } from "react-draggable";
import { useEffect, useRef, useState } from "react";
import useVotoStore from "src/store";
import { Locale, SubStage_go_vacation } from "src/types/index.d";
import Navbar from "src/compnents/navbar/Navbar";
import { useNavigate, Navigate } from "react-router-dom";
import FooterPackupHome from "src/compnents/footer/packup/home/Footer-packup-home";
import homeVideo_swe from "src/assets/videos/Voto_Strandfilmen_PART1_REV6_SWE.mp4";
import homeVideo_en from "src/assets/videos/Voto_Strandfilmen_PART1_REV6_EN.mp4";

const PackupHome = () => {
    const bagRef = useRef<HTMLImageElement>(null);
    const bucketRef = useRef<HTMLImageElement>(null);
    const ballRef = useRef<HTMLImageElement>(null);
    const scubaRef = useRef<HTMLImageElement>(null);
    const sun_glassesRef = useRef<HTMLImageElement>(null);
    const raquetsRef = useRef<HTMLImageElement>(null);
    const cameraRef = useRef<HTMLImageElement>(null);
    const dinoRef = useRef<HTMLImageElement>(null);

    const navigate = useNavigate();

    const [bagPosition, setBagPosition] = useState({
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    });

    const [itemClicked, setItemClicked] = useState({
        [SubStage_go_vacation.sun_glasses]: false,
        [SubStage_go_vacation.ball]: false,
        [SubStage_go_vacation.bucket]: false,
        [SubStage_go_vacation.dino]: false,
        [SubStage_go_vacation.camera]: false,
        [SubStage_go_vacation.scuba]: false,
        [SubStage_go_vacation.raquets]: false,
    });

    const [startDrag, setStartDrag] = useState(false);
    const zoomInBagTime = 5.5;

    const {
        locale,
        vacation: { stages },
        vacationSubstageCompletedByName,
        vacationStageStartById,
        vacationStageCompletedById,
    } = useVotoStore();
    const packupStage = stages[0];

    const homeVideo = locale === Locale.en ? homeVideo_en : homeVideo_swe;

    const getSubstageByName = (subName: SubStage_go_vacation) => {
        return packupStage.subStages.find((sub) => sub.name === subName);
    };

    useEffect(() => {
        if (!packupStage.started) {
            vacationStageStartById(packupStage.id);
        }
        bagRef.current &&
            setBagPosition({
                left: bagRef.current.offsetLeft,
                top: bagRef.current.offsetTop,
                bottom: bagRef.current.offsetTop + bagRef.current.offsetHeight,
                right: bagRef.current.offsetLeft + bagRef.current.offsetWidth,
            });

        return () => {};
    }, [bagRef, packupStage.id, vacationStageStartById, packupStage.started]);

    useEffect(() => {
        if (
            packupStage.subStages.filter((subS) => subS.completed === true)
                .length === 4
        ) {
            if (!packupStage.completed) {
                vacationStageCompletedById(packupStage.id);
            }

            setTimeout(() => {
                navigate("/bag-video", { state: { videoUrl: homeVideo } });
            }, (zoomInBagTime - 1) * 1000);
        }

        return () => {};
    }, [
        packupStage.id,
        packupStage.subStages,
        vacationStageCompletedById,
        packupStage.completed,
        navigate,
    ]);

    const bagHover = () => {
        if (bagRef.current) {
            bagRef.current.style.transform = "scale(1.1)";
        }
    };

    const bagUnHover = () => {
        if (bagRef.current) {
            bagRef.current.style.transform = "scale(1)";
        }
    };

    const removeItem = (item: HTMLImageElement) => {
        item.id && vacationSubstageCompletedByName(packupStage.id, item.id);
    };

    const onStop = (e: any, ui: DraggableData) => {
        if (startDrag) {
            e.target.style.opacity = 1;
        }
        bagUnHover();
        setStartDrag(false);

        const centerLeftItem =
            e.target.offsetLeft + ui.lastX + e.target.offsetWidth / 2;
        const centerTopItem =
            e.target.offsetTop + ui.lastY + e.target.offsetHeight / 2;
        if (bagPosition.left !== 0) {
            if (
                centerLeftItem >= bagPosition.left &&
                centerLeftItem <= bagPosition.right &&
                centerTopItem >= bagPosition.top &&
                centerTopItem <= bagPosition.bottom
            ) {
                removeItem(e.target);
            }
        }
    };
    const onDrag = (e: any, ui: DraggableData) => {
        if (Object.keys(itemClicked).includes(e.target.id)) {
            e.target.style.opacity = 0.7;
        }
        setStartDrag(true);

        const centerLeftItem =
            e.target.offsetLeft + ui.lastX + e.target.offsetWidth / 2;
        const centerTopItem =
            e.target.offsetTop + ui.lastY + e.target.offsetHeight / 2;
        if (bagPosition.left !== 0) {
            if (
                centerLeftItem >= bagPosition.left &&
                centerLeftItem <= bagPosition.right &&
                centerTopItem >= bagPosition.top &&
                centerTopItem <= bagPosition.bottom
            ) {
                bagHover();
            } else {
                bagUnHover();
            }
        }
    };

    const handleOnClickItem = (e: any) => {
        const newObj: any = { ...itemClicked };
        delete newObj[e.target.id];
        Object.keys(newObj).map((item) => (newObj[item] = false));

        setItemClicked({
            ...newObj,
            [e.target.id]: true,
        });
    };

    const handleBagClick = () => {
        const itemIndex = Object.values(itemClicked).findIndex(
            (item) => item === true
        );
        const selectedItem = Object.keys(itemClicked)[itemIndex];
        if (selectedItem) {
            vacationSubstageCompletedByName(packupStage.id, selectedItem);
            if (bagRef.current) {
                bagRef.current.style.transform = "scale(1.1)";
            }
        }
    };

    const bagClass = () =>
        packupStage.subStages.filter((sub) => sub.completed === true).length ===
        4
            ? "bag--3"
            : packupStage.subStages.filter((sub) => sub.completed).length >= 2
            ? "bag--2"
            : "bag--1";
    const bagSrc = () =>
        packupStage.subStages.filter((sub) => sub.completed === true).length ===
        4
            ? bag_3
            : packupStage.subStages.filter((sub) => sub.completed).length >= 2
            ? bag_2
            : bag_1;

    return (
        <div
            className="c-packup__wrapper c-voto-page"
            style={
                packupStage.completed
                    ? {
                          animation: `zoom-in-bag ${zoomInBagTime}s forwards`,
                      }
                    : {}
            }
        >
            <div className={`c-packup`}>
                <Navbar showHome={true} />

                <img className="c-packup__line-bg" src={line_bg} alt="" />
                <img className="c-packup__fix-stuff" src={fix_stuffa} alt="" />
                <img
                    onClick={handleBagClick}
                    ref={bagRef}
                    className={`self-item c-packup__bag ${bagClass()}`}
                    src={bagSrc()}
                    alt=""
                />

                {!getSubstageByName(SubStage_go_vacation.sun_glasses)
                    ?.completed && (
                    <>
                        <Draggable
                            nodeRef={sun_glassesRef}
                            position={{ x: 0, y: 0 }}
                            onStop={onStop}
                            onDrag={onDrag}
                            onMouseDown={handleOnClickItem}
                        >
                            <img
                                ref={sun_glassesRef}
                                id={SubStage_go_vacation.sun_glasses}
                                className="self-item c-packup__sun-glasses"
                                src={sun_glasses}
                                alt=""
                                style={
                                    itemClicked[
                                        SubStage_go_vacation.sun_glasses
                                    ] === true && !startDrag
                                        ? { opacity: 0.7 }
                                        : {}
                                }
                            />
                        </Draggable>
                        <img
                            src={sun_glasses_border}
                            className="self-item border-items c-packup__sun-glasses"
                            alt=""
                            style={
                                itemClicked[
                                    SubStage_go_vacation.sun_glasses
                                ] === true && !startDrag
                                    ? { display: "block" }
                                    : {}
                            }
                        />
                    </>
                )}
                {!getSubstageByName(SubStage_go_vacation.raquets)
                    ?.completed && (
                    <>
                        <Draggable
                            nodeRef={raquetsRef}
                            position={{ x: 0, y: 0 }}
                            onStop={onStop}
                            onDrag={onDrag}
                            onMouseDown={handleOnClickItem}
                        >
                            <img
                                ref={raquetsRef}
                                id={SubStage_go_vacation.raquets}
                                className="self-item c-packup__raquets"
                                src={raquets}
                                alt=""
                                style={
                                    itemClicked[
                                        SubStage_go_vacation.raquets
                                    ] === true && !startDrag
                                        ? { opacity: 0.7 }
                                        : {}
                                }
                            />
                        </Draggable>
                        <img
                            src={raquets_border}
                            className="self-item border-items c-packup__raquets"
                            alt=""
                            style={
                                itemClicked[SubStage_go_vacation.raquets] ===
                                    true && !startDrag
                                    ? { display: "block" }
                                    : {}
                            }
                        />
                    </>
                )}
                {!getSubstageByName(SubStage_go_vacation.scuba)?.completed && (
                    <>
                        <Draggable
                            nodeRef={scubaRef}
                            position={{ x: 0, y: 0 }}
                            onStop={onStop}
                            onDrag={onDrag}
                            onMouseDown={handleOnClickItem}
                        >
                            <img
                                ref={scubaRef}
                                id={SubStage_go_vacation.scuba}
                                className="self-item c-packup__scuba"
                                src={scuba}
                                alt=""
                                style={
                                    itemClicked[SubStage_go_vacation.scuba] ===
                                        true && !startDrag
                                        ? { opacity: 0.7 }
                                        : {}
                                }
                            />
                        </Draggable>
                        <img
                            src={scuba_border}
                            className="self-item border-items c-packup__scuba"
                            alt=""
                            style={
                                itemClicked[SubStage_go_vacation.scuba] ===
                                    true && !startDrag
                                    ? { display: "block" }
                                    : {}
                            }
                        />
                    </>
                )}

                {!getSubstageByName(SubStage_go_vacation.dino)?.completed && (
                    <>
                        <Draggable
                            nodeRef={dinoRef}
                            position={{ x: 0, y: 0 }}
                            onStop={onStop}
                            onDrag={onDrag}
                            onMouseDown={handleOnClickItem}
                        >
                            <img
                                ref={dinoRef}
                                id={SubStage_go_vacation.dino}
                                className="self-item c-packup__dino"
                                src={dino}
                                alt=""
                                style={
                                    itemClicked[SubStage_go_vacation.dino] ===
                                        true && !startDrag
                                        ? { opacity: 0.7 }
                                        : {}
                                }
                            />
                        </Draggable>
                        <img
                            src={dino_border}
                            className="self-item border-items c-packup__dino"
                            alt=""
                            style={
                                itemClicked[SubStage_go_vacation.dino] ===
                                    true && !startDrag
                                    ? { display: "block" }
                                    : {}
                            }
                        />
                    </>
                )}
                {!getSubstageByName(SubStage_go_vacation.ball)?.completed && (
                    <>
                        <Draggable
                            nodeRef={ballRef}
                            position={{ x: 0, y: 0 }}
                            onStop={onStop}
                            onDrag={onDrag}
                            onMouseDown={handleOnClickItem}
                        >
                            <img
                                ref={ballRef}
                                id={SubStage_go_vacation.ball}
                                className="self-item c-packup__ball"
                                src={ball}
                                alt=""
                                style={
                                    itemClicked[SubStage_go_vacation.ball] ===
                                        true && !startDrag
                                        ? { opacity: 0.7 }
                                        : {}
                                }
                            />
                        </Draggable>
                        <img
                            src={ball_border}
                            className="self-item border-items c-packup__ball"
                            alt=""
                            style={
                                itemClicked[SubStage_go_vacation.ball] ===
                                    true && !startDrag
                                    ? { display: "block" }
                                    : {}
                            }
                        />
                    </>
                )}
                {!getSubstageByName(SubStage_go_vacation.camera)?.completed && (
                    <>
                        <Draggable
                            nodeRef={cameraRef}
                            position={{ x: 0, y: 0 }}
                            onStop={onStop}
                            onDrag={onDrag}
                            onMouseDown={handleOnClickItem}
                        >
                            <img
                                ref={cameraRef}
                                id={SubStage_go_vacation.camera}
                                className="self-item c-packup__camera"
                                src={camera}
                                alt=""
                                style={
                                    itemClicked[SubStage_go_vacation.camera] ===
                                        true && !startDrag
                                        ? { opacity: 0.7 }
                                        : {}
                                }
                            />
                        </Draggable>
                        <img
                            src={camera_border}
                            className="self-item border-items c-packup__camera"
                            alt=""
                            style={
                                itemClicked[SubStage_go_vacation.camera] ===
                                    true && !startDrag
                                    ? { display: "block" }
                                    : {}
                            }
                        />
                    </>
                )}
                {!getSubstageByName(SubStage_go_vacation.bucket)?.completed && (
                    <>
                        <Draggable
                            nodeRef={bucketRef}
                            position={{ x: 0, y: 0 }}
                            onStop={onStop}
                            onDrag={onDrag}
                            onMouseDown={handleOnClickItem}
                        >
                            <img
                                ref={bucketRef}
                                id={SubStage_go_vacation.bucket}
                                className="self-item c-packup__bucket"
                                src={bucket}
                                alt=""
                                style={
                                    itemClicked[SubStage_go_vacation.bucket] ===
                                        true && !startDrag
                                        ? { opacity: 0.7 }
                                        : {}
                                }
                            />
                        </Draggable>
                        <img
                            src={bucket_border}
                            className="self-item border-items c-packup__bucket"
                            alt=""
                            style={
                                itemClicked[SubStage_go_vacation.bucket] ===
                                    true && !startDrag
                                    ? { display: "block" }
                                    : {}
                            }
                        />
                    </>
                )}
            </div>
            <FooterPackupHome />
        </div>
    );
};
export default PackupHome;
