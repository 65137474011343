import { SyntheticEvent, useRef, useState } from "react";
import "./video.scss";
import { Link } from "react-router-dom";
import useVotoStore from "src/store";
import { translationService } from "src/services/translation.service";

interface VideoPlayerProps {
    url: string;
    onCloseClick?: React.MouseEventHandler<HTMLButtonElement>;
    onLinkClick?: React.MouseEventHandler<HTMLAnchorElement>;
    target?: string;
    autoplay?: boolean;
    onEndedVideo?: (e: SyntheticEvent<HTMLVideoElement>) => void;
    loop?: boolean;
    size?: string;
    pausable?: boolean;
    showProgressBar?: boolean;
    muted?: boolean;
}

const VideoPlayer = ({
    url,
    onCloseClick,
    onLinkClick,
    target,
    autoplay = false,
    loop = false,
    onEndedVideo,
    pausable = false,
    size = "100%",
    showProgressBar = false,
    muted = false,
}: VideoPlayerProps) => {
    const videoRef: any = useRef(null);
    const progressRef: any = useRef(null);
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(1);

    const [time, setTime] = useState({
        current: "00",
        duration: "00",
        percentage: 0,
    });

    const { locale } = useVotoStore();

    const closeText = translationService.translate("shared.close", locale);

    const videoHandler = () => {
        if (videoRef.current.paused) {
            videoRef.current.play();
            setPlaying(true);
        } else {
            videoRef.current.pause();
            setPlaying(false);
        }
    };

    const handleTimeUpdate = () => {
        let currentMinutes = Math.floor(videoRef.current.currentTime / 60);
        let currentSeconds = Math.floor(
            videoRef.current.currentTime - currentMinutes * 60
        );
        let durationMinutes = Math.floor(videoRef.current.duration / 60);
        let durationSeconds = Math.floor(
            videoRef.current.duration - durationMinutes * 60
        );

        setTime({
            ...time,
            current: `${currentMinutes}:${
                currentSeconds < 10 ? "0" + currentSeconds : currentSeconds
            }`,
            duration: `${durationMinutes}:${durationSeconds}`,
            percentage:
                (videoRef.current.currentTime / videoRef.current.duration) *
                100,
        });
    };

    const handleProgress = (e: any) => {
        const progressTime =
            (e.clientX / progressRef.current.offsetWidth) *
            videoRef.current.duration;

        videoRef.current.currentTime = progressTime;
    };

    return (
        <div className="voto__player">
            <div className="close">
                {onCloseClick && (
                    <button
                        className="c-button button--primary button--medium button--m-transparent"
                        onClick={onCloseClick}
                    >
                        {closeText}
                    </button>
                )}
                {onLinkClick && target && (
                    <Link to={target} onClick={onLinkClick}>
                        Close Link
                    </Link>
                )}
            </div>

            <video
                style={{ width: size, height: size }}
                ref={videoRef}
                className="video"
                src={url}
                onClick={() => (pausable ? videoHandler() : {})}
                onTimeUpdate={handleTimeUpdate}
                autoPlay={autoplay ? true : false}
                loop={loop ? true : false}
                onEnded={onEndedVideo}
                muted={muted ? true : false}
            ></video>

            {showProgressBar ? (
                <div className="player-controls">
                    <div
                        ref={progressRef}
                        className="video-progress"
                        // onClick={handleProgress}
                    >
                        <div
                            className="video-progress-filled"
                            style={{ width: time.percentage + "%" }}
                        ></div>
                    </div>

                    {/* <button
                    onClick={videoHandler}
                    className="play-button"
                    title="Play"
                >
                    {!playing && "►"}
                    {playing && "||"}
                </button> */}

                    {/* <input
                    type="range"
                    className="volume"
                    min="0"
                    max="1"
                    step="0.01"
                    value={volume}
                    onChange={(e: any) => setVolume(e.target.value)}
                    onMouseMove={() => (videoRef.current.volume = volume)}
                /> */}

                    <div className="time">
                        <span className="current">{time.current}</span>&nbsp; /{" "}
                        &nbsp;
                        <span className="duration">{time.duration}</span>
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};
export default VideoPlayer;
