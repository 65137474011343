import produce from "immer";
import {
    Adventures,
    Basename,
    Locations,
    MappedFilm,
    SingleLocation,
    Stage_1,
    Stage_2,
    VotoState,
} from "src/types/index.d";
import { StateCreator } from "zustand";

export interface SubmarineProps {
    [Adventures.submarine]: {
        id: string;
        name: Adventures.submarine;
        started: boolean;
        completed: boolean;
        locations: Locations;
        intro: boolean;
        stages: {
            id: string;
            name: Stage_1;
            url: string;
            title: { en: string; se: string };
            image: string;
            started?: boolean;
            completed: boolean;
        }[];
    };
}

export const submarineInitialState: SubmarineProps = {
    [Adventures.submarine]: {
        id: "1",
        name: Adventures.submarine,
        started: false,
        completed: false,
        locations: [],
        intro: false,
        stages: [
            {
                id: "0",
                name: Stage_1.film_0,
                completed: false,
                url: "",
                title: {
                    en: "",
                    se: "",
                },
                image: "",
            },
            {
                id: "1",
                name: Stage_1.film_1,
                completed: false,
                url: "",
                title: {
                    en: "",
                    se: "",
                },
                image: "",
            },
            {
                id: "2",
                name: Stage_1.film_2,
                completed: false,
                url: "",
                title: {
                    en: "",
                    se: "",
                },
                image: "",
            },
            {
                id: "3",
                name: Stage_1.film_3,
                completed: false,
                url: "",
                title: {
                    en: "",
                    se: "",
                },
                image: "",
            },
            {
                id: "4",
                name: Stage_1.film_4,
                completed: false,
                url: "",
                title: {
                    en: "",
                    se: "",
                },
                image: "",
            },
            {
                id: "5",
                name: Stage_1.film_5,
                completed: false,
                url: "",
                title: {
                    en: "",
                    se: "",
                },
                image: "",
            },
            {
                id: "6",
                name: Stage_1.film_6,
                completed: false,
                url: "",
                title: {
                    en: "",
                    se: "",
                },
                image: "",
            },
        ],
    },
};

export interface SubmarineState extends SubmarineProps {
    setLocations: (locations: any) => void;
    setFilms: (loc: SingleLocation) => void;
    filmDone: (id: string) => void;
    submarineUpdateIntro: (b: boolean) => void;
}

export const submarineSlice: StateCreator<VotoState, [], [], SubmarineState> = (
    set: (
        partial: (state: SubmarineState) => Partial<SubmarineState>,
        replace?: boolean,
        name?: string
    ) => void
) => ({
    ...submarineInitialState,
    setLocations: (locations: any) =>
        set(
            produce((state: SubmarineState) => {
                state[Adventures.submarine].locations = locations;
            }),
            false,
            "Set locations with details"
        ),
    setFilms: (location: SingleLocation) =>
        set(
            produce((state: SubmarineState) => {
                location.films &&
                    location.films.map((film, index) => {
                        state[Adventures.submarine].stages[index]!.url =
                            film.url;
                        state[Adventures.submarine].stages[index]!.image =
                            film.image;
                        state[Adventures.submarine].stages[index]!.title.en =
                            film.film_title_en;
                        state[Adventures.submarine].stages[index]!.title.se =
                            film.film_title_se;
                    });
            }),
            false,
            "Add film details"
        ),
    filmDone: (filmId: string) =>
        set(
            produce((state: SubmarineState) => {
                const selectedFilm = state[Adventures.submarine].stages.find(
                    (stage) => stage.id === filmId
                );
                if (selectedFilm) {
                    selectedFilm.completed = true;
                }
            }),
            false,
            "Film done"
        ),
    submarineUpdateIntro: (b: boolean) =>
        set(
            produce((state: SubmarineState) => {
                state[Adventures.submarine].intro = b;
            }),
            false,
            `Submarine intro update to ${b}`
        ),
});
