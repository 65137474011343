import circle from 'src/assets/svg/quiz/spinner/circle.png'
import ship from 'src/assets/svg/quiz/spinner/ship.png'
import fish from 'src/assets/svg/quiz/spinner/fish.png'
import fishing from 'src/assets/svg/quiz/spinner/fishing.png'
import picture from 'src/assets/svg/quiz/spinner/picture.png'
import scientist from 'src/assets/svg/quiz/spinner/scientist.png'
import spin from 'src/assets/svg/quiz/spinner/spin-btn.svg'
import pointer from 'src/assets/svg/quiz/spinner/pointer.png'
import { useEffect, useRef, useState } from 'react';
import gsap from "gsap";
import './spinner.scss';
import useVotoStore from 'src/store'
import { QuizAreaName } from 'src/types/index.d'
import { translationService } from 'src/services/translation.service'

function Spinner () {
    const [animating, setAnimating] = useState(false);
    const lottoryRef = useRef(null)
    const wheelRef = useRef(null)
    const { locale, quiz: { playerCount }, setSpinning, setSelectedArea } = useVotoStore();

    const marineText = translationService.translate('quizPage.quizAreas.names.marine', locale);
    const artText = translationService.translate('quizPage.quizAreas.names.art', locale);
    const historyText = translationService.translate('quizPage.quizAreas.names.history', locale);
    const scientistText = translationService.translate('quizPage.quizAreas.names.scientist', locale);
    const sportText = translationService.translate('quizPage.quizAreas.names.sport', locale);

    const getData = (callback) => {
        callback(Math.floor(Math.random() * 5));
    }

    const block = () => {
        setAnimating(true);
        setSpinning(true);
        lottoryRef.current.style.pointerEvents = "none";
    }
    
    const prepare = () => {
        // this._images.forEach(image => {
        //   image.classList.remove("is-active");
        // });
    }
    
    const unblock = () => {
        setAnimating(false);
        setSpinning(false);
        lottoryRef.current.style.pointerEvents = "auto";
    }

    const rotate = (id) => {
        block();
        prepare();
    
        const degree = 360 * 7 - 360 / 5 * id;
    
        gsap.timeline()
          .to(wheelRef.current, {
            duration: 5,
            rotation: degree
          })
          .set(wheelRef.current, {
            rotation: degree % 360,
            onComplete: () => {
               unblock();
               switch (id) {
                case 0:
                    return setSelectedArea(QuizAreaName.marine);
                case 1:
                    return setSelectedArea(QuizAreaName.history);
                case 2:
                    return setSelectedArea(QuizAreaName.sport);
                case 3:
                    return setSelectedArea(QuizAreaName.art);
                case 4:
                    return setSelectedArea(QuizAreaName.scientist);
               
                default:
                    return '';
               }
            }
          });
      }

  
    const onSpin = () => {
        if (animating === false) {
            getData(id => {
              rotate(id);
            });
        }
    }

  return (
    <div className={`spinner-container ${playerCount !== 2 ? 'one-player' : ''}`}>
        <div ref={lottoryRef} className={`lottory`}>
            <img className='pointer' src={pointer} alt="" />
            <img className='spin' src={spin} alt="" onClick={onSpin} />
            <div ref={wheelRef} className="container">

                <div  className='wheel-part circle'>
                    <img className='wheel-part__img'  src={circle} alt="" />
                </div>

                <div className='wheel-part ship'>
                    <img className='wheel-part__img' src={ship} alt="" />
                    <div className="wheel-part__text">

                    <h3 className=''>{historyText}</h3>
                    </div>
                </div>

                <div  className='wheel-part fish'>
                    <img className='wheel-part__img' src={fish} alt="" />
                    <div className="wheel-part__text">

                    <h3 className=''>{marineText}</h3>
                    </div>
                </div>

                <div  className='wheel-part fishing'>
                    <img className='wheel-part__img' src={fishing} alt="" />
                    <div className="wheel-part__text">

                    <h3 className=''>{sportText}</h3>
                    </div>
                </div>

                <div  className='wheel-part picture'>
                    <img className='wheel-part__img' src={picture} alt="" />
                    <div className="wheel-part__text">

                    <h3 className=''>{artText}</h3>
                    </div>
                </div>

                <div  className='wheel-part scientist'>
                    <img className='wheel-part__img' src={scientist} alt="" />
                    <div className="wheel-part__text">

                    <h3 className=''>{scientistText}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Spinner;
