import gedda from "src/assets/images/adventure/gedda.png";
import spigg from "src/assets/images/adventure/spigg.png";
import aborre from "src/assets/images/adventure/aborre.png";
import "./fish.scss";
import lax from "src/assets/svg/background/bravo/lax.svg";
import sik from "src/assets/svg/background/bravo/sik.svg";
import mort from "src/assets/svg/background/bravo/mort.svg";
import torsk from "src/assets/svg/background/bravo/torsk.svg";
import sill from "src/assets/svg/background/bravo/sill.svg";
import skarpsill from "src/assets/svg/background/bravo/skarpsill.svg";

const Bubbles = () => {
    return (
        <div className="voto-fishes">
            <span className="voto-fishes-1 fish">
                <img
                    className=""
                    style={{ animation: "swim-aborre 10s linear infinite" }}
                    src={lax}
                    alt=""
                />
            </span>
            <span className="voto-fishes-2 fish">
                <img
                    style={{ animation: "swim-aborre 7s linear infinite" }}
                    src={torsk}
                    alt=""
                />
            </span>
            <span className="voto-fishes-9 fish">
                <img
                    style={{ animation: "swim-gedda 12s linear infinite" }}
                    src={mort}
                    alt=""
                />
            </span>
            <span className="voto-fishes-10 fish">
                <img
                    style={{ animation: "swim-gedda 17s linear infinite" }}
                    src={sik}
                    alt=""
                />
            </span>
            <span className="voto-fishes-11 fish">
                <img
                    style={{ animation: "swim-gedda 9s linear infinite" }}
                    src={sill}
                    alt=""
                />
            </span>
            <span className="voto-fishes-12 fish">
                <img
                    style={{
                        animation: "swim-gedda 9s 2s linear infinite",
                    }}
                    src={sill}
                    alt=""
                />
            </span>
            <span className="voto-fishes-13 fish">
                <img
                    style={{ animation: "swim-gedda 8s linear infinite" }}
                    src={skarpsill}
                    alt=""
                />
            </span>
            <span className="voto-fishes-14 fish">
                <img
                    style={{
                        animation: "swim-gedda 9s 2s linear infinite",
                    }}
                    src={skarpsill}
                    alt=""
                />
            </span>
            <span className="aborre">
                <img src={aborre} alt="" />
            </span>
            <span className="gedda">
                <img src={gedda} alt="" />
            </span>
            <span className="spigg">
                <img src={spigg} alt="" />
            </span>
        </div>
    );
};
export default Bubbles;
